import React from 'react';
import {useInView} from 'react-intersection-observer';
import {Message} from "../../domain/sdk/domain/Message";
import {Sdk} from "../../domain/sdk/Sdk";
import styles from "./Channel.module.css";

interface Props {
    message: Message;
    dateTimeFormat: string;
    onRead: (message: Message) => void;
}

export default function ChannelMessage({message, dateTimeFormat, onRead}: Props) {
    const {ref} = useInView({
        onChange: (messageRead: boolean) => {
            if (messageRead) {
                onRead(message);
            }
        },
        threshold: 1
    });

    return <div ref={ref} style={{marginBottom:10}}>
        {/* if user id matches then the message is float right */}
        {Sdk.context.user.id === message.senderId && <div className={`${styles.SenderWrapper}`}>
            <div className={`${styles.MessageTime}`}>{dateTimeFormat}</div>
            <div className={`${styles.NameWrapper}`}>{message.senderName}</div>
            <div className={`${styles.GroupMessageWrapper}`}>{message.content}</div>

        </div>}
        {Sdk.context.user.id !== message.senderId && <div>
        <div className={`${styles.MessageTime}`}>{dateTimeFormat}</div>
            <div className={`${styles.NameWrapper}`}>{message.senderName}</div>
            <div className={`${styles.GroupMessageWrapper}`}>{message.content}</div>
        </div>}
    </div>;
};