import React from 'react';
import styles from './ConfirmRequestLeadership.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import {MY_PODS} from "./MySettings";
import ButtonPrimary from "../components/ButtonPrimary";

export default function ConfirmRequestLeadership() {
    const {state} = useLocation();
    const navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Apply to lead your own Pod
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        This will send an approval request to your membership admin. Would you like to continue?
                    </div>
                </div>

                <div className={`columns medium-9 large-12 medium-centered show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidthDelete} text="Yes. Send request" type="button" onClick={async () => {
                        await Sdk.leadersClient.requestLeadership(state.membershipId);
                        navigate('/request-leadership-sent');
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state: {...state, ...{panel: MY_PODS}}});
                    }}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state: {...state, ...{panel: MY_PODS}}});
                    }}/>
                    <ButtonPrimary style={styles.DeleteButton} text="Yes. Send request" type="button" onClick={async () => {
                        await Sdk.leadersClient.requestLeadership(state.membershipId);
                        navigate('/request-leadership-sent');
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
