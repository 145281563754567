import * as React from "react"
import { SVGProps } from "react"

const Avatar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="User icon"
        xmlns="http://www.w3.org/2000/svg"
        width={50.865}
        height={50.865}
        {...props}
    >
      <g data-name="Layer">
        <path
            data-name="Path 132"
            d="M25.432 3.458A21.974 21.974 0 1 1 9.894 9.894a21.974 21.974 0 0 1 15.538-6.436m0-3.458a25.432 25.432 0 1 0 25.433 25.432A25.432 25.432 0 0 0 25.432 0Z"
            fill="#37694a"
        />
        <path
            data-name="Path 133"
            d="M25.432 13.138a4.836 4.836 0 1 1-4.833 4.834 4.836 4.836 0 0 1 4.833-4.834m0-2.707a7.542 7.542 0 1 0 7.542 7.542 7.542 7.542 0 0 0-7.542-7.542Z"
            fill="#37694a"
        />
        <path
            data-name="Path 134"
            d="M8.27 42.683c2.469-7.721 9.2-13.261 17.125-13.261 8.017 0 14.82 5.676 17.212 13.545"
            fill="none"
            stroke="#37694a"
            strokeMiterlimit={10}
            strokeWidth={3}
        />
      </g>
    </svg>
)

export default Avatar
