import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPut} from "../utils/HttpClient";
import {NotificationFrequency} from "./domain/NotificationFrequency";
import {NotificationSetting} from "./domain/NotificationSetting";
import {SubscriberNotification} from "./domain/SubscriberNotification";

export class NotificationSettingsClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/my-notification-settings`;
    }

    async notifications() {
        const response = await httpGet(this.url);
        return response.data as NotificationSetting[];
    }

    async updateFrequency(type: SubscriberNotification, frequency: NotificationFrequency) {
        await httpPut(this.url, {type, frequency});
    }
}