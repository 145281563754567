import styles from './FormInputPassword.module.css'
import React, {InputHTMLAttributes} from 'react';

export interface FormInputPasswordProps extends InputHTMLAttributes<any> {
	className?: string;
	label?: string;
}

export default function FormInputPassword({className, label, name, ...other}:FormInputPasswordProps) {
	return <div className={`${className ? className : ''}`}>
		<div className={`${styles.InputText_Wrapper}`}>
			<input className={`${styles.InputText_Input}`} placeholder={"Password"} type="password" name={name} {...other}/>
		</div>
		{label && <label className={`${styles.InputText_Label}`} htmlFor={name}>
			{label}
		</label>}
	</div>
}
