import {Message} from "../sdk/domain/Message";

export function dedupe(messages: Message[]) {
    let deduped = [] as Message[];
    for (const message of messages) {
        if (deduped.find(m => m.id === message.id)) {
            continue;
        }
        deduped.push(message);
    }
    return deduped.sort((a, b) => a.createdEpochMillis - b.createdEpochMillis);
}