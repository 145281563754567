const styles: any = {
    TitleWrapper: {
        paddingTop: 50,
        paddingBottom: 26,
        color: "#EA7E3D",
        fontSize: 21,
        textAlign: 'center'
    },

    SubHeadingText: {
        fontSize: 21,
        color: "#606060",
        marginBottom: 20,
        fontWeight: 300
    },

    InviteForm: {
        marginBottom: 50
    },

    InputWrapper: {
        textAlign: 'center',
        paddingBottom: 10,
        fontSize: 21,
        fontWeight: 300,
        color: '#606060'
    },

    LastInputWrapper: {
        textAlign: 'center',
        fontSize: 21,
        fontWeight: 300,
        paddingBottom: 50,
        color: '#606060'
    },

    DeleteButton: {
        width: "58%",
        fontSize: 22,
        float: "right",
        backgroundColor: "#EA7E3D"
    },

    CancelButton: {
        width: "39%",
        fontSize: 22,
    },

    FullWidth: {
        width: "100%",
        marginBottom: 10,
    },

    FullWidthDelete: {
        backgroundColor: "#EA7E3D",
        width: "100%",
        marginBottom: 10,
    },

    ErrorMessage: {
        color: "#EA7E3D",
        margin: 0,
        paddingTop: 0,
        paddingRight: 5,
        paddingBottom: 0,
        paddingLeft: 5,
    }
}

export default styles;