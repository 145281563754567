import styles from './Breadcrumb.module.css';
import {Link} from "react-router-dom";
import React from "react";

export interface BreadcrumbProps {
    routes:Route[];
}

export interface Route {
    text:string;
    state?: any;
    link?:string;
}

export default function Breadcrumb({routes}:BreadcrumbProps) {
    return <div className={`${styles.Breadcrumb}`}>
        {routes.map((route, index) => {
            if (!route.text) {
                return undefined;
            }
            const isLast = index + 1 === routes.length;
            if (route.link && isLast) {
                return <Link key={index} className={`${styles.LinkWrapper}`} to={route.link} state={route.state}>{route.text}</Link>
            } else if (route.link) {
                return <div key={index} style={{display: "inline"}}>
                    <Link className={`${styles.LinkWrapper}`} to={route.link} state={route.state}>{route.text}</Link>
                    <div className={`${styles.TitleWrapper} ${styles.Arrow}`}>{">"}</div>
                </div>
            } else if (isLast) {
                return <div key={index} className={`${styles.TitleWrapper}`}>{route.text}</div>
            }
            return <div key={index} style={{display: "inline"}}>
                <div className={`${styles.TitleWrapper}`}>{route.text}</div>
                <div className={`${styles.TitleWrapper} ${styles.Arrow}`}>{">"}</div>
            </div>
        })}
    </div>
}