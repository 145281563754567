import React, {useEffect, useState} from 'react';
import styles from './ManageMembershipsPanel.module.css';
import {Link, useNavigate} from "react-router-dom";
import {Sdk} from "../../../domain/sdk/Sdk";
import {MyMembership} from "../../../domain/sdk/domain/Membership";
import ButtonDanger from "../../components/ButtonDanger";
import {Business} from "../../../domain/sdk/domain/Business";

export default function ManageMembershipsPanel() {
    let navigate = useNavigate();
    const [selectedBusiness, setSelectedBusiness] = useState(Sdk.context.selectedBusiness);
    const [deleteStatus, setDeleteStatus] = useState(new Map<string, boolean>());
    const [businessMemberships, setBusinessMemberships] = useState(new Map<string, MyMembership[]>());
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            let deleteStatus = new Map<string, boolean>();
            let businessMemberships = new Map<string, MyMembership[]>();
            for (const business of Sdk.context.businesses) {
                let memberships = await Sdk.membershipsClient.myMemberships(business.id);
                businessMemberships.set(business.id, memberships);
                if (business.owner || business.admin) {
                    for (const membership of memberships) {
                        let status = await Sdk.membershipsClient.deleteStatus(membership.membershipId);
                        deleteStatus.set(membership.membershipId, status);
                    }
                }
            }
            setDeleteStatus(deleteStatus);
            setBusinessMemberships(businessMemberships);
            setLoaded(true);
        })();
    }, [selectedBusiness]);

    return <>
    {loaded && <div>
        <div className={`row`}>
            <div className={`columns small-12`}>
                <div className={`${styles.TitleWrapper}`}>
                    Memberships
                </div>
            </div>
        </div>

        {Sdk.context.businesses.map((business: Business, index: number) => {
            let memberships = businessMemberships.get(business.id) ?? [];
            memberships = memberships.sort((a, b) => a.name.localeCompare(b.name));
            let membershipsText = memberships.length > 1 ? 'these memberships' : 'this membership';
            if (memberships.length === 0) {
                membershipsText = 'this business';
            }
            return <div key={index} className={`${styles.LastInputWrapper}`}>
                <div className={`row`}>
                    <div className={`columns small-12`}>
                        <div className={`${styles.SubTitleWrapper}`}>
                            Memberships owned by {business.name}
                            {business.id !== Sdk.context.selectedBusiness.id &&
                                <Link to={"/memberships"} className={`${styles.Link} float-right`} onClick={() => {
                                    Sdk.context.selectedBusiness = business;
                                    setSelectedBusiness(business);
                                }}>
                                    Switch to this business
                                </Link>
                            }
                        </div>
                        {(business.owner || business.admin) && <>
                            {business.owner && !business.admin && <div className={`${styles.InputWrapperInline}`}>
                                You are the owner of {membershipsText}.
                            </div>}
                            {business.admin && !business.owner && <div className={`${styles.InputWrapperInline}`}>
                                You are an admin of {membershipsText}.
                            </div>}
                            {business.owner && business.admin && <div className={`${styles.InputWrapperInline}`}>
                                You are the owner and an admin of {membershipsText}.
                            </div>}
                        </>}
                    </div>
                </div>
                {memberships.length === 0 && <div className={`row`}>
                    <div className={`columns small-12`}>
                        <div className={`${styles.InputWrapper}`}>
                            This business has no memberships
                        </div>
                    </div>
                </div>}
                {memberships.map((membership: MyMembership, index: number) => {
                    let canBeDeleted = deleteStatus.get(membership.membershipId);
                    return <div key={index}>
                        <div className={`row`}>
                            <div className={`columns small-12`}>
                                <div className={`${styles.Heading}`}>
                                    {membership.name}
                                </div>
                                {(business.owner || business.admin) && <div className={`${styles.TextBlock}`}>
                                    <Link to={"/my-settings"} className={`${styles.Link}`} onClick={async () => {
                                        Sdk.membershipsClient.downloadActiveCampaignCsv(membership.membershipId);
                                    }}>
                                        Download CSV of members
                                    </Link>
                                </div>}
                                {membership.leader && <div className={`${styles.TextBlock}`}>
                                    You are a leader of this membership
                                </div>}
                                {(business.owner || business.admin) && <>
                                    {!canBeDeleted && <div className={`${styles.TextBlock}`}>
                                        To delete this membership you first need to delete all pods and remove any members.
                                    </div>}
                                    {canBeDeleted && <>
                                        <div className={`${styles.TextBlock}`}>
                                            This is a serious action and cannot be undone.
                                        </div>
                                        <ButtonDanger className={`${styles.DeleteButton} hide-for-small-only`} text="Delete membership"
                                                      onClick={() => navigate("/delete-membership", {state: membership})}
                                        />
                                        <ButtonDanger className={`${styles.ButtonWrapperFullWidth} show-for-small-only`} text="Delete membership"
                                                      onClick={() => navigate("/delete-membership", {state: membership})}
                                        />
                                    </>}
                                </>}
                            </div>
                        </div>
                    </div>
                })}
    </div>;
    })}
    </div>
}
</>
    ;
}