import React, {useEffect, useState} from 'react';
import styles from './BusinessSettings.module';
import ButtonPrimary from '../components/ButtonPrimary';
import validate from "./BusinessSettings.validation";
import {Sdk} from "../../domain/sdk/Sdk";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import useForm from "../../domain/utils/UseForm";

export default function BusinessSettings() {
    const [success, setSuccess] = useState(false);
    const {values, setValues, errors, setErrors, handleChange, handleSubmit} = useForm(onFormSubmit, validate);

    useEffect(() => {
        setValues({
            businessName : Sdk.context.selectedBusiness.name
        });
    }, [setValues])

    async function onFormSubmit() {
        let selectedBusiness = Sdk.context.selectedBusiness.id;
        let response = await Sdk.businessClient.updateName(selectedBusiness, values.businessName);
        if (response && response.status === 200) {
            Sdk.context.selectedBusiness.name = values.businessName;
            setSuccess(true);
            setTimeout(() => setSuccess(false), 2000);
        } else {
            setSuccess(false);
            setErrors({other: 'Ran into a problem'})
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-6 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Business Settings
                    </div>
                </div>

                <form style={styles.InviteForm} onSubmit={handleSubmit}>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Business name" name="businessName" onChange={handleChange} value={values.businessName || ''}/>
                            {errors.businessName && (<p style={styles.ErrorMessage}>{errors.businessName}</p>)}
                        </div>
                    </div>
                    <div className={`columns small-12 small-centered show-for-small-only`}>
                        <ButtonPrimary style={styles.FullWidth} text="Save" type="submit"/>
                    </div>
                    <div className={`columns medium-9 large-6 medium-centered hide-for-small-only`}>
                        <ButtonPrimary style={styles.FullWidth} text="Save" type="submit"/>
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        {errors.other && (<p style={styles.ErrorMessage}>{errors.other}</p>)}
                        {success && (<p style={styles.ErrorMessage}>{"All saved"}</p>)}
                    </div>
                </form>
            </div>
        </div>
    </>;
}
