import React, {useEffect, useState} from 'react';
import styles from './ConfirmJoinWaitlist.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import Checkbox from "../components/Checkbox";
import {ScheduledMeeting} from "../../domain/sdk/domain/ScheduledMeeting";

export default function ConfirmJoinWaitlist() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const [scheduledMeeting, setScheduledMeeting] = useState(null as ScheduledMeeting | null);
    const [consentJoinNewPod, setConsentJoinNewPod] = useState(false);
    const [consentToBeNewPodLeader, setConsentToBeNewPodLeader] = useState(false);

    useEffect(() => {
        if (state && state.scheduledMeeting) {
            setScheduledMeeting(new ScheduledMeeting(state.scheduledMeeting));
        }
    }, [state]);

    async function onClickConsentJoinNewPod() {
        setConsentJoinNewPod(!consentJoinNewPod)
    }

    async function onClickConsentToBeNewPodLeader() {
        setConsentToBeNewPodLeader(!consentToBeNewPodLeader)
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-10`}>
                    <div style={styles.TitleWrapper}>
                        Joining Progress Pod Waitlist
                    </div>
                </div>
                <div className={`columns medium-10 medium-offset-1`}>
                    <div style={styles.InputWrapper}>
                        Waitlist for {state.name} that meets on {scheduledMeeting?.weekday()} at {scheduledMeeting?.time()}.
                    </div>
                    <div style={styles.InputWrapper}>
                        Please read through the following before you click the button at the bottom of this page to join this waitlist.
                    </div>
                    <div style={styles.LastInputWrapper}>
                        If there are four or more people on the waitlist for this Progress Pod it is possible that another Progress Pod could be formed to meet at this time.
                    </div>
                </div>
                <div className={`columns medium-10 medium-offset-1`}>
                    <div style={styles.InputWrapper}>
                        1. Would you like to be connected into another Progress Pod that meets at this time if four or more people on this waitlist also agree to join this new
                        Progress Pod?
                    </div>
                    <div style={styles.LastInputWrapper}>
                        <Checkbox
                            className={styles.Checkbox}
                            name="consentJoinNewPod"
                            label="(Optional) Yes, I agree to be connected into a new Progress Pod that also meets at this time."
                            onChange={(e) => {
                            }}
                            checked={consentJoinNewPod}
                            onClick={onClickConsentJoinNewPod}
                        />
                        <Checkbox
                            className={styles.Checkbox}
                            name="consentToBeNewPodLeader"
                            label="(Optional) Yes, I agree to be the leader of the new Progress Pod and complete the leader training. "
                            onChange={(e) => {
                            }}
                            checked={consentToBeNewPodLeader}
                            onClick={onClickConsentToBeNewPodLeader}
                        />
                    </div>
                </div>
                <div className={`columns medium-10 medium-offset-1`}>
                    <div style={styles.InputWrapper}>
                        2. Please click the ‘Join’ button below to confirm that you want to join the waitlist for a Progress Pod that meets on {scheduledMeeting?.weekday()} at {scheduledMeeting?.time()}.
                    </div>
                    <div style={styles.LastInputWrapper}>
                        You will be notified when a position becomes available and will be automatically connected into your Progress Pod.
                    </div>
                </div>
                <div className={`columns medium-9 large-7 medium-centered show-for-small-only`}>
                    <div style={styles.LastInputWrapper}>
                        <ButtonPrimary style={styles.FullWidth} text="Join" type="button" onClick={async () => {
                            await Sdk.podsClient.joinWaitlist(state.id, consentJoinNewPod, consentToBeNewPodLeader);
                            navigate('/pods', {state});
                        }}/>
                        <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                            navigate('/pods/join', {state});
                        }}/>
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <div style={styles.LastInputWrapper}>
                        <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                            navigate('/pods/join', {state});
                        }}/>
                        <ButtonPrimary style={styles.AssignButton} text="Join" type="button" onClick={async () => {
                            await Sdk.podsClient.joinWaitlist(state.id, consentJoinNewPod, consentToBeNewPodLeader);
                            navigate('/pods', {state});
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
