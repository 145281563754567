import {DateTime} from "luxon";
import {FrequencyPeriod} from "../sdk/domain/FrequencyPeriod";
import {ScheduledMeeting} from "../sdk/domain/ScheduledMeeting";
import {capitalize, inWords} from "./WordUtils";
import {WeekInMonth} from "../sdk/domain/WeekInMonth";

const FORMAT = "EEEE, dd LLLL yyyy hh:mm a";
const TIME_FORMAT = "h:mm a (ZZZZ)";

export function dateTimeDescription(scheduledMeeting:ScheduledMeeting) {
    // monthly
    let zonedDateTime = DateTime.fromSeconds(scheduledMeeting.unixTime, {zone: 'local'});
    let frequencyToWord = inWords(scheduledMeeting.frequency);
    let time = zonedDateTime.toFormat(TIME_FORMAT);
    let dayOfTheWeek = capitalize(zonedDateTime.weekdayLong);
    if (scheduledMeeting.frequencyPeriod === FrequencyPeriod.Month) {
        let shortMonth = zonedDateTime.day > 28;
        let orLast = shortMonth ? " (or last day)" : "";
        let monthlyOptions = scheduledMeeting.monthlyOptions;
        if (scheduledMeeting.frequency === 1) {
            if (monthlyOptions) {
                return `On the ${monthlyOptions.weekInMonth.toLowerCase()} ${dayOfTheWeek} every month at ${time}`;
            }
            return `Every month on the ${scheduledMeeting.dayOfMonth()}${orLast} at ${time}`;
        } else {
            if (monthlyOptions) {
                return `On the ${monthlyOptions.weekInMonth.toLowerCase()} ${dayOfTheWeek} every ${frequencyToWord} months at ${time}`;
            }
            return `Every ${frequencyToWord} months on the ${scheduledMeeting.dayOfMonth()}${orLast} at ${time}`;
        }
    }
    // weekly
    if (scheduledMeeting.weeklyOptions && scheduledMeeting.weeklyOptions.days && scheduledMeeting.weeklyOptions.days.length > 1) {
        dayOfTheWeek = scheduledMeeting.weeklyOptions.days.map(day => capitalize(day.toString().toLowerCase())).join(", ");
    }
    if (scheduledMeeting.frequency === 1) {
        return `Every week on ${dayOfTheWeek} at ${time}`;
    }
    return `Every ${frequencyToWord} weeks on ${dayOfTheWeek} at ${time}`;
}

export function dateTimeExamplesFor(date:Date | null, frequency:number, frequencyPeriod:FrequencyPeriod) {
    if (date === null) {
        return [];
    }
    try {
        let dateTime = DateTime.fromJSDate(date, {zone: 'local'});
        if (dateTime.isValid) {
            let examples = [];
            examples.push(dateTime.toFormat(FORMAT));
            if (frequency >= 1) {
                for (let i = 0; i < 2; i++) {
                    if (frequencyPeriod === FrequencyPeriod.Week) {
                        dateTime = dateTime.plus({ days: 7 * frequency });
                    } else {
                        dateTime = dateTime.plus({month: frequency});
                    }
                    examples.push(dateTime.toFormat(FORMAT));
                }
            }
            return examples;
        }
    } catch (e) {
        // ignore
    }
    return [];
}

export function dateSuffix(value:number) {
    if ([1,21,31].includes(value)) {
        return "st"
    } else if ([2,22].includes(value)) {
        return "nd"
    } else if ([3,23].includes(value)) {
        return "rd"
    }
    return "th"
}

export function pad(value:number) {
    if(value < 10) {
        return '0' + value;
    } else {
        return value;
    }
}

export function validateMeetingSchedule(values: any) {
    let errors: any = {};
    if (!values.frequency) {
        errors.frequency = 'Frequency is required';
    } else if (values.frequency <= 0) {
        errors.frequency = 'Frequency must be 1 or greater';
    }
    if (!values.duration) {
        errors.duration = 'Duration is required';
    }
    return errors;
}

export function updateSelectedWeek(date: Date) {
    let occurrences = occurrencesOfDay(date);
    let weekCursor = -1;
    for (let i = 0; i < occurrences.length; i++) {
        const occurrence = occurrences[i];
        if (occurrence.getDate() === date.getDate()) {
            weekCursor = i;
            break;
        }
    }
    if (weekCursor !== -1) {
        return [WeekInMonth.First, WeekInMonth.Second, WeekInMonth.Third, WeekInMonth.Fourth, WeekInMonth.Last][weekCursor];
    }
}

export function occurrencesOfDay(date: Date) {
    let dates = [];
    let dayInMonth = date.getDay();
    let firstOf = new Date(date.getFullYear(), date.getMonth(), 1, );

    let quantityOfDays = daysInMonth(date.getMonth(), date.getFullYear());
    for (let i = 0; i < quantityOfDays; i++) {
        if (firstOf.getDay() === dayInMonth) {
            let occurrence = new Date(firstOf);
            occurrence.setHours(date.getHours());
            occurrence.setMinutes(date.getMinutes());
            dates.push(occurrence);
        }
        firstOf.setDate(firstOf.getDate() + 1);
    }
    return dates;
}

export function daysInMonth(month: number, year: number) {
    return new Date(year, month + 1, 0).getDate();
}

export function respectiveDateTime(createdEpochMillis: number, currentTime: DateTime) {
    let dateTime = DateTime.fromMillis(createdEpochMillis);
    let dateTimeFormat = '' as string | null;
    let duration = currentTime.diff(dateTime, ['minutes', 'hours', 'days', 'months']);
    if (duration.months === 0 && duration.weeks === 0 && duration.days === 0) {
        // within 24 hours
        if (duration.hours > 0) {
            dateTimeFormat = duration.hours === 1 ? `An hour ago` : `${Math.round(duration.hours)} hours ago`;
        } else if (duration.minutes > 1) {
            dateTimeFormat = duration.minutes === 1 ? `One minute ago` : `${Math.round(duration.minutes)} minutes ago`;
        } else {
            dateTimeFormat = `Just now`;
        }
    } else if (duration.months === 0 && duration.weeks === 0 && duration.days < 7) {
        dateTimeFormat = dateTime.toRelativeCalendar();
    }

    if (dateTimeFormat === null || dateTimeFormat === '') {
        dateTimeFormat = dateTime.toLocaleString({
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: "h23",
        });
    }
    return dateTimeFormat;
}