import {Sdk} from "../sdk/Sdk";
import {UserActionRequired} from "../sdk/domain/UserActionRequired";


export function rootNavigation() {
    let context = Sdk.context;
    if (context) {
        if (context.actionsRequired?.includes(UserActionRequired.OWNER_ADMIN_PROMPT)) {
            return '/assign-admin';
        } else if (context.actionsRequired?.includes(UserActionRequired.ZOOM_PROMPT)) {
            return '/setup-zoom';
        } else if (context.actionsRequired?.includes(UserActionRequired.LEADER_APPROVAL_ACKNOWLEDGEMENT)) {
            return '/leader/welcome';
        } else if (context.actionsRequired?.includes(UserActionRequired.LEADER_CONFIRM_TRAINING)) {
            return '/leader/confirm-training';
        } else if (context.actionsRequired?.includes(UserActionRequired.COMPLETE_PROFILE)) {
            return '/account/bio';
        } else {
            return '/memberships';
        }
    }
    return '/login';
}