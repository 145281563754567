import {useLocation} from "react-router-dom";
import React from "react";

export function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function extract(value:any) {
    return atob(String(value));
}
