import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost} from "../utils/HttpClient";
import {MessageChannel} from "./domain/ChannelMessages";
import {Message} from "./domain/Message";
import {ChannelListener} from "./ChannelListener";
import {dedupe} from "../utils/MessageUtils";
import Auth from "../Auth";
import {Sdk} from "./Sdk";

const RECONNECT_MS = 30000;

export class MessageClient {

    private readonly url: string;
    private messageSource?: EventSource;
    private userId?: string;
    private _channels: Map<string, MessageChannel> = new Map<string, MessageChannel>();
    private listeners: Map<string, ChannelListener> = new Map<string, ChannelListener>([]);
    private messageListeners: MessageListener[] = [];

    /* checks if this message was created after the current last read */
    async markMessageAsRead(message: Message) {
        if (this._channels.has(message.channelId)) {
            let channel = this._channels.get(message.channelId);
            // N.B. messages must be present if the chat is open
            if (channel && channel.messages && channel.messages.length > 0) {
                let messageIds = channel.messages.map(message => message.id);
                if (messageIds.indexOf(message.id) > messageIds.indexOf(channel.lastMessageReadId)) {
                    await httpPost(`${this.url}/channels/${message.channelId}/subscribers`, {messageId: message.id, status: 'READ'});
                    channel.lastMessageReadId = message.id; // update the last message for this channel
                    for (const messageListener of this.messageListeners) {
                        messageListener.read(message);
                    }
                }
            }
        }
    }

    getChannel(channelId:string): MessageChannel | undefined {
        return this._channels.get(channelId);
    }

    channels(): MessageChannel[] {
        return Array.from(this._channels.values());
    }

    addListener(listener: MessageListener) {
        this.messageListeners.push(listener);
    }

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1`;
    }

    async initialise(userId: string) {
        this.userId = userId;
        this.connect();
        await this.reloadChannels();
        setInterval(() => this.connect(), RECONNECT_MS);
    }

    async reloadChannels(): Promise<MessageChannel[]> {
        let channels = await this.myChannels();
        this._channels = new Map<string, MessageChannel>();
        for (const channel of channels) {
            this._channels.set(channel.id, channel);
        }
        this.listeners.clear();
        return channels;
    }

    async channel(channelId: string, listen: (messages: Message[]) => void): Promise<MessageChannel | undefined> {
        let messages = await this.messages(channelId);
        this.listeners.set(channelId, {
            listen: (message: Message) => {
                messages.push(message);
                listen(dedupe(messages));
            }
        })
        let channel = this._channels.get(channelId);
        if (channel) {
            channel.messages = dedupe(messages);
        }
        return channel;
    }

    async sendMessage(content: string, channelId: string) {
        return await httpPost(`${this.url}/channels/${channelId}/messages`, {content});
    }

    close() {
        try {
            this.messageSource?.close();
        } catch (e) {
            console.error(e);
        }
    }

    private connect() {
        try {
            let token = localStorage.getItem('access_token');
            if (this.messageSource) {
                this.messageSource.close();
                this.messageSource = undefined;
            }
            if (token) {
                this.messageSource = new EventSource(`${this.url}/messages/stream?userId=${this.userId}&authToken=${token}`);
                this.messageSource.addEventListener('message', (e) => {
                    let message = JSON.parse(e.data);
                    if (message?.event === "logout") {
                        Auth.logout();
                        Sdk.messageClient.close();
                        // @ts-ignore
                        window.location = "/login";
                    }
                    if (message.channelId) {
                        if (this._channels.has(message.channelId)) {
                            let channel = this._channels.get(message.channelId);
                            if (channel) {
                                channel.lastMessageId = message.id;
                                channel.messages?.push(message);
                                channel.messages = dedupe(channel.messages ?? []);
                            }
                        }
                        if (this.listeners.has(message.channelId)) {
                            let listener = this.listeners.get(message.channelId);
                            listener?.listen(message);
                        }
                        for (const messageListener of this.messageListeners) {
                            messageListener.received(message);
                        }
                    }
                }, false);
            } else {
                console.warn('Cannot reconnect no access token found');
            }
        } catch (e) {
            console.error(e);
        }
    }

    private async messages(channelId: string): Promise<Message[]> {
        let response = await httpGet(`${this.url}/channels/${channelId}/messages`);
        return response.data as Message[];
    }

    private async myChannels(): Promise<MessageChannel[]> {
        let response = await httpGet(`${this.url}/channels/my-channels`);
        let channels = response.data as MessageChannel[];
        return channels.sort((a, b) => a.name.localeCompare(b.name));
    }
}

export interface MessageListener {
    received(message: Message): void;

    read(message: Message): void;
}