import React, {useEffect, useState} from 'react';
import styles from './MySettings.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import {Link, useLocation} from "react-router-dom";
import {Membership, MyMembership} from "../../domain/sdk/domain/Membership";
import {Pod} from "../../domain/sdk/domain/Pod";
import AccountPanel from "./mysettings/AccountPanel";
import MyPodsPanel from "./mysettings/MyPodsPanel";
import ProfilePanel from "./mysettings/ProfilePanel";
import ManageAdminsPanel from "./mysettings/ManageAdminsPanel";
import ManageMembershipsPanel from "./mysettings/ManageMembershipsPanel";
import {Waitlist} from "../../domain/sdk/domain/Waitlist";
import NotificationsPanel from "./mysettings/NotificationsPanel";

export const MEMBERSHIPS = 'Memberships';
export const PROFILE = 'Profile';
export const NOTIFICATIONS = 'Notifications';
export const ACCOUNT = 'Account';
export const MY_PODS = 'My Pods';
export const ADMINS = 'Admins';

export default function MySettings() {
    const {state} = useLocation();
    const [isAdminOfCurrentMembership, setIsAdminOfCurrentMembership] = useState(false);
    const [isLeader, setIsLeader]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(true);
    const [selectedPanel, setSelectedPanel]: any = useState(state?.panel ?? PROFILE);
    const [memberships, setMemberships]: any = useState([] as Membership[]);
    const [settings, setSettings]: any = useState(new Map<string, Map<string, string>>());
    const [pods, setPods] = useState([] as Pod[]);
    const [waitlists, setWaitlists] = useState([] as Waitlist[]);

    useEffect(() => {
        let selectedBusiness = Sdk.context.selectedBusiness;
        let admin = selectedBusiness?.admin;
        let owner = selectedBusiness?.owner;
        setIsAdminOfCurrentMembership(owner || admin);
        (async () => {

            let myMemberships:MyMembership[] = [];
            if (Sdk.context.selectedBusiness) {
                myMemberships = await Sdk.membershipsClient.myMemberships(selectedBusiness.id)
            }
            let membershipSettings = new Map<string, Map<string, string>>();
            for (const membership of myMemberships) {
                let settings = await Sdk.membershipsClient.membershipSettings(membership.membershipId);
                if (settings != null && settings.size > 0) {
                    membershipSettings.set(membership.membershipId, settings);
                }
            }
            setSettings(membershipSettings);
            setMemberships(myMemberships);
            let myPods = await Sdk.podsClient.myPods();
            setPods(myPods.sort((a, b) => a.name.localeCompare(b.name)));
            let myWaitlists = await Sdk.podsClient.myWaitlists();
            setWaitlists(myWaitlists.sort((a, b) => a.name.localeCompare(b.name)));
            setIsLeader(myMemberships.filter(membership => membership.leader).length > 0);
            setIsLoading(false);
        })()
    }, [selectedPanel]);


    return <div>
        {!isLoading && <div className={`row`}>
            <div className={`columns small-12 medium-3`}>
                <div className={`${styles.Menu}`}>
                    <Link to={'/my-settings'} onClick={() => setSelectedPanel(PROFILE)}
                          className={selectedPanel === PROFILE ? `${styles.Selected}` : `${styles.Unselected}`}>
                        <div className={`${styles.MenuItem} ${selectedPanel === PROFILE ? `${styles.SelectedMenuItem}` : ``}`}>
                            {PROFILE}
                        </div>
                    </Link>
                    <Link to={'/my-settings'} onClick={() => setSelectedPanel(NOTIFICATIONS)}
                          className={selectedPanel === NOTIFICATIONS ? `${styles.Selected}` : `${styles.Unselected}`}>
                        <div className={`${styles.MenuItem} ${selectedPanel ===  NOTIFICATIONS ? `${styles.SelectedMenuItem}` : ``}`}>
                            {NOTIFICATIONS}
                        </div>
                    </Link>
                    {<Link to={'/my-settings'} onClick={() => setSelectedPanel(MEMBERSHIPS)}
                              className={selectedPanel === MEMBERSHIPS ? `${styles.Selected}` : `${styles.Unselected}`}>
                        <div className={`${styles.MenuItem} ${selectedPanel === MEMBERSHIPS ? `${styles.SelectedMenuItem}` : ``}`}>
                            {MEMBERSHIPS}
                        </div>
                    </Link>}
                    <Link to={'/my-settings'} onClick={() => setSelectedPanel(MY_PODS)}
                          className={selectedPanel === MY_PODS ? `${styles.Selected}` : `${styles.Unselected}`}>
                        <div className={`${styles.MenuItem} ${selectedPanel === MY_PODS ? `${styles.SelectedMenuItem}` : ``}`}>
                            {MY_PODS}
                        </div>
                    </Link>
                    <Link to={'/my-settings'} onClick={() => setSelectedPanel(ACCOUNT)}
                          className={selectedPanel === ACCOUNT ? `${styles.Selected}` : `${styles.Unselected}`}>
                        <div className={`${styles.MenuItem} ${selectedPanel === ACCOUNT ? `${styles.SelectedMenuItem}` : ``}`}>
                            {ACCOUNT}
                        </div>
                    </Link>
                    {isAdminOfCurrentMembership && <Link to={'/my-settings'} onClick={() => setSelectedPanel(ADMINS)}
                                      className={selectedPanel === ADMINS ? `${styles.Selected}` : `${styles.Unselected}`}>
                        <div className={`${styles.MenuItem} ${selectedPanel === ADMINS ? `${styles.SelectedMenuItem}` : ``}`}>
                            {ADMINS}
                        </div>
                    </Link>}
                </div>
            </div>
            <div className={`columns small-12 medium-9`}>
                <div className={`${styles.Panel}`}>
                    {selectedPanel === PROFILE && <ProfilePanel />}
                    {selectedPanel === NOTIFICATIONS && <NotificationsPanel />}
                    {selectedPanel === MEMBERSHIPS && <ManageMembershipsPanel />}
                    {selectedPanel === MY_PODS && <MyPodsPanel memberships={memberships} pods={pods} waitlists={waitlists} settings={settings} isLeader={isLeader}/>}
                    {selectedPanel === ACCOUNT && <AccountPanel isLeader={isLeader} isAdmin={isAdminOfCurrentMembership}/>}
                    {isAdminOfCurrentMembership && selectedPanel === ADMINS && <ManageAdminsPanel />}
                </div>
            </div>
        </div>}
    </div>;
}
