import * as React from "react"
import { SVGProps } from "react"

const Calendar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.749}
        height={18.976}
        {...props}
    >
        <g data-name="Layer 3" transform="translate(1 1)">
            <rect
                data-name="Rectangle 35"
                width={16.749}
                height={15.457}
                rx={3}
                transform="translate(0 1.518)"
                fill="none"
                stroke="#606060"
                strokeMiterlimit={10}
                strokeWidth={2}
            />
            <path
                data-name="Path 152"
                d="M.035 6.158h16.777"
                fill="none"
                stroke="#606060"
                strokeWidth={2}
            />
            <path
                data-name="Line 11"
                fill="none"
                stroke="#606060"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.031 0v.766"
            />
            <path
                data-name="Path 153"
                d="M12.717 0v.766"
                fill="none"
                stroke="#606060"
                strokeLinecap="round"
                strokeWidth={2}
            />
            <rect
                data-name="Rectangle 36"
                width={3.88}
                height={3.651}
                rx={1.825}
                transform="translate(10.482 11.501)"
                fill="#606060"
            />
        </g>
    </svg>
)

export default Calendar
