import React, {useEffect, useState} from 'react';
import styles from './LeaderPodParticipants.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import {useLocation} from "react-router-dom";
import {Leader} from "../../domain/sdk/domain/Leader";
import Breadcrumb from "../components/Breadcrumb";

export default function LeaderPodParticipants() {
    const {state} = useLocation();
    const [members, setMembers] = useState([] as Leader[]);
    const isAdmin = Sdk.context.selectedBusiness?.owner || Sdk.context.selectedBusiness?.admin;

    useEffect(() => {
        (async () => {
            let leaders = await Sdk.leadersClient.leaders(state.membershipId);
            leaders.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
            setMembers(leaders);
        })();
    }, [state.membershipId]);

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: isAdmin ? "Leader meetings" : "Progress Pods", link: isAdmin ? "/leader-pods" : "/pods", state: state},
                        {text: "Leader meeting", link: "/leader-pod", state: state},
                        {text: "Participants"},
                    ]}/>
                </div>
            </div>
            {members && <div className={`row`}>
                {members.map((leader, index) => {
                    return <div key={index} className={`columns small-12 ${styles.Participant}`}>
                        <div className={`${styles.NameText}`}>
                            {leader.firstName} {leader.lastName}
                        </div>
                        <div className={`${styles.BioText}`}>{leader.bio}</div>
                    </div>
                })}
            </div>}
        </div>
    </>;
}
