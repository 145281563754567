import React from 'react';
import styles from './RemoveAdmin.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import {ADMINS} from "./MySettings";

export default function RemoveAdmin() {
    const navigate = useNavigate();
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Remove admin
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        Are you sure you want to remove the admin {state.firstName} {state.lastName}?
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state: {...state, ...{panel: ADMINS}}});
                    }}/>
                    <ButtonPrimary style={styles.AssignButton} text="Yes. Remove admin" type="button" onClick={async () => {
                        await Sdk.businessAdminClient.removeAdmin(Sdk.context.selectedBusiness.id, state.id);
                        navigate('/my-settings', {state: {...state, ...{panel: ADMINS}}});
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidth} text="Yes. Remove admin" type="button" onClick={async () => {
                        await Sdk.businessAdminClient.removeAdmin(Sdk.context.selectedBusiness.id, state.id);
                        navigate('/my-settings', {state: {...state, ...{panel: ADMINS}}});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state: {...state, ...{panel: ADMINS}}});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
