import React, {useEffect, useState} from 'react';
import styles from './Notifications.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import {Link} from "react-router-dom";
import {Icon, InAppNotification, NotificationActionType} from "../../domain/sdk/domain/InAppNotification";
import {httpPost} from "../../domain/utils/HttpClient";
import parse from 'html-react-parser';
import {ReactComponent as Like} from '../svg/Like.svg'

export default function Notifications() {
    const [notifications, setNotifications] = useState([] as InAppNotification[]);

    useEffect(() => {
        (async () => {
            setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
        })();
    }, []);

    function determineButtonContent(notification: InAppNotification) {
        if (notification.primaryIcon) {
            if (notification.primaryIcon === Icon.Like) {
                return <Like style={{paddingTop: 3}}/>
            }
        }
        return notification.primaryDisplayName;
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>Notifications</div>
                </div>
            </div>
            {(!notifications || notifications.length === 0) &&
                <div className={`columns small-12`}>
                    <div className={`${styles.NoNotifications}`}>
                        <div className={`row`}>
                            <div className={`columns small-12`}>
                                <div>No notifications</div>
                            </div>
                        </div>
                    </div>
                </div>}
            {notifications && <div className={`row`}>
                {notifications.map((notification, index) => {
                    return <div key={index} className={`columns small-12`}>
                        <div className={`${styles.CardWrapper}`}>
                            <div className={`row`}>
                                <div className={`columns small-12`}>
                                    <div className={`${styles.CardTitle}`}>{notification.membershipName}</div>
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className={`columns small-12`}>
                                    <div className={`${styles.CardMessage}`}>{parse(notification.message)}</div>
                                </div>
                            </div>

                            {notification.actionType === NotificationActionType.ACTION && <div className={`row`}>
                                <div className={`columns small-12 hide-for-small-only`}>
                                    <div className={`${styles.CardItem}`}>
                                        <Link
                                            className={`${styles.CardLink}`}
                                            to={`/notifications`}
                                            onClick={async () => {
                                                if (notification.externalLink) {
                                                    window.open(notification.externalLink, '_blank');
                                                }
                                                let response = await httpPost(notification.primaryActionUrl);
                                                if (response.status === 200) {
                                                    await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                }
                                                setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                            }}>
                                            {notification.primaryDisplayName}
                                        </Link>
                                    </div>
                                </div>
                                <div className={`columns small-12 show-for-small-only`}>
                                    <div className={`${styles.CardItem}`}>
                                        <div className={`row`}>
                                            <div className={`columns small-12`}>
                                                <Link
                                                    className={`${styles.CardLink} ${styles.FullWidth}`}
                                                    to={`/notifications`}
                                                    onClick={async () => {
                                                        if (notification.externalLink) {
                                                            window.open(notification.externalLink, '_blank');
                                                        }
                                                        let response = await httpPost(notification.primaryActionUrl);
                                                        if (response.status === 200) {
                                                            await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                        }
                                                        setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                                    }}>
                                                    {notification.primaryDisplayName}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {notification.actionType === NotificationActionType.CHOICE && <div className={`row`}>
                                <div className={`columns small-12 hide-for-small-only`}>
                                    <div className={`${styles.CardItem}`}>
                                        <Link
                                            className={`${styles.SecondaryCardLink}`}
                                            to={`/notifications`}
                                            onClick={async () => {
                                                let response = await httpPost(notification.secondaryActionUrl);
                                                if (response.status === 200) {
                                                    await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                    setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                                }
                                            }}>
                                            {notification.secondaryDisplayName}
                                        </Link>
                                        <Link
                                            className={`${styles.CardLink}`}
                                            to={`/notifications`}
                                            onClick={async () => {
                                                let response = await httpPost(notification.primaryActionUrl);
                                                if (response.status === 200) {
                                                    await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                }
                                                setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                            }}>
                                            {notification.primaryDisplayName}
                                        </Link>
                                    </div>
                                </div>
                                <div className={`columns small-12 show-for-small-only`}>
                                    <div className={`${styles.CardItem}`}>
                                        <div className={`row`}>
                                            <div className={`columns small-12`}>
                                                <Link
                                                    className={`${styles.CardLink} ${styles.FullWidth}`}
                                                    to={`/notifications`}
                                                    onClick={async () => {
                                                        let response = await httpPost(notification.primaryActionUrl);
                                                        if (response.status === 200) {
                                                            await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                        }
                                                        setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                                    }}>
                                                    {notification.primaryDisplayName}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className={`columns small-12`}>
                                                <Link
                                                    className={`${styles.SecondaryCardLink} ${styles.FullWidth}`}
                                                    to={`/notifications`}
                                                    onClick={async () => {
                                                        let response = await httpPost(notification.secondaryActionUrl);
                                                        if (response.status === 200) {
                                                            await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                            setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                                        }
                                                    }}>
                                                    {notification.secondaryDisplayName}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {notification.actionType === NotificationActionType.DISMISSIBLE_ACTION && <div className={`row`}>
                                <div className={`columns small-12 hide-for-small-only`}>
                                    <div className={`${styles.CardItem}`}>
                                        <Link
                                            className={`${styles.SecondaryCardLink}`}
                                            to={`/notifications`}
                                            onClick={async () => {
                                                await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                            }}>
                                            {"DISMISS"}
                                        </Link>
                                        <Link
                                            className={`${styles.CardLink}`}
                                            to={`/notifications`}
                                            onClick={async () => {
                                                let response = await httpPost(notification.primaryActionUrl);
                                                if (response.status === 200) {
                                                    await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                }
                                                setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                            }}>
                                            {determineButtonContent(notification)}
                                        </Link>
                                    </div>
                                </div>
                                <div className={`columns small-12 show-for-small-only`}>
                                    <div className={`${styles.CardItem}`}>
                                        <div className={`row`}>
                                            <div className={`columns small-12`}>
                                                <Link
                                                    className={`${styles.CardLink} ${styles.FullWidth}`}
                                                    to={`/notifications`}
                                                    onClick={async () => {
                                                        let response = await httpPost(notification.primaryActionUrl);
                                                        if (response.status === 200) {
                                                            await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                        }
                                                        setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                                    }}>
                                                    {determineButtonContent(notification)}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className={`columns small-12`}>
                                                <Link
                                                    className={`${styles.SecondaryCardLink} ${styles.FullWidth}`}
                                                    to={`/notifications`}
                                                    onClick={async () => {
                                                        await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                        setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                                    }}>
                                                    {"DISMISS"}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {notification.actionType === NotificationActionType.ACKNOWLEDGE && <div className={`row`}>
                                <div className={`columns small-12`}>
                                    <div className={`${styles.CardItem}`}>
                                        <Link
                                            className={`${styles.CardLink}`}
                                            to={`/notifications`}
                                            onClick={async () => {
                                                await Sdk.inAppNotificationsClient.dismissNotification(notification.id);
                                                setNotifications(await Sdk.inAppNotificationsClient.myNotifications());
                                            }}>
                                            OK
                                        </Link>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                })}
            </div>}
        </div>
    </>;
}
