export default function validate(values:any) {
    let errors:any = {};
    if (values.firstName === undefined || values.firstName === '') {
        errors.firstName = 'First name is required';
    }
    if (values.lastName === undefined || values.lastName === '') {
        errors.lastName = 'Last name is required';
    }
    if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    if ((values.password && values.password.length < 8) || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(values.password)) {
        errors.password = '8 characters, 1 number & 1 uppercase';
    }
    if (values.confirm !== values.password) {
        errors.confirm = 'Passwords do not match';
    }
    return errors;
};