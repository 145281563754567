import React, {ButtonHTMLAttributes} from 'react';
import styles from './Message.module.css';

export interface MessageProps extends ButtonHTMLAttributes<any> {
    title: string;
    messages?: string[];
}

export default function Message({title, messages}:MessageProps) {

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        {title}
                    </div>
                    {messages?.map((message) => {return <div className={`${styles.MessageText}`}>
                        {message}
                    </div>})}
                    <div className={`${styles.FooterText}`}>
                        You can now close this tab
                    </div>
                </div>
            </div>
        </div>
    </>;
}
