export default function validate(values:any) {
    let errors:any = {};
    if (!values.email || values.email === '') {
        errors.email = 'Email is required';
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(values.email)) {
        errors.email = 'Email is not valid';
    }
    if (!values.name || values.name === '') {
        errors.name = 'Name is required';
    }
    return errors;
};