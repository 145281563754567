import * as React from "react"
import { SVGProps } from "react"

const People = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19.265}
        height={17.246}
        {...props}
    >
        <g data-name="Layer 9" fill="#606060">
            <path
                data-name="Path 128"
                d="M7.757 1.474A1.994 1.994 0 0 1 9.614 3.58a1.874 1.874 0 1 1-3.72 0 2 2 0 0 1 1.864-2.1m0-1.474a3.326 3.326 0 0 0-3.136 3.109 3.6 3.6 0 0 0 2.313 3.92 3.082 3.082 0 0 0 3.748-2.068A3.827 3.827 0 0 0 9.362.492 2.892 2.892 0 0 0 7.757 0Z"
            />
            <path
                data-name="Path 129"
                d="M7.758 9.823c3.1 0 5.782 2.463 6.4 5.9H1.355c.624-3.438 3.3-5.905 6.4-5.9m0-1.474c-3.866 0-7.156 3.182-7.749 7.5a1.284 1.284 0 0 0 .243.94.993.993 0 0 0 .789.406h13.435a.937.937 0 0 0 .176-.017 1.166 1.166 0 0 0 .856-1.332c-.594-4.318-3.883-7.5-7.747-7.5Z"
            />
            <path
                data-name="Path 130"
                d="M11.505.035a2.741 2.741 0 0 0-.489.043 4.991 4.991 0 0 1 1.43 3.532 5 5 0 0 1-1.4 3.547A3.017 3.017 0 0 0 14.087 5.7a3.978 3.978 0 0 0 .241-3.725 3.144 3.144 0 0 0-2.823-1.94Z"
            />
            <path
                data-name="Path 131"
                d="M12.451 8.454a10.04 10.04 0 0 1 4.564 7.287 2.564 2.564 0 0 1-.252 1.5h1.458a1 1 0 0 0 .791-.408 1.286 1.286 0 0 0 .242-.944c-.539-3.922-3.317-6.962-6.8-7.442Z"
            />
        </g>
    </svg>
)

export default People
