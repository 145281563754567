import React from 'react';
import SetupMeeting from "../components/SetupMeeting";
import {validateMeetingSchedule} from "../../domain/utils/DateTimeUtils";

export default function AddLeaderPod() {

    return <SetupMeeting
        validator={validateMeetingSchedule}
        leaderPod={true}
        supportAdHoc={true}
        edit={false}
    />
}
