import SvgProps from "./SvgProps";


export interface ToggleProps extends SvgProps {
    enabled: boolean;
}

export default function Toggle({enabled, colour = "#ea7e3d", ...props}: ToggleProps) {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30" height="15.714" viewBox="0 0 30 15.714"
        {...props}>
        {!enabled && <path
            id="toggle_off"
            data-name="toggle off"
            d="M67.858-684.285a7.579,7.579,0,0,1-5.565-2.291A7.573,7.573,0,0,1,60-692.139a7.583,7.583,0,0,1,2.292-5.566A7.573,7.573,0,0,1,67.858-700H82.144a7.578,7.578,0,0,1,5.565,2.291A7.572,7.572,0,0,1,90-692.145a7.583,7.583,0,0,1-2.292,5.566,7.573,7.573,0,0,1-5.565,2.294Zm0-2.143H82.144a5.5,5.5,0,0,0,4.036-1.679,5.5,5.5,0,0,0,1.679-4.036,5.5,5.5,0,0,0-1.679-4.036,5.5,5.5,0,0,0-4.036-1.679H67.858a5.5,5.5,0,0,0-4.036,1.679,5.5,5.5,0,0,0-1.679,4.036,5.5,5.5,0,0,0,1.679,4.036A5.5,5.5,0,0,0,67.858-686.428Zm0-1.786a3.792,3.792,0,0,0,2.783-1.145,3.784,3.784,0,0,0,1.147-2.782,3.792,3.792,0,0,0-1.145-2.783,3.784,3.784,0,0,0-2.782-1.147,3.792,3.792,0,0,0-2.783,1.145,3.784,3.784,0,0,0-1.147,2.782,3.792,3.792,0,0,0,1.145,2.783A3.784,3.784,0,0,0,67.857-688.213ZM75-692.142Z"
            transform="translate(-60.001 699.999)"
            fill="#696969"
        />}
        {enabled && <path
            id="toggle_on"
            data-name="toggle on"
            d="M67.858-684.285a7.579,7.579,0,0,1-5.565-2.291A7.572,7.572,0,0,1,60-692.139a7.583,7.583,0,0,1,2.292-5.566A7.573,7.573,0,0,1,67.858-700H82.144a7.578,7.578,0,0,1,5.565,2.291A7.572,7.572,0,0,1,90-692.145a7.583,7.583,0,0,1-2.292,5.566,7.573,7.573,0,0,1-5.565,2.294Zm0-2.143H82.144a5.5,5.5,0,0,0,4.036-1.679,5.5,5.5,0,0,0,1.679-4.036,5.5,5.5,0,0,0-1.679-4.036,5.5,5.5,0,0,0-4.036-1.679H67.858a5.5,5.5,0,0,0-4.036,1.679,5.5,5.5,0,0,0-1.679,4.036,5.5,5.5,0,0,0,1.679,4.036A5.5,5.5,0,0,0,67.858-686.428Zm14.284-1.786a3.792,3.792,0,0,0,2.783-1.145,3.785,3.785,0,0,0,1.147-2.782,3.792,3.792,0,0,0-1.145-2.783,3.784,3.784,0,0,0-2.782-1.147,3.792,3.792,0,0,0-2.783,1.145,3.784,3.784,0,0,0-1.147,2.781,3.792,3.792,0,0,0,1.145,2.783A3.784,3.784,0,0,0,82.142-688.213ZM75-692.142Z"
            transform="translate(-60.001 699.999)"
            fill={colour}
        />}
    </svg>;
}