import * as React from "react"
import {SVGProps} from "react"

const Logo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="Progress Icon"
        xmlns="http://www.w3.org/2000/svg"
        width={35.171}
        height={31.174}
        {...props}
    >
      <path
          data-name="Path 158"
          d="M7.676 5.96a15.041 15.041 0 0 0 1.936 20.488 14.684 14.684 0 0 0 20.358-.915 15.044 15.044 0 0 0 .125-20.581"
          fill="none"
      />
      <path
          data-name="Path 159"
          d="M19.303 31.173h.358l.179-.005.179-.007a15.861 15.861 0 0 0 3-.423 15.694 15.694 0 0 0 5.421-2.471 15.95 15.95 0 0 0 6.424-9.92 16.223 16.223 0 0 0 .039-6.066 15.941 15.941 0 0 0-4.025-7.988 1.314 1.314 0 0 0-.208-.181 1.3 1.3 0 0 0-1.437-.034 1.319 1.319 0 0 0-.26 2.014 13.38 13.38 0 0 1-2.033 20.025 13.144 13.144 0 0 1-7.036 2.414l-.15.006H19.306a13.106 13.106 0 0 1-6.75-1.864 13.3 13.3 0 0 1-5.949-7.7 13.549 13.549 0 0 1-.48-5.044 13.333 13.333 0 0 1 2.76-6.947 1.318 1.318 0 0 0-.41-1.968 1.294 1.294 0 0 0-.617-.157 1.3 1.3 0 0 0-1.029.5A16.062 16.062 0 0 0 5.21 22.52a16.009 16.009 0 0 0 6.012 6.424 15.7 15.7 0 0 0 8.081 2.231Z"
          fill="#37694a"
      />
      <path
          data-name="Ellipse 9"
          d="M13.168 0a1.712 1.712 0 1 0 .672.135A1.712 1.712 0 0 0 13.168 0Z"
          fill="#37694a"
      />
      <path
          data-name="Ellipse 10"
          d="M19.675 0a1.712 1.712 0 1 0 .672.135A1.712 1.712 0 0 0 19.675 0Z"
          fill="#37694a"
      />
      <path
          data-name="Ellipse 11"
          d="M26.182 0a1.712 1.712 0 1 0 .672.135A1.712 1.712 0 0 0 26.182 0Z"
          fill="#37694a"
      />
      <path
          data-name="Path 145"
          d="M.069 7.252a1.513 1.513 0 0 0 1.865 1l4.841-1.427 1.209 4.905a1.514 1.514 0 0 0 1.8 1.108 1.447 1.447 0 0 0 1.092-1.764L9.3 4.662a1.485 1.485 0 0 0-.367-.664l-.022-.024a1.519 1.519 0 0 0-1.526-.416L1.044 5.422a1.447 1.447 0 0 0-.975 1.83Z"
          fill="#37694a"
      />
    </svg>
)

export default Logo
