import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import styles from './EditMembership.module';
import ButtonPrimary from '../components/ButtonPrimary';
import ButtonCancel from "../components/ButtonCancel";
import formUtils from "../../domain/utils/UseForm";
import {validate} from "./EditMembership.validation";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import {Sdk} from "../../domain/sdk/Sdk";
import {DefaultLinks, Setting} from "../../domain/sdk/domain/Setting";
import {ReactComponent as Open} from "../svg/Open.svg";
import {ReactComponent as Reset} from "../svg/Reset.svg";
import {CustomButton} from "../../domain/sdk/domain/CustomButton";
import Checkbox from "../components/Checkbox";
import UploadImage from "../components/UploadImage";
import CustomLogo from "../components/CustomLogo";
import ButtonSecondary from "../components/ButtonSecondary";

export default function EditMembership() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [defaults, setDefaults] = useState({} as DefaultLinks);
    const [ntsDisabled, setNtsDisabled] = useState(false);
    const [chatDisabled, setChatDisabled] = useState(false);
    const [selectPodOnSignupDisabled, setSelectPodOnSignupDisabled] = useState(false);
    const [avatar, setAvatar] = useState(undefined as undefined | string);
    const [editingAvatar, setEditingAvatar] = useState(false);
    const {values, setValues, errors, handleChange, handleCheckboxChange, handleSubmit} = formUtils(onFormSubmit, validate);

    useEffect(() => {
        if (!loaded) {
            const {name, url, communityUrl, supportEmail, settings, features, customButtons} = state;
            let existing = (settings as Setting[]).reduce(function (map: any, obj) {
                map[obj.name] = obj.value;
                return map;
            }, {});

            setNtsDisabled(features?.NextTinyStep ?? false);
            setChatDisabled(features?.Chat ?? false);
            setSelectPodOnSignupDisabled(features?.SelectPodOnSignup ?? false);
            const podButtons = customButtons.filter((button:CustomButton) => button.placement === 'VIEW_POD')
            const signUpButtons = customButtons.filter((button:CustomButton) => button.placement === 'PARTICIPANT_SIGNUP')
            let customButton1FormLabel;
            let customButton1Label;
            let customButton1Url;
            if (podButtons && podButtons.length > 0) {
                customButton1FormLabel = podButtons[0].formLabel;
                customButton1Label = podButtons[0].label;
                customButton1Url = podButtons[0].url;
            }
            let customButton2FormLabel;
            let customButton2Label;
            let customButton2Url;
            if (signUpButtons && signUpButtons.length > 0) {
                customButton2FormLabel = signUpButtons[0].formLabel;
                customButton2Label = signUpButtons[0].label;
                customButton2Url = signUpButtons[0].url;
            }

            setValues({
                name,
                url,
                communityUrl,
                supportEmail,
                leaderAgreementLink: existing.LeaderAgreementLink,
                participantAgreementLink: existing.ParticipantAgreementLink,
                leaderTrainingLink: existing.LeaderTrainingLink,
                participantOnboardingLink: existing.ParticipantOnboardingLink,
                shareWinLink: existing.ShareWinLink,
                maxParticipants: existing.MaxParticipants,
                customButton1FormLabel: customButton1FormLabel,
                customButton1Label: customButton1Label,
                customButton1Url: customButton1Url,
                customButton2FormLabel: customButton2FormLabel,
                customButton2Label: customButton2Label,
                customButton2Url: customButton2Url
            });
            (async () => {
                setDefaults(await Sdk.membershipsClient.defaultLinks());
            })();
            setLoaded(true);
        }
    }, [setValues, state, loaded])

    async function onFormSubmit() {
        let buttons: CustomButton[] = [];
        if (values.customButton1Label && values.customButton1Label !== '') {
            buttons.push({
                formLabel: values.customButton1FormLabel,
                label: values.customButton1Label,
                url: values.customButton1Url,
                placement: 'VIEW_POD'
            });
        }
        if (values.customButton2Label && values.customButton2Label !== '') {
            buttons.push({
                formLabel: values.customButton2FormLabel,
                label: values.customButton2Label,
                url: values.customButton2Url,
                placement: 'PARTICIPANT_SIGNUP'
            });
        }
        await Sdk.membershipsClient.update(state.membershipId, values.name, values.url, values.communityUrl, values.supportEmail, {
            LeaderAgreementLink: values.leaderAgreementLink,
            ParticipantAgreementLink: values.participantAgreementLink,
            LeaderTrainingLink: values.leaderTrainingLink,
            ParticipantOnboardingLink: values.participantOnboardingLink,
            ShareWinLink: values.shareWinLink,
            MaxParticipants: values.maxParticipants,
        }, {
            NextTinyStep: ntsDisabled,
            Chat: chatDisabled,
            SelectPodOnSignup: selectPodOnSignupDisabled
        }, buttons);
        if (avatar) {
            await Sdk.logoClient.upload(state.membershipId, avatar);
        }
        navigate('/memberships');
    }

    return <>
        <div>
            <div className={`row`}>
                <form style={styles.InviteForm} onSubmit={handleSubmit}>
                    <div className={`columns small-12`}>
                        <div style={styles.TitleWrapper}>
                            Edit membership
                        </div>
                    </div>
                    <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Membership Details
                        </div>
                    </div>
                    <div className={`columns small-12 medium-8`}>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership name" name="name" placeholder={"e.g. Smart Moves"} onChange={handleChange} value={values.name || ''}/>
                            {errors.name && (<p style={styles.ErrorMessage}>{errors.name}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership support email" name="supportEmail" placeholder={"Email"} onChange={handleChange}
                                                    value={values.supportEmail || ''}/>
                            {errors.supportEmail && (<p style={styles.ErrorMessage}>{errors.supportEmail}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership website URL (Optional)" name="url" placeholder={"Web link"} onChange={handleChange} value={values.url || ''}/>
                            {errors.url && (<p style={styles.ErrorMessage}>{errors.url}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership community URL (Optional)" name="communityUrl" placeholder={"Web link, e.g. Facebook"} onChange={handleChange}
                                                    value={values.communityUrl || ''}/>
                            {errors.communityUrl && (<p style={styles.ErrorMessage}>{errors.communityUrl}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <Checkbox
                                name="ntsEnabled"
                                label="Enable Next Tiny Step module"
                                labelTextStyle={styles.FeatureCheckbox}
                                onChange={handleCheckboxChange}
                                onClick={() => setNtsDisabled(!ntsDisabled)}
                                checked={!ntsDisabled}>
                            </Checkbox>
                        </div>
                        <div style={styles.InputWrapper}>
                            <Checkbox
                                name="chatDisabled"
                                label="Enable chat"
                                labelTextStyle={styles.FeatureCheckbox}
                                onChange={handleCheckboxChange}
                                onClick={() => setChatDisabled(!chatDisabled)}
                                checked={!chatDisabled}>
                            </Checkbox>
                        </div>
                        <div style={styles.LastInputWrapper}>
                            <Checkbox
                                name="selectPodOnSignupDisabled"
                                label="Allow members to select a pod when signing up"
                                labelTextStyle={styles.FeatureCheckbox}
                                onChange={handleCheckboxChange}
                                onClick={() => setSelectPodOnSignupDisabled(!selectPodOnSignupDisabled)}
                                checked={!selectPodOnSignupDisabled}>
                            </Checkbox>
                        </div>
                    </div>
                    <div className={`columns small-12 medium-4 text-center`} style={{marginBottom:30}}>
                        {!editingAvatar && <>
                            <CustomLogo membershipId={state.membershipId} />
                            <ButtonSecondary style={{width: 197, marginTop:10}} text={"Edit"} onClick={()=> setEditingAvatar(true)} />
                        </>}
                        {editingAvatar && <UploadImage
                            showRemoveButton={false}
                            onImageChange={async (data) => {
                                setAvatar(data);
                            }}
                        />}
                        {editingAvatar && <>
                            <ButtonCancel style={{width: 197, marginTop:10}} text={"Cancel"} onClick={()=> {
                                setAvatar(undefined);
                                setEditingAvatar(false);
                            }} />
                        </>}
                    </div>

                    <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Onboarding & training links
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to leader agreement" name="leaderAgreementLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.leaderAgreementLink || ''}/>
                                {errors.leaderAgreementLink && (<p style={styles.ErrorMessage}>{errors.leaderAgreementLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.leaderAgreementLink} rel="noreferrer"><Open/></a>
                                <Link to={""} state={state} onClick={() => values.leaderAgreementLink = defaults.LeaderAgreementLink}><Reset/></Link>
                            </div>
                        </div>

                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to participant agreement" name="participantAgreementLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.participantAgreementLink || ''}/>
                                {errors.participantAgreementLink && (<p style={styles.ErrorMessage}>{errors.participantAgreementLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.participantAgreementLink} rel="noreferrer"><Open/></a>
                                <Link to={""} state={state} onClick={() => values.participantAgreementLink = defaults.ParticipantAgreementLink}><Reset/></Link>
                            </div>
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to leader training" name="leaderTrainingLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.leaderTrainingLink || ''}/>
                                {errors.leaderTrainingLink && (<p style={styles.ErrorMessage}>{errors.leaderTrainingLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.leaderTrainingLink} rel="noreferrer"><Open/></a>
                                <Link to={""} state={state} onClick={() => values.leaderTrainingLink = defaults.LeaderTrainingLink}><Reset/></Link>
                            </div>
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to participant onboarding" name="participantOnboardingLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.participantOnboardingLink || ''}/>
                                {errors.participantOnboardingLink && (<p style={styles.ErrorMessage}>{errors.participantOnboardingLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.participantOnboardingLink} rel="noreferrer"><Open/></a>
                                <Link to={""} state={state} onClick={() => values.participantOnboardingLink = defaults.ParticipantOnboardingLink}><Reset/></Link>
                            </div>
                        </div>

                        <div style={styles.TextBlock}>
                            Add a custom link to the sign up page (optional)
                        </div>
                        <div className={`show-for-small-only`}>
                            <div>
                                <FormInputTextWithLabel
                                    label="Link text"
                                    name="customButton2Label"
                                    placeholder={"Link text"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton2Label || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Link"
                                    name="customButton2Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton2Url || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton2FormLabel"
                                    placeholder={"Appears above the link"}
                                    onChange={handleChange}
                                    value={values.customButton2FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div className={`hide-for-small-only`} style={styles.InputWithDefaultsWrapper}>
                            <div style={{flex: 2, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Link text"
                                    name="customButton2Label"
                                    placeholder={"Link text"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton2Label || ''}
                                />
                            </div>
                            <div style={{flex: 4, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Link"
                                    name="customButton2Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton2Url || ''}
                                />
                            </div>
                            <div style={{flex: 3}}>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton2FormLabel"
                                    placeholder={"Appears above the link"}
                                    onChange={handleChange}
                                    value={values.customButton2FormLabel || ''}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Pod Configuration
                        </div>

                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Maximum participants per pod" name="maxParticipants" placeholder={"8"} onChange={handleChange}
                                                        value={values.maxParticipants || ''}/>
                                {errors.maxParticipants && (<p style={styles.ErrorMessage}>{errors.maxParticipants}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <Link to={""} state={state} onClick={() => values.maxParticipants = defaults.MaxParticipants}><Reset/></Link>
                            </div>
                        </div>
                        <div style={styles.TextBlock}>
                            Add a custom button to each Progress Pod. (optional)
                        </div>
                        <div className={`show-for-small-only`}>
                            <div>
                                <FormInputTextWithLabel
                                    label="Button text"
                                    name="customButton1Label"
                                    placeholder={"Click me"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton1Label || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Button link"
                                    name="customButton1Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton1Url || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton1FormLabel"
                                    placeholder={"Appears above the button"}
                                    onChange={handleChange}
                                    value={values.customButton1FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div className={`hide-for-small-only`} style={styles.InputWithDefaultsWrapper}>
                            <div style={{flex: 2, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Button text"
                                    name="customButton1Label"
                                    placeholder={"Click me"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton1Label || ''}
                                />
                            </div>
                            <div style={{flex: 4, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Button link"
                                    name="customButton1Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton1Url || ''}
                                />
                            </div>
                            <div style={{flex: 3, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton1FormLabel"
                                    placeholder={"Appears above the button"}
                                    onChange={handleChange}
                                    value={values.customButton1FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div>
                            {errors.customButtons && (<p style={styles.ErrorMessage}>{errors.customButtons}</p>)}
                        </div>
                    </div>

                    <div className={`columns small-12 small-centered show-for-small-only`}>
                        <ButtonPrimary style={styles.FullWidth} text="Save" type="submit"/>
                        <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                            navigate('/memberships')
                        }}/>
                    </div>
                    <div className={`columns medium-9 large-6 hide-for-small-only float-right`}>
                        <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                            navigate('/memberships')
                        }}/>
                        <ButtonPrimary style={styles.AssignButton} text="Save" type="submit"/>
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        {errors.other && (<p style={styles.ErrorMessage}>{errors.other}</p>)}
                    </div>
                </form>
            </div>
        </div>
    </>;
}
