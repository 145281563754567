import {createContext} from 'react';

interface ContextProps {
    selectedMembershipId: string | undefined,
    setSelectedMembershipId: (membershipId?: string) => void,

}

export const SecureContext = createContext<ContextProps>({
    selectedMembershipId: undefined,

    setSelectedMembershipId(membershipId?: string): void {
        this.selectedMembershipId = membershipId;
    },
});