import React from 'react';
import styles from './ResetPassword.module.css';
import validate from './ResetPassword.validation';
import formUtils from '../../domain/utils/UseForm';
import ButtonPrimary from '../components/ButtonPrimary';
import {Sdk} from '../../domain/sdk/Sdk';
import {useNavigate, useSearchParams} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import FormInputPassword from "../components/FormInputPassword";

export default function ResetPassword() {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams({});
    let code = searchParams.get("code");
    const {values, errors, setErrors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    async function onFormSubmit() {
        if (code !== null) {
            let response = await Sdk.userClient.resetPassword(values.password, code);
            if (response.status === 200) {
                navigate('password-changed')
            } else {
                if (response.status === 400) {
                    setErrors({other: response.data})
                } else {
                    setErrors({other: 'Something went wrong'})
                }
            }
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.UserForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            Reset Password
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="password"
                                               placeholder={"New password"}
                                               onChange={handleChange}
                                               value={values.password || ''}
                                               autoComplete="new-password"/>
                            {errors.password && (<p className={`${styles.ErrorMessage}`}>{errors.password}</p>)}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputPassword name="confirm"
                                               onChange={handleChange}
                                               placeholder={"Retype new password"}
                                               value={values.confirm || ''}
                                               autoComplete="new-password"/>
                            {errors.confirm && (<p className={`${styles.ErrorMessage}`}>{errors.confirm}</p>)}
                        </div>
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Save" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={() => {
                                navigate('/login', {state: values})
                            }}/>
                        </div>
                        {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}
                    </form>
                </div>
            </div>
        </div>
    </>;
}
