import React, {useState} from 'react';
import styles from './ChangePassword.module.css';
import validate from './ChangePassword.validation';
import formUtils from '../../domain/utils/UseForm';
import ButtonPrimary from '../components/ButtonPrimary';
import {Sdk} from '../../domain/sdk/Sdk';
import {useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import FormInputPassword from "../components/FormInputPassword";
import {ACCOUNT} from "./MySettings";

export default function ChangePassword() {
    const navigate = useNavigate();

    const {values, errors, setErrors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);
    const [success, setSuccess] = useState(false);

    async function onFormSubmit() {
        let response = await Sdk.userClient.changePassword(values.oldPassword, values.newPassword);
        if (response.status === 200) {
            navigate('/login')
        } else {
            if (response.status === 400) {
                setErrors({other: response.data})
            } else {
                setErrors({other: 'Something went wrong'})
            }
            setSuccess(false);
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.UserForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            Change Password
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="oldPassword"
                                               placeholder={"Current password"}
                                               onChange={handleChange}
                                               value={values.oldPassword || ''}
                                               autoComplete="current-password"/>
                            {errors.oldPassword && (<p className={`${styles.ErrorMessage}`}>{errors.oldPassword}</p>)}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="newPassword"
                                               onChange={handleChange}
                                               placeholder={"New password"}
                                               value={values.newPassword || ''}
                                               autoComplete="new-password"/>
                            {errors.newPassword && (<p className={`${styles.ErrorMessage}`}>{errors.newPassword}</p>)}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputPassword name="confirm"
                                               onChange={handleChange}
                                               placeholder={"Retype new password"}
                                               value={values.confirm || ''}
                                               autoComplete="new-password"/>
                            {errors.confirm && (<p className={`${styles.ErrorMessage}`}>{errors.confirm}</p>)}
                        </div>
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Save" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={() => {
                                navigate('/my-settings', {state : {panel:ACCOUNT}});
                            }}/>
                        </div>
                        {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}
                        {success && (<p className={`${styles.ErrorMessage}`}>{"Password changed"}</p>)}
                    </form>
                </div>
            </div>
        </div>
    </>;
}
