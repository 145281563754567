import styles from './LogoWrapper.module.css'
import React, {HTMLAttributes, useContext} from 'react';
import CustomLogo from "./CustomLogo";
import {SecureContext} from "../state/SecureContext";

export default function LogoWrapper({className, onClick}: HTMLAttributes<any>) {
    const {selectedMembershipId} = useContext(SecureContext);

    return <div className={`${styles.Wrapper} ${className ? className : ''}`} onClick={onClick}>
        <CustomLogo width={51} height={51} center={true} membershipId={selectedMembershipId}/>
    </div>
}
