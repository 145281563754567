import React from 'react';
import styles from './CheckInbox.module.css';

export default function CheckInbox() {

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-8 large-6 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Please check your inbox
                    </div>
                    <div className={`${styles.CheckInboxText}`}>
                        You can now close this tab
                    </div>
                </div>
            </div>
        </div>
    </>;
}
