import styles from './FormInputText.module.css'
import React, {InputHTMLAttributes} from 'react';

export interface FormInputTextProps extends InputHTMLAttributes<any> {
    className?: string;
}

export default function FormInputText({className, name, ...other}: FormInputTextProps) {
    return <div className={`${className ? className : ''}`}>
        <div className={`${styles.InputText_Wrapper}`}>
            <input className={`${styles.InputText_Input}`} type="text" name={name} {...other}/>
        </div>
    </div>
}
