import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost, httpPut} from "../utils/HttpClient";
import {Pod, PodResponse} from "./domain/Pod";
import {ScheduledMeetingsClient} from "./ScheduledMeetingsClient";
import {PodMember} from "./domain/PodMember";
import {Waitlist, WaitlistResponse} from "./domain/Waitlist";
import {PodRequest} from "./domain/request/PodRequest";
import {LeaderTransfer} from "./domain/LeaderTransfer";
import {AssistantLeader} from "./domain/AssistantLeader";
import {AxiosResponse} from "axios";

export class PodsClient {
    private readonly membershipsUrl: string;
    private readonly podsUrl: string;
    private readonly leaderTransfersUrl: string;
    private scheduledMeetingsClient: ScheduledMeetingsClient;

    constructor(config: SdkConfig, scheduledMeetingsClient: ScheduledMeetingsClient) {
        this.membershipsUrl = `${config.apiUrl}/v1/memberships`;
        this.podsUrl = `${config.apiUrl}/v1/pods`;
        this.leaderTransfersUrl = `${config.apiUrl}/v1/leader-transfers`;
        this.scheduledMeetingsClient = scheduledMeetingsClient;
    }

    async create(membershipId: string, request: PodRequest) {
        return await httpPost(`${this.membershipsUrl}/${membershipId}/pods`, request);
    }

    async pods(membershipId: string) {
        return this.podsFilterBy(membershipId, "");
    }

    async podsFilterBy(membershipId: string, filters: string) {
        let url = `${this.membershipsUrl}/${membershipId}/pods`;
        if (filters !== '') {
            url += `?${filters}`;
        }
        let response = await httpGet(url);
        if (response.status === 200) {
            const meetings: Pod[] = [];
            for (const pod of response.data as PodResponse[]) {
                const meeting = await this.scheduledMeetingsClient.scheduledMeeting(pod.meetingScheduleId);
                meetings.push({
                    ...pod,
                    scheduledMeeting: meeting
                })
            }
            return meetings;
        }
        return [];
    }

    async pod(podId: string): Promise<Pod | undefined> {
        let response = await httpGet(`${this.podsUrl}/${podId}`);
        if (response.status === 200) {
            const pod = response.data as PodResponse;
            const meeting = await this.scheduledMeetingsClient.scheduledMeeting(pod.meetingScheduleId);
            return {
                ...pod,
                scheduledMeeting: meeting
            };
        }
    }

    async updateDetails(podId: string, request: PodRequest) {
        return await httpPut(`${this.podsUrl}/${podId}`, request);
    }

    async join(podId: string) {
        return await httpPost(`${this.podsUrl}/${podId}/join`);
    }

    async leave(podId: string) {
        return await httpDelete(`${this.podsUrl}/${podId}/members`);
    }

    async joinWaitlist(podId: string, consentJoinNewPod: boolean, consentToBeNewPodLeader: boolean) {
        return await httpPost(`${this.podsUrl}/${podId}/waitlist`, {
            consentJoinNewPod,
            consentToBeNewPodLeader
        });
    }

    async members(podId: string): Promise<PodMember[]> {
        let response = await httpGet(`${this.podsUrl}/${podId}/members`);
        if (response.status === 200) {
            return response.data.sort((a: any, b: any) => b.leader - a.leader);
        }
        return [];
    }

    async myPods(): Promise<Pod[]> {
        let response = await httpGet(`${this.podsUrl}/my-pods`);
        if (response.status === 200) {
            const pods: Pod[] = [];
            for (const pod of response.data as PodResponse[]) {
                const scheduledMeeting = await this.scheduledMeetingsClient.scheduledMeeting(pod.meetingScheduleId);
                pods.push({
                    ...pod,
                    scheduledMeeting
                })
            }
            return pods;
        }
        return [];
    }

    async switchPod(fromPodId: string, toPodId: string) {
        return await httpPost(`${this.podsUrl}/${fromPodId}/switch`, {
            toPodId
        })
    }

    async assistantLeaderByPod(podId: string): Promise<AssistantLeader | undefined> {
        let response = await httpGet(`${this.podsUrl}/${podId}/assistant-leader`);
        if (response.status === 200) {
            return response.data as AssistantLeader;
        }
    }

    async assignAssistantLeader(podId: string, participantId: string) {
        return await httpPost(`${this.podsUrl}/${podId}/assistant-leader`, {
            participantId
        })
    }

    async confirmAssistantLeader(podId: string, meetingLink:string) {
        return await httpPost(`${this.podsUrl}/${podId}/assistant-leader/confirm`, {meetingLink});
    }

    async unassignAssistantLeader(podId: string):Promise<AxiosResponse<string>> {
        return await httpDelete(`${this.podsUrl}/${podId}/assistant-leader`);
    }

    async transferLeaderTo(podId: string, participantId: string) {
        return await httpPost(`${this.podsUrl}/${podId}/leader-transfers`, {
            participantId
        })
    }

    async leaderTransferBy(transferId: string): Promise<LeaderTransfer | undefined> {
        let response = await httpGet(`${this.leaderTransfersUrl}/${transferId}`);
        if (response.status === 200) {
            return response.data;
        }
    }

    async acceptTransfer(transferId: string, meetingLink: string) {
        return await httpPost(`${this.leaderTransfersUrl}/${transferId}/accept`, {
            meetingLink
        })
    }

    async declineTransfer(transferId: string) {
        return await httpPost(`${this.leaderTransfersUrl}/${transferId}/decline`);
    }

    async myWaitlists(): Promise<Waitlist[]> {
        let response = await httpGet(`${this.podsUrl}/my-waitlists`);
        if (response.status === 200) {
            const waitlists: Waitlist[] = [];
            for (const waitlist of response.data as WaitlistResponse[]) {
                const scheduledMeeting = await this.scheduledMeetingsClient.scheduledMeeting(waitlist.meetingScheduleId);
                waitlists.push({
                    ...waitlist,
                    scheduledMeeting
                })
            }
            return waitlists;
        }
        return [];
    }

    async waitlists(membershipId: string): Promise<Waitlist[]> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/waitlists`);
        if (response.status === 200) {
            return response.data as Waitlist[];
        }
        return [];
    }

    async removeParticipant(podId: string, memberId: string) {
        return await httpDelete(`${this.podsUrl}/${podId}/members/${memberId}`);
    }

    async downloadAgenda(podId: string) {
        window.open(`${this.podsUrl}/${podId}/agenda?authToken=${localStorage.getItem('access_token')}`, '_blank');
    }
}