import * as React from "react"
import { SVGProps } from "react"

const Chevron = (props: SVGProps<SVGSVGElement>) => (
    <svg width={12} height={7} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M10.59.02 6 4.348 1.41.021 0 1.353 6 7.02l6-5.668z"
            fill={"#606060"}
            fillRule="evenodd"
        />
    </svg>
)

export default Chevron
