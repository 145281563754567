import React from 'react';
import styles from './RequestLeadershipSent.module';
import {useLocation, useNavigate} from "react-router-dom";
import {MY_PODS} from "./MySettings";
import ButtonPrimary from "../components/ButtonPrimary";

export default function RequestLeadershipSent() {
    const {state} = useLocation();
    const navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Request sent
                    </div>
                </div>

                <div className={`columns medium-9 large-12 medium-centered show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidthDelete} text="Okay" type="button" onClick={async () => {
                        navigate('/my-settings', {state : {...state, ...{panel:MY_PODS}}});
                    }}/>
                </div>

                <div className={`columns medium-3 medium-centered hide-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidthDelete} text="Okay" type="button" onClick={async () => {
                        navigate('/my-settings', {state : {...state, ...{panel:MY_PODS}}});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
