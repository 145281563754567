import React, {useContext, useEffect, useState} from 'react';
import styles from './Memberships.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import {Link, useNavigate} from "react-router-dom";
import {MyMembership} from "../../domain/sdk/domain/Membership";
import People from "../svg/People";
import Anchor from "../svg/Anchor";
import Calendar from "../svg/Calendar";
import {ReactComponent as Settings} from '../svg/Settings.svg'
import {LeaderStatus} from "../../domain/sdk/domain/LeaderStatus";
import {DefaultLinks} from "../../domain/sdk/domain/Setting";
import {ACCOUNT, MEMBERSHIPS} from "./MySettings";
import {SecureContext} from "../state/SecureContext";

export default function Memberships() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [isAdminOfAny, setIsAdminOfAny] = useState(false);
    const [memberships, setMemberships] = useState([] as MyMembership[]);
    const [leaderTrainingLinks, setLeaderTrainingLinks] = useState(new Map<string, string>());
    const [onboardingLinks, setOnboardingLinks] = useState(new Map<string, string>());
    const [defaults, setDefaults] = useState({} as DefaultLinks);

    useEffect(() => {
        setSelectedMembershipId(undefined);
        (async () => {
            setDefaults(await Sdk.membershipsClient.defaultLinks());
            let myMemberships:MyMembership[] = [];
            if (Sdk.context.selectedBusiness) {
                myMemberships = await Sdk.membershipsClient.myMemberships(Sdk.context.selectedBusiness.id);
            }
            setIsAdminOfAny(Sdk.context.selectedBusiness?.owner || Sdk.context.selectedBusiness?.admin);
            setMemberships(myMemberships);
            let leaderTrainingLinks = new Map<string, string>();
            let onboardingLinks = new Map<string, string>();
            for (const membership of myMemberships) {
                let leaderStatus = await Sdk.leadersClient.leaderStatus(membership.membershipId);
                if (leaderStatus !== LeaderStatus.NONE) {
                    let trainingLink = membership.settings.filter(setting => setting.name === 'LeaderTrainingLink')
                    if (trainingLink.length === 1 && trainingLink[0].value !== '') {
                        leaderTrainingLinks.set(membership.membershipId, trainingLink[0].value);
                    }
                } else {
                    let participantOnboarding = membership.settings.filter(setting => setting.name === 'ParticipantOnboardingLink')
                    if (participantOnboarding.length === 1 && participantOnboarding[0].value !== '') {
                        onboardingLinks.set(membership.membershipId, participantOnboarding[0].value);
                    }
                }
            }
            setLeaderTrainingLinks(leaderTrainingLinks);
            setOnboardingLinks(onboardingLinks);
            setLoaded(true);
        })();
    }, [setSelectedMembershipId]);

    async function onAddMembership() {
        navigate('/memberships/add');
    }

    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>Memberships</div>
                </div>
            </div>
            {isAdminOfAny && <div className={`row`}>
                <div className={`columns small-12 medium-5 medium-4 small-left`}>
                    <ButtonPrimary className={`${styles.AddMembershipWrapper}`} text="Add Membership" type="button" onClick={onAddMembership}/>
                </div>
            </div>}
            {memberships && <div className={`row`}>
                {memberships.map((membership: MyMembership, index) => {
                    let showIfNotOwnerOrAdminUnlessRoleAlsoIsLeaderOrMember = !(membership.admin || membership.owner) || membership.leader || membership.member;
                    return <div key={index} className={`columns large-4 end`}>
                        <div className={`${styles.MembershipCardWrapper}`}>
                            {(membership.admin || membership.owner) && <Link className={`${styles.MembershipCardLink}`} to={`/membership/settings`} state={membership}>
                                <Settings className={`${styles.MembershipSettings}`}/>
                            </Link>}
                            <div className={`${styles.MembershipCardTitle}`}>{membership.name}</div>
                            {(membership.admin || membership.owner) && <>
                                <div className={`${styles.MembershipCardItem}`}>
                                    <Link className={`${styles.MembershipCardLink}`} to={`/leader-pods`} state={membership}>
                                        Manage leader meetings <Calendar className={`${styles.Icon}`}/>
                                    </Link>
                                </div>
                                {defaults.AdminOnboardingLink && defaults.AdminOnboardingLink !== '' && <div className={`${styles.MembershipCardItem}`}>
                                    <a className={`${styles.MembershipCardLink}`} href={defaults.AdminOnboardingLink} target="_blank" rel="noreferrer">
                                        {showIfNotOwnerOrAdminUnlessRoleAlsoIsLeaderOrMember ? 'Admin Onboarding' : 'Onboarding'}
                                        <Anchor className={`${styles.IconMiddle}`}/>
                                    </a>
                                </div>}
                                <div className={`${styles.MembershipCardItem}`}>
                                    <Link className={`${styles.MembershipCardLink}`} to={`/membership/invites`} state={membership}>
                                        Invite links <Anchor className={`${styles.Icon}`}/>
                                    </Link>
                                </div>
                                <div className={`${styles.MembershipCardItem}`}>
                                    <Link className={`${styles.MembershipCardLink}`} to={`/members`} state={membership}>
                                        Manage participants and pods <People className={`${styles.Icon}`}/>
                                    </Link>
                                </div>
                            </>}

                            {showIfNotOwnerOrAdminUnlessRoleAlsoIsLeaderOrMember && <>
                                {membership.url && <div className={`${styles.MembershipCardItem}`}>
                                    <a className={`${styles.MembershipCardLink}`} href={membership.url} target="_blank" rel="noreferrer">
                                        Membership website <Anchor className={`${styles.IconMiddle}`}/>
                                    </a>
                                </div>}
                                {membership.communityUrl && <div className={`${styles.MembershipCardItem}`}>
                                    <a className={`${styles.MembershipCardLink}`} href={membership.communityUrl} target="_blank" rel="noreferrer">
                                        Membership community website <Anchor className={`${styles.IconMiddle}`}/>
                                    </a>
                                </div>}
                                {leaderTrainingLinks.has(membership.membershipId) && <div className={`${styles.MembershipCardItem}`}>
                                    <a className={`${styles.MembershipCardLink}`} href={leaderTrainingLinks.get(membership.membershipId)} target="_blank" rel="noreferrer">
                                        Leader training <Anchor className={`${styles.IconMiddle}`}/>
                                    </a>
                                </div>}
                                {onboardingLinks.has(membership.membershipId) && <div className={`${styles.MembershipCardItem}`}>
                                    <a className={`${styles.MembershipCardLink}`} href={onboardingLinks.get(membership.membershipId)} target="_blank" rel="noreferrer">
                                        {showIfNotOwnerOrAdminUnlessRoleAlsoIsLeaderOrMember ? 'Participant Onboarding' : 'Onboarding'}
                                        <Anchor className={`${styles.IconMiddle}`}/>
                                    </a>
                                </div>}
                                <div className={`${styles.MembershipCardItem}`}>
                                    <Link className={`${styles.MembershipCardLink}`} to={`/pods`} state={membership}>
                                        Progress Pods <People className={`${styles.Icon}`}/>
                                    </Link>
                                </div>
                            </>}
                        </div>
                    </div>
                })}
            </div>}
            {!isAdminOfAny && memberships.length === 0 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={styles.SubTitle}>You are not a member of any memberships</div>
                    <div className={styles.SubTitle}>
                        If you believe that you have removed from this Pod membership accidentally,
                        please contact the support person for the membership you are part of.
                    </div>
                    <div className={styles.SubTitle}>
                        Alternatively you can delete your account in this software by clicking
                        <Link to={`/my-settings`} state={{panel:ACCOUNT}}>
                            &nbsp;here
                        </Link>
                        .
                    </div>
                </div>
            </div>}

            {Sdk.context.businesses?.length > 1 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <br/>
                    <div className={styles.SubTitle}>
                        Looking for another business.&nbsp;
                        <Link to={`/my-settings`} state={{panel:MEMBERSHIPS}}>
                           Switch here
                        </Link>
                    </div>
                </div>
            </div>}
        </div>}
    </>;
}
