import React, {useEffect} from 'react';
import styles from './ZoomPrompt.module.css';
import ButtonPrimary from '../components/ButtonPrimary';
import {useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import formUtils from "../../domain/utils/UseForm";
import validate from "./ZoomPrompt.validation";
import {Sdk} from "../../domain/sdk/Sdk";
import {UserActionRequired} from "../../domain/sdk/domain/UserActionRequired";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import {rootNavigation} from "../../domain/utils/NavigationUtils";

export default function ZoomPrompt() {
    let navigate = useNavigate();
    const {values, setValues, errors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    useEffect(() => {
        setValues({});
    }, [setValues]);

    async function onFormSubmit() {
        let businessId = Sdk.context.selectedBusiness.id;
        await Sdk.zoomClient.setup(businessId, values.zoomApiKey, values.zoomApiSecret, values.zoomUserId);
        await Sdk.onboardingActionsClient.markComplete(businessId, UserActionRequired.ZOOM_PROMPT);
        navigate(rootNavigation());
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Setup Zoom
                    </div>
                </div>
                <div className={`columns small-12 medium-8 small-centered`}>
                    <div className={`${styles.InviteAdminText}`}>
                        Enter your Zoom credentials to automatically schedule meetings. You can also do this later by going to 'Third party settings' in the menu.
                    </div>
                </div>

                <form className={`${styles.InviteForm}`} onSubmit={handleSubmit}>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputTextWithLabel label="API Key" name="zoomApiKey" placeholder={"API key"} value={values.zoomApiKey ?? ''} onChange={handleChange}/>
                            {errors.zoomApiKey && (<p className={`${styles.ErrorMessage}`}>{errors.zoomApiKey}</p>)}
                        </div>
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputTextWithLabel label="API Secret" name="zoomApiSecret" placeholder={"Secret"} value={values.zoomApiSecret ?? ''} onChange={handleChange}/>
                            {errors.zoomApiSecret && (<p className={`${styles.ErrorMessage}`}>{errors.zoomApiSecret}</p>)}
                        </div>
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputTextWithLabel label="Zoom User Id" name="zoomUserId" placeholder={"Email"} value={values.zoomUserId ?? ''} onChange={handleChange}/>
                            {errors.zoomUserId && (<p className={`${styles.ErrorMessage}`}>{errors.zoomUserId}</p>)}
                        </div>
                    </div>
                    <div className={`columns small-12 small-centered show-for-small-only`}>
                        <ButtonPrimary className={`${styles.FullWidth}`} text="Start using Zoom" type="submit"/>
                        <ButtonCancel className={`${styles.FullWidth}`} text="Skip" type="button" onClick={async () => {
                            await Sdk.onboardingActionsClient.markComplete(Sdk.context.selectedBusiness.id, UserActionRequired.ZOOM_PROMPT)
                            navigate(rootNavigation());
                        }}/>
                    </div>
                    <div className={`columns medium-9 large-6 medium-centered hide-for-small-only`}>
                        <ButtonCancel className={`${styles.CancelButton}`} text="Skip" type="button" onClick={async () => {
                            await Sdk.onboardingActionsClient.markComplete(Sdk.context.selectedBusiness.id, UserActionRequired.ZOOM_PROMPT)
                            navigate(rootNavigation());
                        }}/>
                        <ButtonPrimary className={`${styles.AssignButton}`} text="Start using Zoom" type="submit"/>
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}
                    </div>
                </form>
            </div>
        </div>
    </>;
}
