import React from 'react';
import styles from './DemoteLeader.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonDanger from "../components/ButtonDanger";

export default function DemoteLeader() {
    const navigate = useNavigate();
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Unassign Leader
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Are you sure you want to unassign {state.member.firstName} {state.member.lastName}?
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        They will still have access to Progress Pods, but will no longer be able to lead a Pod
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/member', {state});
                    }}/>
                    <ButtonDanger style={styles.RemoveButton} text="Unassign" type="button" onClick={async () => {
                        await Sdk.leadersClient.demoteLeader(state.membershipId, state.member.id);
                        state.member.leader = false;
                        navigate('/member', {state});
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonDanger style={styles.FullWidthDanger} text="Unassign" type="button" onClick={async () => {
                        await Sdk.leadersClient.demoteLeader(state.membershipId, state.member.id);
                        state.member.leader = false;
                        navigate('/member', {state});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/member', {state});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
