import React from 'react';
import styles from './Message.module.css';
import ButtonPrimary from "../components/ButtonPrimary";
import {useNavigate} from "react-router-dom";


export default function PasswordChanged() {
    let navigate = useNavigate();

    return <>
        <div className={`row`}>
            <div className={`columns small-12 small-centered`}>
                <div className={`${styles.TitleWrapper}`}>
                    Password updated
                </div>
                <div className={`${styles.MessageText}`}>
                    Password successfully changed
                </div>
            </div>
        </div>
        <div className={`row`}>
            <div className={`columns small-12 small-centered show-for-small-only`}>
                <ButtonPrimary className={`${styles.FullWidthButton}`} text="Back to login" type="button" onClick={() => navigate('/login')}/>
            </div>
            <div className={`columns small-12 small-centered hide-for-small-only`} style={{textAlign: "center"}}>
                <ButtonPrimary className={`${styles.PasswordChangedWrapper}`} text="Back to login" type="button" onClick={() => navigate('/login')}/>
            </div>
        </div>
    </>;
}
