import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styles from './ViewPod.module.css';
import ButtonPrimary from "../components/ButtonPrimary";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonSecondary from "../components/ButtonSecondary";
import Breadcrumb from "../components/Breadcrumb";
import {extract, useQuery} from "../../domain/utils/QueryUtils";
import {ScheduledMeeting} from "../../domain/sdk/domain/ScheduledMeeting";

export default function ViewLeaderPod() {
    let queryParams = useQuery();
    const {state} = useLocation();
    const {model} = (() => {
        const membershipIdParam = queryParams.get("m");
        const membershipNameParam = queryParams.get("mn");
        const podIdParam = queryParams.get("p");
        const podNameParam = queryParams.get("n");
        const meetingScheduleIdParam = queryParams.get("s");
        return {
            model: {
                params: podIdParam ?? false,
                membershipId: membershipIdParam ? extract(membershipIdParam) : state.membershipId,
                membershipName: membershipNameParam ? extract(membershipNameParam) : state.membershipName,
                id: podIdParam ? extract(podIdParam) : state.id,
                name: podNameParam ? extract(podNameParam) : state.name,
                meetingScheduleIdParam,
                scheduledMeeting: state?.scheduledMeeting,
                ...state
            }
        }
    })();
    const navigate = useNavigate();
    const [scheduledMeeting, setScheduledMeeting]: any = useState(state?.scheduledMeeting as undefined | ScheduledMeeting);
    const [loaded, setLoaded] = useState(false);
    const isAdmin = Sdk.context.selectedBusiness?.owner || Sdk.context.selectedBusiness?.admin;

    useEffect(() => {
        (async () => {
            if (model.meetingScheduleIdParam) {
                setScheduledMeeting(await Sdk.scheduledMeetingsClient.scheduledMeeting(extract(model.meetingScheduleIdParam)));
            }
            setLoaded(true);
        })();
    }, [model.meetingScheduleIdParam]);


    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: isAdmin ? "Leader meetings" : "Progress Pods", link: isAdmin ? "/leader-pods" : "/pods", state: model},
                        {text: "View details"},
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitleText}`}>
                        {model.membershipName} leader meeting
                    </div>
                    <div className={`${styles.BodyText}`}>
                        {model.name}
                    </div>
                    <div className={`${styles.DateText}`}>
                        {scheduledMeeting?.nextMeeting}
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-5 small-left`}>
                    <ButtonSecondary className={`${styles.ButtonWrapper}`} text="View participants" type="button" onClick={async () => {
                        navigate('/leader-pod/participants', {state: model});
                    }}/>
                    {scheduledMeeting?.thirdPartyMeetingLink && <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Join online meeting" type="button" onClick={() => {
                        window.open(scheduledMeeting.thirdPartyMeetingLink, '_blank');
                    }}/>}
                </div>
            </div>
        </div>}
    </>;
}
