export interface NextTinyStep {
    id: string;
    podId: string;
    userId: string;
    goal: string;
    description: string;
    status: NextTinyStepStatus;
    created: number;
    commitmentDate: number | null;
}

export enum NextTinyStepStatus {
    NotSet = 'NotSet',
    InProgress = 'InProgress',
    NotDone = 'NotDone',
    Done = 'Done'
}