import React, {useCallback, useEffect, useState} from "react";
import {Sdk} from "../../domain/sdk/Sdk";
import {MessageChannel} from "../../domain/sdk/domain/ChannelMessages";
import {Link} from "react-router-dom";
import styles from "./Channel.module.css";
import {MessageListener} from "../../domain/sdk/MessageClient";
import {Message} from "../../domain/sdk/domain/Message";

class BusinessChannelSet {
    name: string;
    memberships: MembershipChannels[] = [];

    constructor(name: string) {
        this.name = name;
    }
}

class MembershipChannels {
    name: string;
    leaderChannels: MessageChannel[] = [];
    channels: MessageChannel[] = [];

    constructor(name: string) {
        this.name = name;
    }
}

export default function Channels() {
    const [businesses, setBusinesses] = useState([] as BusinessChannelSet[]);

    const organiseChats = useCallback((allChannels: MessageChannel[]) => {
        allChannels = allChannels.sort((a, b) => a.name.localeCompare(b.name));
        const businessChannelSets = [] as BusinessChannelSet[];
        for (const business of Sdk.context.businesses) {
            let businessChannelSet = new BusinessChannelSet(business.name);
            const channelsForBusiness = allChannels.filter(channel => channel.businessId === business.id);
            const membershipChannelMap = new Map<string, MembershipChannels>();
            for (const channel of channelsForBusiness) {
                if (!membershipChannelMap.has(channel.membershipId)) {
                    membershipChannelMap.set(channel.membershipId, new MembershipChannels(channel.membershipName));
                }
                const channelMap = membershipChannelMap.get(channel.membershipId);
                if (channel.leaderChannel) {
                    channelMap?.leaderChannels.push(channel);
                } else {
                    channelMap?.channels.push(channel);
                }
            }
            businessChannelSet.memberships = Array.from(membershipChannelMap.values()).sort((a, b) => a.name.localeCompare(b.name));
            businessChannelSets.push(businessChannelSet);
        }
        return businessChannelSets.sort((a, b) => a.name.localeCompare(b.name));
    }, []);

    useEffect(() => {
        (async () => {
            let allChannels = await Sdk.messageClient.reloadChannels();
            setBusinesses(organiseChats(allChannels));

            Sdk.messageClient.addListener(new class implements MessageListener {
                received(received: Message): void {
                    if (received.senderId === Sdk.context.user.id) {
                        return;
                    }
                    setBusinesses(organiseChats(Sdk.messageClient.channels()));
                }

                read(message: Message): void {

                }
            }());
        })();
    }, [organiseChats]);

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 ${styles.Title}`}>
                    <div className={`${styles.TitleWrapper}`}>Chats</div>
                </div>
            </div>

            {businesses.map((business: BusinessChannelSet) => {
                return <div key={business.name} className={`row`} style={{paddingBottom: 10}}>
                    <div className={`columns small-12`}>
                        <div className={`${styles.SubTitleWrapper}`}>{business.name}</div>
                    </div>
                    {business.memberships.map(membership => {
                        let leaderChannels = membership.leaderChannels.sort((a, b) => a.name.localeCompare(b.name));
                        let podChannels = membership.channels.sort((a, b) => a.name.localeCompare(b.name));
                        return <div key={membership.name}>
                            <div className={`columns small-12 ${styles.HeadingWrapper}`}>
                                {membership.name}
                            </div>
                            {(leaderChannels.length === 0 && podChannels.length === 0) &&
                                <div className={`columns small-12`}>
                                    <div className={`${styles.NoNotifications}`}>
                                        <div className={`row`}>
                                            <div className={`columns small-12`}>
                                                <div>{membership.name} has no messages</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {leaderChannels.length > 0 && <div className={`columns small-12`}>
                                <div className={`${styles.SubHeadingWrapper}`}>Leader Chats</div>
                            </div>}

                            {leaderChannels && leaderChannels.map((channel) => {
                                return <div key={channel.id} className={`columns small-12`}>
                                    <Link className={`${styles.ChatTitle}`} to={'/chat'} state={channel}>
                                        <div className={`${channel.lastMessageReadId !== channel.lastMessageId ? styles.UnreadChatWrapper : styles.ChatWrapper}`}>
                                            {channel.name}
                                        </div>
                                    </Link>
                                </div>
                            })}

                            {podChannels.length > 0 && <div className={`columns small-12`}>
                                <div className={`${styles.SubHeadingWrapper}`}>Pod Chats</div>
                            </div>}

                            {podChannels && podChannels.map((channel) => {
                                return <div key={channel.id} className={`columns small-12`}>
                                    <Link className={`${styles.ChatTitle}`} to={'/chat'} state={channel}>
                                        <div className={`${channel.lastMessageReadId !== channel.lastMessageId ? styles.UnreadChatWrapper : styles.ChatWrapper}`}>
                                            {channel.name} - {channel.leaderName ?? ""}
                                        </div>
                                    </Link>
                                </div>
                            })}
                        </div>
                    })}
                </div>
            })};
        </div>
    </>
}