import styles from './DateTimeInputWithLabel.module.css'
import React, {InputHTMLAttributes} from 'react';
import ReactDatePicker from "react-datepicker";

export interface FormInputTextWithLabelProps extends InputHTMLAttributes<any> {
	className?: string;
	label?: string;
	locale?: string;
	date?: Date;
	update: (date: Date|null) => void;
}
export default function DateTimeInputWithLabel({className, label, locale, date, update}:FormInputTextWithLabelProps) {
	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.InputText_Label}`}>
			<div>{label}</div>
			<div className={`${styles.InputText_Wrapper}`}>
				<ReactDatePicker
					className={`${styles.InputText_Input}`}
					popperClassName={`${styles.DatePopper}`}
					showTimeSelect
					dateFormat={locale === 'en-US' ? 'MM/dd/yyyy h:mm a' : 'dd/MM/yyyy h:mm a'}
					selected={date}
					onChange={(change) => {
						change?.setMilliseconds(0)
						update(change);
					}}
					popperPlacement="bottom-start"
				/>
			</div>
		</label>
	</div>
}
