import {SdkConfig} from "./SdkConfig";
import {httpPost} from "../utils/HttpClient";

const publicVapId = "BB2n84dHxE8_CKhRgRLSI2ibJHf8becrQH75uU86Jd9F9WCsbdEC-yg-RaXMQH07Dh4if2lwBmbjm4d0UD9Pruc";

export class PushNotificationClient {
    private readonly config: SdkConfig;

    constructor(config: SdkConfig) {
        this.config = config;
    }

    async subscribeToPushNotifications() {
        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            console.warn('Notifications aren\'t supported.');
            return;
        }

        if (Notification.permission === 'denied') {
            console.warn('The user has blocked notifications.');
            return;
        }

        if (!('PushManager' in window)) {
            console.warn("Push messaging isn't supported.");
            return;
        }

        try {
            let serviceWorkerRegistration = await navigator.serviceWorker.ready;
            // Get the push notification subscription object
            let subscription = await serviceWorkerRegistration.pushManager.getSubscription();
            // If this is the user's first visit we need to set up
            // a subscription to push notifications
            if (!subscription) {
                subscribeToBrowser(serviceWorkerRegistration, this.config);
                return;
            }

            // Update the server state with the new subscription
            await sendSubscriptionToServer(subscription, this.config);
        } catch (e) {
            console.error('Failed to subscribe to push notifications', e);
        }
        let nav = navigator as any;
        if (nav?.clearAppBadge) {
            nav.clearAppBadge();
        }
    }
}

/**
 * If permitted subscribe to the browsers native push service and send resulting user subscription to Progress Pod API
 * {
 * 	  "endpoint": "https://push.server.mozilla.org/unique-endpoint",
 * 	  "key": "AnExampleKey==",
 * 	  "auth": "AuthCode1234"
 * 	}
 */
function subscribeToBrowser(serviceWorkerRegistration: ServiceWorkerRegistration, config:SdkConfig) {
    serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(publicVapId)
    }).then(function (subscription) {
        return sendSubscriptionToServer(subscription, config);
    }).catch(function (e) {
        if (Notification.permission === 'denied') {
            console.warn('Permission for Notifications was denied');
        } else {
            console.error('Unable to subscribe to push.', e);
        }
    });
}

async function sendSubscriptionToServer(subscription: PushSubscription, config:SdkConfig) {
    let key = subscription.getKey ? subscription.getKey('p256dh') : null;
    let auth = subscription.getKey ? subscription.getKey('auth') : null;
    if (!key || !auth) {
        return;
    }
    let keyArray: any = new Uint8Array(key);
    let authArray: any = new Uint8Array(auth);
    await httpPost(`${config.apiUrl}/v1/notifications/subscribe`, {
        endpoint: subscription.endpoint,
        key: key ? btoa(String.fromCharCode.apply(null, keyArray)) : '',
        auth: auth ? btoa(String.fromCharCode.apply(null, authArray)) : ''
    });
}


function urlB64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const rawData = window.atob((base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/"));
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
