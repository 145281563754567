import * as React from "react"
import { SVGProps } from "react"

export interface NotificationProps extends SVGProps<SVGSVGElement> {
    quantity:number;
}

const Notifications = (props: NotificationProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36.574}
        height={41.306}
        {...props}
    >
        <g data-name="Layer 15">
            <path
                data-name="Path 141"
                d="M23.293 36.694c0 2.547-2.684 4.613-6 4.613s-5.985-2.066-5.985-4.613"
                fill="#606060"
            />
            <path
                data-name="Path 142"
                d="M14.947 7.009a2.5 2.5 0 0 1 .732-3.025 2.628 2.628 0 0 1 3.175-.047 2.5 2.5 0 0 1 .825 3"
                fill="none"
                stroke="#606060"
                strokeMiterlimit={10}
                strokeWidth={2}
            />
            <path
                data-name="Path 143"
                d="M17.3 8.636a8.031 8.031 0 0 1 5.932 2.671c1.928 2.085 3 5.158 3.087 8.889.007 4.878 1.688 8.367 5.288 10.978a.58.58 0 0 1 .254.461.562.562 0 0 1-.568.556H14.582v.014H3.275a.562.562 0 0 1-.565-.552.574.574 0 0 1 .254-.461c3.6-2.612 5.28-6.1 5.288-10.978.092-3.728 1.163-6.8 3.1-8.892A8.071 8.071 0 0 1 17.3 8.639m0-2.652c-5.772 0-11.543 4.738-11.76 14.193 0 4.7-1.8 7.15-4.191 8.879A3.208 3.208 0 0 0 0 31.652a3.243 3.243 0 0 0 3.277 3.207h14.017v-.014h14a3.271 3.271 0 0 0 3.117-2.217 3.171 3.171 0 0 0-1.191-3.587c-2.39-1.73-4.191-4.179-4.191-8.879-.218-9.455-5.97-14.183-11.729-14.183Z"
                fill="#606060"
            />
        </g>
        {props.quantity > 0 && <g data-name="quantity">
            <g data-name="Group 136" transform="translate(20.282)">
                <circle
                    data-name="Ellipse 1"
                    cx={8.146}
                    cy={8.146}
                    r={8.146}
                    fill="#ea7e3d"
                />
            </g>
            <text
                data-name={1}
                transform="translate(28.1 11.327)"
                fill="#fff"
                fontSize={9}
                fontFamily="SFProText-Regular, SF Pro Text"
            >
                <tspan x={-2.087} y={0}>
                    {props.quantity < 10 ? props.quantity : ".."}
                </tspan>
            </text>
        </g>}
    </svg>
)

export default Notifications

