import {FrequencyPeriod} from "./FrequencyPeriod";
import {DateTime} from "luxon";
import {dateSuffix, dateTimeDescription} from "../../utils/DateTimeUtils";
import {WeeklyOptions} from "./WeeklyOptions";
import {MonthlyOptions} from "./MonthlyOptions";

const n = "numeric", l = "long";

export class ScheduledMeeting {
    id: string;
    thirdPartyMeetingId: String;
    thirdPartyMeetingLink: String;
    unixTime: number;
    recurring: boolean;
    frequency: number;
    frequencyPeriod: FrequencyPeriod;
    duration: number;
    weeklyOptions?:WeeklyOptions;
    monthlyOptions?:MonthlyOptions;
    localDateTime: DateTime;
    frequencyDescription:string;
    nextMeeting:string;
    pausedFromEpoch?:number;
    pausedToEpoch?:number;

    constructor(data:any) {
        this.id = data.id;
        this.thirdPartyMeetingId = data.thirdPartyMeetingId;
        this.thirdPartyMeetingLink = data.thirdPartyMeetingLink;
        this.unixTime = data.unixTime;
        this.recurring = data.recurring;
        this.frequency = data.frequency;
        this.frequencyPeriod = data.frequencyPeriod;
        this.duration = data.duration;
        this.weeklyOptions = data.weeklyOptions;
        this.monthlyOptions = data.monthlyOptions;
        this.pausedFromEpoch = data.pausedFromEpoch;
        this.pausedToEpoch = data.pausedToEpoch;
        this.localDateTime = DateTime.fromSeconds(data.unixTime, { zone: 'local' });
        this.frequencyDescription = dateTimeDescription(this);
        const dateTime = this.localDateTime.toLocaleString({
            year: n,
            month: l,
            day: n,
            weekday: l,
            hour: n,
            minute: n
        });
        const timezone = this.localDateTime.toFormat('ZZZZ');
        this.nextMeeting = `Next Meeting: ${dateTime} (${timezone})`;
    }

    weekday(): string {
        return this.localDateTime.weekdayLong;
    }

    weekdayNumber(): number {
        return this.localDateTime.weekday;
    }

    dayOfMonth(): string {
        return this.localDateTime.toFormat("d") + dateSuffix(this.localDateTime.day);
    }

    time(): string {
        return this.localDateTime.toLocaleString(DateTime.TIME_24_SIMPLE)
    }
}

export function calculateNextMeeting(meeting: ScheduledMeeting) {
    let firstMeeting = DateTime.fromSeconds(meeting.unixTime, {zone: 'local'});
    while (firstMeeting.diffNow("seconds").seconds < 0) {
        if (meeting.frequencyPeriod === FrequencyPeriod.Week) {
            firstMeeting = firstMeeting.plus({ days: 7 * meeting.frequency });
        } else {
            firstMeeting = firstMeeting.plus({month: meeting.frequency});
        }
    }
    return firstMeeting;
}
