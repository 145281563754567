import React, {CSSProperties, InputHTMLAttributes} from 'react';
import styles from './Checkbox.module.css'

export interface CheckboxProps extends InputHTMLAttributes<any> {
    text?: string;
    label?: string;
    labelTextStyle?: CSSProperties;
}

export default function Checkbox({className, label, labelTextStyle, name, children, ...other}: CheckboxProps) {
    return <div className={`${className ? className : ''}`}>
        <label className={`${styles.Checkbox_Label}`}>
            <input id={name} type="checkbox" className={`${styles.Checkbox_Standard}`} {...other}/>
            <span className={`${styles.Checkbox_Custom}`}/>
            {label && <span className={`${styles.Checkbox_Label_Text}`} style={labelTextStyle}>
				{label}
			</span>}
        </label>
        {children}
    </div>
}
