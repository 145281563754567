import axios from "axios";
import Auth from "../Auth";
import {Sdk} from "../sdk/Sdk";

export async function httpGet(url) {
    let config = defaultConfig();
    config.headers['Content-Type'] = 'application/json';
    return await axios.get(url, config);
}

export async function httpPut(url, values) {
    return await axios.put(url, values, defaultConfig());
}

export async function httpPost(url, values) {
    return await axios.post(url, values, defaultConfig());
}

export async function httpDelete(url, values) {
    let config = defaultConfig(values);
    config.headers['Content-Type'] = 'application/json';
    return await axios.delete(url, config);
}

function defaultConfig(data) {
    let config = {
        headers: {Pragma: 'no-cache'},
        data : data
    };
    let accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}

axios.interceptors.response.use((response) => {
    return response;
}, async function (error) {
    if ([401, 403].indexOf(error.response.status) >= 0) {
        Auth.logout();
        Sdk.messageClient.close();
        if (!error.response.config.url.includes("/login")) {
            window.location = "/login";
        }
    }
    return Promise.resolve(error.response);
});
