import React from 'react';
import styles from './ConfirmDeleteAccount.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonDanger from "../components/ButtonDanger";
import {MY_PODS} from "./MySettings";

export default function ConfirmSwitchPod() {
    const {state} = useLocation();
    const navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Confirm Switch Pod
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        You are moving from '{state.fromPod.name}' to '{state.toPod.name}'
                    </div>
                </div>

                <div className={`columns medium-9 large-12 medium-centered show-for-small-only`}>
                    <ButtonDanger style={styles.FullWidthDelete} text="Yes. Switch Pod" type="button" onClick={async () => {
                        await Sdk.podsClient.switchPod(state.fromPod.id, state.toPod.id);
                        navigate('/pods', {state});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state : {...state, ...{panel:MY_PODS}}});
                    }}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state : {...state, ...{panel:MY_PODS}}});
                    }}/>
                    <ButtonDanger style={styles.DeleteButton} text="Yes. Switch Pod" type="button" onClick={async () => {
                        await Sdk.podsClient.switchPod(state.fromPod.id, state.toPod.id);
                        navigate('/pods', {state});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
