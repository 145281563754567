import React, {useEffect, useState} from "react";
import {Sdk} from "../domain/sdk/Sdk";
import {Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import HeaderLoggedIn from "./components/HeaderLoggedIn";
import {rootNavigation} from "../domain/utils/NavigationUtils";
import SecureProvider from "./state/SecureProvider";

export default function Secure() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams({});
    const [context, setContext] = useState(Sdk.context);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            if (Sdk.config.prod && location.pathname === '/') {
                window.location.href = 'https://richardralston.com';
            } else {
                if (searchParams.has("ac")) {
                    localStorage.setItem('access_token', searchParams.get("ac"));
                }
                let authed = await Sdk.authenticateAndInitialise();
                if (!authed || Sdk.context.status !== 200) {
                    Sdk.context = undefined;
                    navigate("/login");
                } else if (location.pathname === '/') {
                    navigate(rootNavigation());
                }
                setContext(Sdk.context);
            }
            setLoaded(Sdk.isAuthenticated());
        })();
    }, [location.pathname, navigate, searchParams]);

    return <>
        {loaded && location.pathname !== '/' && context && <>
            <SecureProvider>
                <HeaderLoggedIn/>
                <Outlet/>
            </SecureProvider>
        </>}
    </>
}