import React, {useEffect, useState} from 'react';
import styles from './Member.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonSecondary from "../components/ButtonSecondary";
import ButtonPrimary from "../components/ButtonPrimary";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonDanger from "../components/ButtonDanger";
import Breadcrumb from "../components/Breadcrumb";
import {Pod} from "../../domain/sdk/domain/Pod";
import Edit from "../svg/Edit";
import FormInputText from "../components/FormInputText";
import ButtonCancel from "../components/ButtonCancel";
import {validateEmail} from "../../domain/utils/Validate";

export default function Member() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [accountDisabled, setAccountDisabled] = useState(state.member.disabled);
    const [isMemberWithNoPods, setIsMemberWithNoPods] = useState(false);
    const [isLeaderWithNoPods, setIsLeaderWithNoPods] = useState(false);
    const [showAdminPanel, setShowAdminPanel] = useState(false);
    const [myPods, setMyPods] = useState<Pod[]>([])
    const [isOwner, setIsOwner] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [updatedEmailText, setUpdatedEmailText] = useState<string>();
    const [updateEmailError, setUpdateEmailError] = useState<string>();

    const isAdmin = Sdk.context.selectedBusiness?.owner || Sdk.context.selectedBusiness?.admin;

    useEffect(() => {
        let business = Sdk.context.selectedBusiness;
        let owner = business?.owner && Sdk.context.user.id === state.member.id;
        setIsOwner(owner);
        (async () => {
            let pods = await Sdk.podsClient.pods(state.membershipId);
            let memberOfPods = pods.filter(pod => pod.members.includes(state.member.id));
            let leaderOfPods = pods.filter(pod => pod.leaderId === state.member.id);
            let isMemberOfAnyPod = !state.member.leader && memberOfPods.length === 0;
            let isLeaderOfAnyPod = state.member.leader && leaderOfPods.length === 0;
            setMyPods(memberOfPods);
            setIsMemberWithNoPods(isMemberOfAnyPod);
            setIsLeaderWithNoPods(isLeaderOfAnyPod);
            setShowAdminPanel(state.member.disabled || (!owner && isMemberOfAnyPod) || isLeaderOfAnyPod);
            setLoaded(true);
        })();
    }, [state.membershipId, state.member.id, state.member.disabled, state.member.leader]);

    function updateMembersEmail() {
        return async () => {
            const error = validateEmail(updatedEmailText);
            if (error) {
                setUpdateEmailError(error);
                return;
            }
            if (!updatedEmailText) {
                setUpdateEmailError('Email is required');
                return;
            }
            const response = await Sdk.membersClient.changeEmail(state.membershipId, state.member.id, updatedEmailText);
            if (response.status !== 200) {
                if (response.data) {
                    setUpdateEmailError(response.data);
                } else {
                    setUpdateEmailError('Something went wrong. Please try again later');
                }
                return;
            }
            state.member.email = updatedEmailText;
            setEditEmail(false);
            setUpdatedEmailText(undefined);
            setUpdateEmailError(undefined);
        };
    }

    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Memberships", link: "/memberships"},
                        {text: "Participants & Pods", link: "/members", state: state},
                        {text: `${state.member.firstName} ${state.member.lastName}`}
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.BodyText}`}>
                        Member bio
                    </div>
                </div>
                <div className={`columns small-12`}>
                    <div className={`${styles.InputWrapper}`}>
                        {state.member.bio ?? "No bio"}
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.BodyText}`}>
                        Member email address
                    </div>
                </div>
                <div className={`columns small-12`}>
                    {!editEmail && <div className={`${styles.InputWrapper}`} style={{display: "flex"}}>
                        {state.member.email}
                        {isAdmin && <div style={{cursor: "pointer", marginLeft: 5}} onClick={() => {
                            setUpdatedEmailText(state.member.email);
                            setEditEmail(true)
                        }}><Edit/></div>}
                    </div>}
                    {editEmail && <div className={`${styles.InputWrapper}`}>
                        <FormInputText style={{flexGrow: 1}} value={updatedEmailText} onChange={(e) => {
                            setUpdatedEmailText(e.target.value.trim());
                            setUpdateEmailError(undefined);
                        }}/>
                        <div className={`row`} style={{paddingTop: 10}}>
                            {/* mobile */}
                            <div className={`columns small-12 show-for-small-only`} >
                                <ButtonPrimary className={styles.FullWidth} text="Update" type="button" onClick={updateMembersEmail()}/>
                                <ButtonCancel className={styles.FullWidth} text="Cancel" type="button" onClick={() => {
                                    setEditEmail(false);
                                    setUpdatedEmailText(undefined);
                                    setUpdateEmailError(undefined);
                                }}/>
                            </div>
                            {/* desktop */}
                            <div className={`columns medium-9 large-8 float-right hide-for-small-only text-right`}>
                                <ButtonCancel className={styles.CancelButton} text="Cancel" type="button" onClick={() => {
                                    setEditEmail(false);
                                    setUpdatedEmailText(undefined);
                                    setUpdateEmailError(undefined);
                                }}/>
                                <ButtonPrimary className={styles.AssignButton} text="Update" type="button" onClick={updateMembersEmail()}/>
                            </div>
                            {updateEmailError && <div className={`columns small-12`}>
                                <p className={styles.ErrorMessage}>{updateEmailError}</p>
                            </div>}
                        </div>
                    </div>}
                </div>
            </div>
            {showAdminPanel && <div className={`row`}>
                <div className={`columns small-12 medium-5 medium-4 small-left`}>
                    <div className={`${styles.BodyText}`}>
                        Manage participant
                    </div>
                    {!isOwner && isMemberWithNoPods && <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Remove from membership" type="button" onClick={async () => {
                        navigate('/member/remove', {state});
                    }}/>}
                    {accountDisabled && <ButtonSecondary className={`${styles.ButtonWrapper}`} text="Enable account" type="button" onClick={async () => {
                        await Sdk.membersClient.enableMember(state.membershipId, state.member.id);
                        setAccountDisabled(false);
                    }}/>}
                    {isLeaderWithNoPods && <ButtonDanger className={`${styles.ButtonWrapper}`} text="Unnassign" type="button" onClick={async () => {
                        navigate('/leader/demote', {state});
                    }}/>}
                </div>
            </div>}
            {myPods.length > 0 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitleWrapper}`}>
                        Member pods
                    </div>
                    {myPods.map(pod => <div>
                        <div className={`${styles.BodyText}`}>
                            {pod.name}
                        </div>
                        <ButtonDanger className={`${styles.ButtonWrapper}`} style={{width: "fit-content"}} text={"Remove from this pod"} type="button" onClick={async () => {
                            navigate('/pod/participant/remove', {
                                state: {
                                    pod,
                                    member: {...state.member, userId: state.member.id},
                                    route: {
                                        path: '/member',
                                        state: state
                                    }
                                }
                            });
                        }}/>
                    </div>)}
                </div>
            </div>}
        </div>}
    </>;
}
