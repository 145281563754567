import * as React from "react"
import {SVGProps} from "react"

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={44} height={35} {...props}>
        <g
            data-name="Menu Icon"
            fill="none"
            stroke="#39684b"
            strokeLinecap="round"
            strokeWidth={5}
        >
            <path d="M41.5 2.5h-39M41.5 17.5h-39M41.5 32.5h-39" />
        </g>
    </svg>
)

export default MenuIcon