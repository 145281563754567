import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost} from "../utils/HttpClient";
import {Member} from "./domain/Member";

export class MembersClient {
    private readonly membersUrl: string;
    private readonly membershipUrl: string;

    constructor(config: SdkConfig) {
        this.membersUrl = `${config.apiUrl}/v1/members`;
        this.membershipUrl = `${config.apiUrl}/v1/memberships`;
    }

    async register(inviteCode:string, email: string, firstName: string, lastName:string, password: string, preferredPodId?:string) {
        return await httpPost(`${this.membersUrl}/register`, {
            inviteCode,
            firstName,
            lastName,
            email,
            password,
            preferredPodId
        });
    }

    async members(membershipId:string):Promise<Member[]> {
        let response = await httpGet(`${this.membershipUrl}/${membershipId}/members`);
        return response.data;
    }

    async leave(membershipId:string) {
        return await httpDelete(`${this.membershipUrl}/${membershipId}/members`);
    }

    async removeMember(membershipId:string, userId:string) {
        return await httpDelete(`${this.membershipUrl}/${membershipId}/members/${userId}`);
    }

    async disableMember(membershipId:string, userId:string) {
        return await httpPost(`${this.membershipUrl}/${membershipId}/members/${userId}/disable`);
    }

    async enableMember(membershipId:string, userId:string) {
        return await httpPost(`${this.membershipUrl}/${membershipId}/members/${userId}/enable`);
    }

    async changeEmail(membershipId:string, userId:string, newEmail:string) {
        return await httpPost(`${this.membershipUrl}/${membershipId}/members/${userId}/change-email`, {newEmail});
    }
}