import React from "react";
import validate from "./AddPod.validation";
import SetupMeeting from "../components/SetupMeeting";

export default function EditPod() {

    return <SetupMeeting
        validator={validate}
        leaderPod={false}
        edit={true}
        pause={true}
    />
}
