import styles from "./Terms.module.css";
import React from "react";

export default function ParticipantTsAndCs() {
    return <>
        <div className={`${styles.ContentWrapper}`}>
            <div className={`row`}>
                <div className={`columns small-12 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>Terms & Conditions</div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.BodyText}`}>These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the “Progress Pods
                        Manager” mobile application (“Mobile Application” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is
                        legally binding between you (“User”, “you” or “your”) and this Mobile Application developer (“Operator”, “we”, “us” or “our”). By accessing and using the
                        Mobile Application and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering
                        into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which
                        case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this
                        Agreement, you must not accept this Agreement and may not access and use the Mobile Application and Services. You acknowledge that this Agreement is a
                        contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Mobile Application
                        and Services.
                    </div>
                    <div className={`${styles.HeaderText}`}>Links to other resources</div>
                    <div className={`${styles.BodyText}`}>Although the Mobile Application and Services may link to other resources (such as websites, mobile applications, etc.), we
                        are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically
                        stated herein. Some of the links in the Mobile Application may be “affiliate links”. This means if you click on the link and purchase an item, the Operator
                        will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or
                        individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any
                        other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link in the
                        Mobile Application and Services. Your linking to any other off-site resources is at your own risk.
                    </div>
                    <div className={`${styles.HeaderText}`}>Prohibited uses</div>
                    <div className={`${styles.BodyText}`}>In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile Application and
                        Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international,
                        federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the
                        intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual
                        orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit
                        viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Mobile Application
                        and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or
                        immoral purpose; or (j) to interfere with or circumvent the security features of the Mobile Application and Services, third party products and services, or
                        the Internet. We reserve the right to terminate your use of the Mobile Application and Services for violating any of the prohibited uses.
                    </div>
                    <div className={`${styles.HeaderText}`}>Intellectual property rights</div>
                    <div className={`${styles.BodyText}`}>
                        “Intellectual Property Rights” means all present and future rights conferred by statute, common law or equity in or in
                        relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions,
                        rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to
                        apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of
                        intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual
                        property owned by the Operator or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely
                        with the Operator. All trademarks, service marks, graphics and logos used in connection with the Mobile Application and Services, are trademarks or
                        registered trademarks of the Operator or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Mobile Application
                        and Services may be the trademarks of other third parties. Your use of the Mobile Application and Services grants you no right or license to reproduce or
                        otherwise use any of the Operator or third party trademarks.
                    </div>
                    <div className={`${styles.HeaderText}`}>GDPR</div>
                    <div className={`${styles.BodyText}`}>
                        As part of our obligations under the General Data Protection Regulation (GDPR), we draw to your attention that you have the following rights:
                        <ul>
                            <li>Right of access to personal information we hold about you</li>
                            <li>Right of erasure of personal information we hold about you</li>
                            <li>Right to restrict the processing of personal information we hold about you</li>
                            <li>Right to portability of the personal information we hold about you</li>
                            <li>Right to object to us processing your personal information on the basis of 'Legitimate Interests'.</li>
                        </ul>
                    </div>
                    <div className={`${styles.HeaderText}`}>Data usage</div>
                    <div className={`${styles.BodyText}`}>
                        By accepting the terms you agree to ProgressPod.io sharing your name and email with other users of the system where we believe it enhances your overall experience.
                    </div>
                    <div className={`${styles.HeaderText}`}>Indemnification</div>
                    <div className={`${styles.BodyText}`}>
                        You agree to indemnify and hold the Operator and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Mobile Application and Services or any willful misconduct on your part.
                    </div>
                    <div className={`${styles.HeaderText}`}>Severability</div>
                    <div className={`${styles.BodyText}`}>
                        All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
                    </div>
                    <div className={`${styles.HeaderText}`}>Dispute resolution</div>
                    <div className={`${styles.BodyText}`}>
                        The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of United Kingdom without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of United Kingdom. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in United Kingdom, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
                    </div>
                    <div className={`${styles.HeaderText}`}>Changes and amendments</div>
                    <div className={`${styles.BodyText}`}>
                        We reserve the right to modify this Agreement or its terms relating to the Mobile Application and Services at any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Mobile Application and Services after any such changes shall constitute your consent to such changes.
                    </div>
                    <div className={`${styles.HeaderText}`}>Acceptance of these terms</div>
                    <div className={`${styles.BodyText}`}>
                        You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Mobile Application and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Mobile Application and Services.
                    </div>
                    <div className={`${styles.HeaderText}`}>Contacting us</div>
                    <div className={`${styles.BodyText}`}>
                        If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to support@progresspod.io
                    </div>
                    <div className={`${styles.BodyText}`}>
                        This document was last updated on March 1, 2022
                    </div>
                </div>
            </div>
        </div>
    </>;
}