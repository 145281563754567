import React from 'react';
import {Route, Routes} from "react-router-dom";
import HeaderLoggedOut from "./components/HeaderLoggedOut";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import CheckInbox from "./screens/CheckInbox";
import SignUpLeader from "./screens/SignUpLeader";
import SignUpParticipant from "./screens/SignUpParticipant";
import ParticipantTsAndCs from "./screens/ParticipantTsAndCs";
import LeaderTsAndCs from "./screens/LeaderTsAndCs";
import LeaderAgreement from "./screens/LeaderAgreement";
import ParticipantAgreement from "./screens/ParticipantAgreement";
import InviteAdminPrompt from "./screens/InviteAdminPrompt";
import LeaderWelcome from "./screens/LeaderWelcome";
import LeaderConfirmTraining from "./screens/LeaderConfirmTraining";
import LeaderTraining from "./screens/LeaderTraining";
import MySettings from "./screens/MySettings";
import Secure from "./Secure";
import Memberships from "./screens/Memberships";
import SignUpAdmin from "./screens/SignUpAdmin";
import AdminTsAndCs from "./screens/AdminTsAndCs";
import Message from "./screens/Message";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import ChangePassword from "./screens/ChangePassword";
import BusinessSettings from "./screens/BusinessSettings";
import ThirdPartySettings from "./screens/ThirdPartySettings";
import AddMembership from "./screens/AddMembership";
import EditMembership from "./screens/EditMembership";
import LeaderPods from "./screens/LeaderPods";
import AddLeaderPod from "./screens/AddLeaderPod";
import Notifications from "./screens/Notifications";
import Members from "./screens/Members";
import Member from "./screens/Member";
import RemoveMember from "./screens/RemoveMember";
import EditLeaderPod from "./screens/EditLeaderPod";
import ResetLinks from "./screens/ResetLinks";
import Pods from "./screens/Pods";
import ViewPod from "./screens/ViewPod";
import AddPod from "./screens/AddPod";
import JoinPod from "./screens/JoinPod";
import ConfirmJoinPod from "./screens/ConfirmJoinPod";
import ZoomPrompt from "./screens/ZoomPrompt";
import EditPod from "./screens/EditPod";
import PodParticipants from "./screens/PodParticipants";
import PodParticipant from "./screens/PodParticipant";
import RemovePodParticipant from "./screens/RemovePodMember";
import ConfirmJoinWaitlist from "./screens/ConfirmJoinWaitlist";
import NextTinySteps from "./screens/NextTinySteps";
import Channels from "./screens/Channels";
import Channel from "./screens/Channel";
import ViewLeaderPod from "./screens/ViewLeaderPod";
import OwnerTsAndCs from "./screens/OwnerTsAndCs";
import Bio from './screens/Bio';
import ConfirmLeavePod from "./screens/ConfirmLeavePod";
import ConfirmLeaveMembership from "./screens/ConfirmLeaveMembership";
import ConfirmLeaveAccount from "./screens/ConfirmLeaveAccount";
import MembershipInvites from './screens/MembershipInvites';
import BioPrompt from "./screens/BioPrompt";
import JoinAnotherPod from "./screens/JoinAnotherPod";
import SwitchPod from "./screens/SwitchPod";
import ConfirmSwitchPod from "./screens/ConfirmSwitchPod";
import ConfirmRequestLeadership from "./screens/ConfirmRequestLeadership";
import RequestLeadershipSent from "./screens/RequestLeadershipSent";
import PodAdministration from "./screens/PodAdministration";
import ConfirmSwitchLeader from "./screens/ConfirmSwitchLeader";
import CompleteSwitchLeader from "./screens/CompleteSwitchLeader";
import RemoveAdmin from "./screens/RemoveAdmin";
import AdminRemovePodMember from "./screens/AdminRemovePodMember";
import AssignAdminPrompt from "./screens/AssignAdminPrompt";
import AdminRemovePodLeader from "./screens/AdminRemovePodLeader";
import DemoteLeader from "./screens/DemoteLeader";
import LeaderPodParticipants from "./screens/LeaderPodParticipants";
import DeleteMembership from "./screens/DeleteMembership";
import PasswordChanged from "./screens/PasswordChanged";
import AssignAssistantLeader from "./screens/AssignAssistantLeader";
import ConfirmAssistantLeader from "./screens/ConfirmAssistantLeader";
import UnassignAssistantLeader from "./screens/UnassignAssistantLeader";
import ConfirmCancelLeaderPod from "./screens/ConfirmCancelLeaderPod";
import AdminEditPod from "./screens/AdminEditPod";

export default function Home() {
    return <Routes>
        <Route path={"/login"} element={<HeaderLoggedOut/>}>
            <Route index element={<SignIn/>}/>
        </Route>
        <Route path={"/invalid"} element={<HeaderLoggedOut/>}>
            <Route index element={<Message title={"Invalid link"} messages={["This link is no longer valid"]}/>}/>
        </Route>
        <Route path={"/approval-pending"} element={<HeaderLoggedOut/>}>
            <Route index element={<Message title={"Welcome to ProgressPod.io"}
                                           messages={["Please keep an eye on your inbox, we'll send you an email as soon as your account is ready"]}/>}/>
        </Route>
        <Route path={"/email-verified"} element={<HeaderLoggedOut/>}>
            <Route index element={<Message title={"Thank you for verifying your email address"} messages={[
                "Please check your inbox for further instructions on how to get setup."
            ]}/>}/>
        </Route>
        <Route path={"/leader-email-verified"} element={<HeaderLoggedOut/>}>
            <Route index element={<Message title={"Thank you for verifying your email address"} messages={[
                "Your application to be a Progress Pod leader is in the approval process.",
                "We will email your next steps as soon as your application has been approved."
            ]}/>}/>
        </Route>
        <Route path={"/account-deleted"} element={<HeaderLoggedOut/>}>
            <Route index element={<Message title={"Your account has been deleted"}/>}/>
        </Route>
        <Route path={"/forgot-password"} element={<HeaderLoggedOut/>}>
            <Route index element={<ForgotPassword/>}/>
            <Route path={"check-inbox"} element={<Message title={"Email sent"} messages={["Please check your inbox for the link to reset your email address"]}/>}/>
        </Route>
        <Route path={"/reset-password"} element={<HeaderLoggedOut/>}>
            <Route index element={<ResetPassword/>}/>
            <Route path={"password-changed"} element={<PasswordChanged/>}/>
        </Route>
        <Route path={"/sign-up"} element={<HeaderLoggedOut/>}>
            <Route path={"business"} element={<SignUp/>}/>
            <Route path={"check-inbox"} element={<CheckInbox/>}/>
            <Route path={"owner/terms"} element={<OwnerTsAndCs/>}/>
            <Route path={"admin"} element={<SignUpAdmin/>}/>
            <Route path={"admin/terms"} element={<AdminTsAndCs/>}/>
            <Route path={"leader"} element={<SignUpLeader/>}/>
            <Route path={"leader/terms"} element={<LeaderTsAndCs/>}/>
            <Route path={"leader/agreement"} element={<LeaderAgreement/>}/>
            <Route path={"participant"} element={<SignUpParticipant/>}/>
            <Route path={"participant/terms"} element={<ParticipantTsAndCs/>}/>
            <Route path={"participant/agreement"} element={<ParticipantAgreement/>}/>
        </Route>
        <Route path={"/"} element={<Secure/>}>
            <Route path={"/assign-admin"} element={<AssignAdminPrompt/>}/>
            <Route path={"/invite-admin"} element={<InviteAdminPrompt/>}/>
            <Route path={"/remove-admin"} element={<RemoveAdmin/>}/>
            <Route path={"/setup-zoom"} element={<ZoomPrompt/>}/>
            <Route path={"/leader/welcome"} element={<LeaderWelcome/>}/>
            <Route path={"/leader/confirm-training"} element={<LeaderConfirmTraining/>}/>
            <Route path={"/leader/training"} element={<LeaderTraining/>}/>
            <Route path={"/leader/switch"} element={<ConfirmSwitchLeader/>}/>
            <Route path={"/leader/complete-switch"} element={<CompleteSwitchLeader/>}/>
            <Route path={"/leader/demote"} element={<DemoteLeader/>}/>
            <Route path={"/request-leadership"} element={<ConfirmRequestLeadership/>}/>
            <Route path={"/request-leadership-sent"} element={<RequestLeadershipSent/>}/>
            <Route path={"/memberships"} element={<Memberships/>}/>
            <Route path={"/memberships/add"} element={<AddMembership/>}/>
            <Route path={"/membership/reset-links"} element={<ResetLinks/>}/>
            <Route path={"/membership/settings"} element={<EditMembership/>}/>
            <Route path={"/membership/invites"} element={<MembershipInvites/>}/>
            <Route path={"/membership/leave"} element={<ConfirmLeaveMembership/>}/>
            <Route path={"/members"} element={<Members/>}/>
            <Route path={"/member"} element={<Member/>}/>
            <Route path={"/member/remove"} element={<RemoveMember/>}/>
            <Route path={"/leader-pods"} element={<LeaderPods/>}/>
            <Route path={"/leader-pods/add"} element={<AddLeaderPod/>}/>
            <Route path={"/leader-pods/edit"} element={<EditLeaderPod/>}/>
            <Route path={"/leader-pods/confirm-cancel"} element={<ConfirmCancelLeaderPod/>}/>
            <Route path={"/leader-pod"} element={<ViewLeaderPod/>}/>
            <Route path={"/leader-pod/participants"} element={<LeaderPodParticipants/>}/>
            <Route path={"/my-settings"} element={<MySettings/>}/>
            <Route path={"/account/bio"} element={<BioPrompt/>}/>
            <Route path={"/bio"} element={<Bio/>}/>
            <Route path={"/change-password"} element={<ChangePassword/>}/>
            <Route path={"/delete-account"} element={<ConfirmLeaveAccount/>}/>
            <Route path={"/delete-membership"} element={<DeleteMembership/>}/>
            <Route path={"/pods"} element={<Pods/>}/>
            <Route path={"/pods/add"} element={<AddPod/>}/>
            <Route path={"/pod/edit"} element={<EditPod/>}/>
            <Route path={"/pod/admin"} element={<AdminEditPod/>}/>
            <Route path={"/pod/leave"} element={<ConfirmLeavePod/>}/>
            <Route path={"/pod/switch"} element={<SwitchPod/>}/>
            <Route path={"/pod/confirm-switch"} element={<ConfirmSwitchPod/>}/>
            <Route path={"/pods/join-another"} element={<JoinAnotherPod/>}/>
            <Route path={"/pod/confirm-assistant-leader"} element={<ConfirmAssistantLeader/>}/>
            <Route path={"/pod"} element={<ViewPod/>}/>
            <Route path={"/pod/participants"} element={<PodParticipants/>}/>
            <Route path={"/pod/participant"} element={<PodParticipant/>}/>
            <Route path={"/pod/participant/remove"} element={<RemovePodParticipant/>}/>
            <Route path={"/pod/participant/assign"} element={<AssignAssistantLeader/>}/>
            <Route path={"/pod/participant/unassign"} element={<UnassignAssistantLeader/>}/>
            <Route path={"/pods/admin"} element={<PodAdministration/>}/>
            <Route path={"/pods/admin/remove"} element={<AdminRemovePodMember/>}/>
            <Route path={"/pods/admin/remove-leader"} element={<AdminRemovePodLeader/>}/>
            <Route path={"/pods/join"} element={<JoinPod/>}/>
            <Route path={"/pods/confirm-join-pod"} element={<ConfirmJoinPod/>}/>
            <Route path={"/pods/confirm-join-waitlist"} element={<ConfirmJoinWaitlist/>}/>
            <Route path={"/pods/next-tiny-step"} element={<NextTinySteps/>}/>
            <Route path={"/business/settings"} element={<BusinessSettings/>}/>
            <Route path={"/third-party/settings"} element={<ThirdPartySettings/>}/>
            <Route path={"/notifications"} element={<Notifications/>}/>
            <Route path={"/chats"} element={<Channels/>}/>
            <Route path={"/chat"} element={<Channel/>}/>
        </Route>
    </Routes>
};
