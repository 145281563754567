import React from 'react';
import styles from './LeaderConfirmTraining.module.css';
import {useNavigate} from "react-router-dom";
import ButtonPrimary from "../components/ButtonPrimary";
import {Sdk} from "../../domain/sdk/Sdk";
import {UserActionRequired} from "../../domain/sdk/domain/UserActionRequired";

export default function LeaderConfirmTraining() {
    let navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-10 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Confirm leader training complete
                    </div>
                    <div className={`${styles.BodyText}`}>
                        Now that you have completed your training please let us know if you want to continue as a Progress Pod leader.
                    </div>
                </div>
                <div className={`columns small-12 medium-6 large-4 small-centered`}>
                    <div className={`${styles.OkayButtonWrapper}`}>
                        <ButtonPrimary className={`${styles.FullWidthButton}`} text="Continue" type="button" onClick={async () => {
                            await Sdk.onboardingActionsClient.markComplete(Sdk.context.selectedBusiness.id, UserActionRequired.LEADER_CONFIRM_TRAINING);
                            navigate('../leader/training');
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
