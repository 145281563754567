import React, {useState} from 'react';
import formUtils from '../../domain/utils/UseForm';
import styles from './SignUpAdmin.module.css';
import validate from './SignUpAdmin.validation';
import FormInputText from '../components/FormInputText';
import ButtonPrimary from '../components/ButtonPrimary';
import ButtonCancel from "../components/ButtonCancel";
import FormInputPassword from '../components/FormInputPassword';
import {useNavigate, useSearchParams} from "react-router-dom";
import Avatar from "../svg/Avatar";
import Checkbox from "../components/Checkbox";
import {Sdk} from "../../domain/sdk/Sdk";

export default function SignUpAdmin() {
    const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);
    const {values, errors, setErrors, handleChange, handleCheckboxChange, handleSubmit} = formUtils(onFormSubmit, validate);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const inviteCode = searchParams.get("code");

    async function onClickAgreeToTermsAndConditions() {
        setAgreeToTermsAndConditions(!agreeToTermsAndConditions)
    }

    async function onFormSubmit() {
        if (!agreeToTermsAndConditions) {
            setErrors({agreeToTerms : "Please agree to terms"});
        } else if(inviteCode === null) {
            setErrors({agreeToTerms : "Missing code"});
        } else {
            let response = await Sdk.businessAdminClient.register(inviteCode, values.firstName, values.lastName, values.password);
            if (response && response.status === 200) {
                if (response.headers.authorization) {
                    Sdk.context = undefined;
                    localStorage.setItem('access_token', response.headers.authorization.replace('Bearer', '').trim());
                }
                navigate('/memberships');
            }
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.SignUpForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            Admin sign up
                        </div>
                        <div className={`${styles.AvatarWrapper}`}>
                            <Avatar/>
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="First name" name="firstName"
                                           onChange={handleChange}
                                           value={values.firstName || ''}/>
                            {errors.firstName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.firstName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Last name" name="lastName"
                                           onChange={handleChange}
                                           value={values.lastName || ''}/>
                            {errors.lastName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.lastName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="password"
                                               onChange={handleChange}
                                               value={values.password || ''}/>
                            {errors.password && (
                                <p className={`${styles.ErrorMessage}`}>{errors.password}</p>
                            )}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputPassword name="confirm"
                                               onChange={handleChange}
                                               placeholder={"Retype password"}
                                               value={values.confirm || ''}/>
                            {errors.confirm && (
                                <p className={`${styles.ErrorMessage}`}>{errors.confirm}</p>
                            )}
                        </div>
                        <div className={`${styles.CheckboxWrapper}`}>
                            <Checkbox className={styles.Checkbox} name="agreeToTermsAndConditions" label="I AGREE TO THE" onChange={handleCheckboxChange} checked={agreeToTermsAndConditions} onClick={onClickAgreeToTermsAndConditions} >
                                <a href="admin/terms" target="_blank" className={styles.Underline}>TERMS AND CONDITIONS</a>
                            </Checkbox>
                            {errors.agreeToTerms && (
                                <p className={`${styles.ErrorMessage}`}>{errors.agreeToTerms}</p>
                            )}
                        </div>
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Create account & join" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={() => {
                                navigate('/')
                            }}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}
