import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./Channel.module.css";
import {useLocation, useSearchParams} from "react-router-dom";
import {Message} from "../../domain/sdk/domain/Message";
import ButtonPrimary from "../components/ButtonPrimary";
import FormInputText from "../components/FormInputText";
import formUtils from "../../domain/utils/UseForm";
import validate from "./Channel.validation";
import {Sdk} from "../../domain/sdk/Sdk";
import {DateTime} from "luxon";
import ChannelMessage from "./ChannelMessage";
import {respectiveDateTime} from "../../domain/utils/DateTimeUtils";
import Breadcrumb from "../components/Breadcrumb";
import {extract} from "../../domain/utils/QueryUtils";


export default function Channel() {
    const scrollRef = useRef(null as null | HTMLDivElement);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [lastReadMessage, setLastReadMessage] = useState(null as null | Element);
    const {state} = useLocation();
    const [searchParams] = useSearchParams({});
    const [messages, setMessages] = useState([] as Message[]);
    const [currentTime, setCurrentTime] = useState(DateTime.local());
    const [lastReadMessageId, setLastReadMessageId] = useState(undefined as undefined | string);
    const [lastMessageId, setLastMessageId] = useState(undefined as undefined | string);
    const {values, setValues, errors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);
    let id:string;
    let name:string;
    if (searchParams.has("c") && searchParams.has("n")) {
        id = extract(searchParams.get("c"));
        name = extract(searchParams.get("n"));
    } else {
        id = searchParams.has("channelId") ? searchParams.get("channelId") : state.id;
        name = searchParams.has("name") ? searchParams.get("name") : state.name;
    }
    const handleLastRead = useCallback((node:any) => {
        setLastReadMessage(node);
    }, []);

    useEffect(() => {
        (async () => {
            if (id) {
                let response = await Sdk.messageClient.channel(id, (messages: Message[]) => {
                    setMessages(messages);
                    let lastMessage = messages[messages.length - 1].id;
                    setLastReadMessageId(lastMessage)
                    setLastMessageId(lastMessage);
                });
                if (response?.messages) {
                    setMessages(response.messages);
                    setLastReadMessageId(response.lastMessageReadId);
                    setLastMessageId(response.lastMessageId);
                }
                setValues({
                    message: ""
                });
            }
        })();
        const interval = setInterval(() => {
            setCurrentTime(DateTime.local());
        }, 60000);
        return () => clearInterval(interval);
    }, [id, setValues]);

    useEffect(() => {
        lastReadMessage?.scrollIntoView({behavior: "smooth"});
    }, [lastReadMessage, messages]);

    async function onFormSubmit() {
        if (isButtonDisabled) {
            return false;
        }
        setIsButtonDisabled(true);
        await Sdk.messageClient.sendMessage(values.message, id);
        setValues({
            message: "",
        });
        setTimeout(()=> setIsButtonDisabled(false), 500);
    }

    return <div style={{display: "flex", flexDirection: "column", flex: 1}}>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Chats", link: "/chats"},
                        {text: name},
                    ]}/>
                </div>
            </div>
        </div>

        {(!messages || messages.length === 0) && <div className={`row`}>
            <div className={`columns small-12`}>
                <div className={`${styles.NoNotifications}`}>
                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div>No messages</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {messages && <div className={`${styles.ChatInput}`}>
            <div className={`row`}>
                <div className={`columns small-12 ${styles.MessageWrapper}`} ref={scrollRef}>
                    {messages.map((message, index) => {
                        return <div key={message.id}>
                            <ChannelMessage
                                dateTimeFormat={respectiveDateTime(message.createdEpochMillis, currentTime)}
                                message={message}
                                onRead={async (readMessage) => {
                                    await Sdk.messageClient.markMessageAsRead(readMessage);
                                }}
                            />
                            {lastReadMessageId === message.id &&
                                <div key={index + '-last-read'} ref={handleLastRead} className={`columns small-12`}>
                                    {lastReadMessageId !== lastMessageId && <div className={`${styles.LastRead}`}>
                                        Last read
                                    </div>}
                                </div>
                            }
                        </div>
                    })}
                </div>
            </div>
        </div>}

        <form onSubmit={handleSubmit} className={`${styles.FormWrapper}`}>
            <div className={`row hide-for-small-only`}>
                <div className={`columns small-10`}>
                    <FormInputText
                        name="message"
                        placeholder={"Message the group"}
                        onChange={handleChange}
                        value={values.message || ''}
                    />
                </div>
                <div className={`columns small-2`}>
                    <ButtonPrimary disabled={isButtonDisabled} className={`${styles.FloatRight}`} text="Send" type="submit"/>
                </div>
            </div>
            <div className={`row show-for-small-only`}>
                <div className={`columns small-12`}>
                    <FormInputText
                        className={`${styles.InputWrapper}`}
                        name="message"
                        placeholder={"Message the group"}
                        onChange={handleChange}
                        value={values.message || ''}
                    />
                </div>

                <div className={`columns small-12`}>
                    <ButtonPrimary disabled={isButtonDisabled} className={`${styles.FullWidth}`} text="Send" type="submit"/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    {errors.name && (<p className={`${styles.ErrorMessage}`}>{errors.name}</p>)}
                </div>
            </div>
        </form>
    </div>
}
