import React from 'react';
import validate from "./AddPod.validation";
import SetupMeeting from "../components/SetupMeeting";

export default function AddPod() {

    return <SetupMeeting
        validator={validate}
        leaderPod={false}
        edit={false}
    />
}
