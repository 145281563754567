import React, {useEffect, useState} from 'react';
import styles from './ProfilePanel.module.css';
import ButtonPrimary from '../../components/ButtonPrimary';
import formUtils from "../../../domain/utils/UseForm";
import validate from "../Account.validation";
import FormTextarea from "../../components/FormTextarea";
import {Sdk} from "../../../domain/sdk/Sdk";
import FormInputTextWithLabel from "../../components/FormInputTextWithLabel";
import {useNavigate} from "react-router-dom";


export default function ProfilePanel() {
    let navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const {values, setValues, errors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    useEffect(() => {
        (async () => {
            let account = await Sdk.accountClient.myAccount();
            Sdk.context.account.bio = account.bio ?? '';

            setValues({
                bio: Sdk.context.account.bio ?? '',
                emailAddress: Sdk.context.user.email,
                firstName: Sdk.context.user.firstName,
                lastName: Sdk.context.user.lastName,
            });
        })();
    }, [setValues]);

    async function onFormSubmit() {
        await Sdk.accountClient.updateProfile(values.bio, values.emailAddress, values.firstName, values.lastName);
        Sdk.context.account.bio = values.bio;
        Sdk.context.user.firstName = values.firstName;
        Sdk.context.user.lastName = values.lastName;
        setSuccess(true);
        navigate('/my-settings');
        setTimeout(() => setSuccess(false), 2000);
    }

    return <div>
        <div className={`row`}>
            <div className={`columns small-3`}>
                <div className={`${styles.TitleWrapper}`}>
                    Profile
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputTextWithLabel
                            label={"First name"}
                            name="firstName"
                            onChange={handleChange}
                            value={values.firstName || ''}/>
                        {errors.firstName && (<p className={`${styles.ErrorMessage}`}>{errors.firstName}</p>)}
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputTextWithLabel
                            style={{resize: "none"}}
                            label={"Last name"}
                            name="lastName"
                            onChange={handleChange}
                            value={values.lastName || ''}/>
                        {errors.lastName && (<p className={`${styles.ErrorMessage}`}>{errors.lastName}</p>)}
                    </div>
                </div>
                <div className={`columns small-12`}>
                    <div className={`${styles.InputWrapper}`}>
                        <label className={`${styles.Label}`}>
                            Bio
                            <FormTextarea
                                name="bio"
                                placeholder={"Tell us a little about yourself, e.g. location, hobbies, life achievements and a weird fact."}
                                onChange={handleChange}
                                value={values.bio || ''}
                            />
                        </label>
                        {errors.bio && (<p className={`${styles.ErrorMessage}`}>{errors.bio}</p>)}
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-6 large-4 float-right`}>
                    <ButtonPrimary className={`${styles.FullWidth}`} text="Save" type="submit"/>
                </div>
                <div className={`columns small-12 medium-6 large-4`}>
                    {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}
                    {success && (<p className={`${styles.SuccessMessage}`}>{"All saved"}</p>)}
                </div>
            </div>
        </form>
    </div>;
}
