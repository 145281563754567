import React, {useEffect, useState} from 'react';
import styles from './CompleteSwitchLeader.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import {Pod} from "../../domain/sdk/domain/Pod";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CompleteSwitchLeader() {
    const {state} = useLocation();
    const query = useQuery();
    const reference = query.get("r");
    const navigate = useNavigate();
    const [pod, setPod] = useState(undefined as Pod | undefined);
    const [transferId, setTransferId] = useState(undefined as string | undefined);
    const [meetingLink, setMeetingLink] = useState('');
    const [meetingLinkError, setMeetingLinkError] = useState(undefined as String | undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (reference) {
            (async () => {
                let decoded = atob(String(reference))
                let transfer = await Sdk.podsClient.leaderTransferBy(decoded);
                if (transfer) {
                    setTransferId(transfer.id);
                    setPod(await Sdk.podsClient.pod(transfer.podId));
                }
                setLoading(false);
            })();
        } else {
            setPod(state.pod);
            setLoading(false);
        }
    }, [reference, state?.pod])

    function accept(transferId:string) {
        return async () => {
            if (!meetingLink || meetingLink === '') {
                setMeetingLinkError("Meeting link is required");
                return;
            }
            await Sdk.podsClient.acceptTransfer(transferId, meetingLink);
            navigate('/pods', {state: {membershipId: pod?.membershipId}});
        };
    }

    function decline(transferId:string) {
        return async () => {
            await Sdk.podsClient.declineTransfer(transferId);
            navigate('/pods', {state: {membershipId: pod?.membershipId}});
        };
    }

    return <>
        {!loading && transferId && pod && <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Are you happy to lead {pod.name}?
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        A membership admin has requested that you become the leader of '{pod.name}'.
                    </div>
                    <div style={styles.LastInputWrapper}>
                        Once you click accept the Pod will be transferred to you.
                    </div>
                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div style={styles.LastInputWrapper}>
                                <FormInputTextWithLabel
                                    name="meetingLink"
                                    placeholder={"e.g. Zoom"}
                                    onChange={(event) => {
                                        setMeetingLink(event.target.value);
                                        setMeetingLinkError(undefined);
                                    }}
                                    value={meetingLink || ''}
                                />
                                {meetingLinkError && (<p style={styles.ErrorMessage}>{meetingLinkError}</p>)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`columns medium-9 large-12 medium-centered show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidthDelete} text="Accept" type="button" onClick={accept(transferId)}/>
                    <ButtonCancel style={styles.FullWidth} text="Decline" type="button" onClick={decline(transferId)}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.DeclineButton} text="Decline" type="button" onClick={decline(transferId)}/>
                    <ButtonPrimary style={styles.AcceptButton} text="Accept" type="button" onClick={accept(transferId)}/>
                </div>
            </div>
        </div>}
        {!loading && !pod && <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Request not found
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Link is no longer valid
                    </div>
                </div>
            </div>
        </div>}
    </>;
}
