import React from 'react';
import styles from './AccountPanel.module.css';
import {useNavigate} from "react-router-dom";
import ButtonDanger from "../../components/ButtonDanger";
import ButtonSecondary from "../../components/ButtonSecondary";

export interface AccountPanelProps {
    isLeader:boolean;
    isAdmin:boolean;
}

export default function AccountPanel({isLeader, isAdmin}: AccountPanelProps) {
    let navigate = useNavigate();
    return <div>
        <div className={`row`}>
            <div className={`columns small-12`}>
                <div className={`${styles.TitleWrapper}`}>
                    Account
                </div>
            </div>
        </div>
        <div className={`row`}>
            <div className={`columns small-12`}>
                <div className={`${styles.SubTitleWrapper}`}>
                    Security
                </div>
            </div>
            <div className={`columns small-12`}>
                <div className={`${styles.InputWrapper}`}>
                    <div className={`${styles.TextBlock}`}>
                        Click this button to reset your password.
                    </div>
                </div>
                <div className={`${styles.LastInputWrapper}`}>
                    <ButtonSecondary className={`${styles.ButtonWrapper} hide-for-small-only`} text="Change password"
                                     onClick={() => navigate("/change-password")}
                    />
                    <ButtonSecondary className={`${styles.ButtonWrapperFullWidth} show-for-small-only`} text="Change password"
                                     onClick={() => navigate("/change-password")}
                    />
                </div>
            </div>
        </div>
        {!isAdmin && !isLeader && <div className={`row`}>
            <div className={`columns small-12`}>
                <div className={`${styles.SubTitleWrapper}`}>
                    Delete your account
                </div>
            </div>
            <div className={`columns small-12`}>
                <div className={`${styles.InputWrapper}`}>
                    <div className={`${styles.TextBlock}`}>
                        After you click this button you will be asked to confirm your decision before your account is deleted.
                    </div>
                </div>
                <div className={`${styles.InputWrapper}`}>
                    <ButtonDanger className={`${styles.ButtonWrapper} hide-for-small-only`} text="Delete account"
                                  onClick={() => navigate("/delete-account")}
                    />
                    <ButtonDanger className={`${styles.ButtonWrapperFullWidth} show-for-small-only`} text="Delete account"
                                  onClick={() => navigate("/delete-account")}
                    />
                </div>
            </div>
        </div>}
    </div>;
}