import React, {InputHTMLAttributes, useEffect, useState} from 'react';
import styles from './SetupMeeting.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import formUtils from "../../domain/utils/UseForm";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import FormSelect from "../components/FormSelect";
import {DateTime} from "luxon";
import {FrequencyPeriod} from "../../domain/sdk/domain/FrequencyPeriod";
import DateTimeInputWithLabel from "../components/DateTimeInputWithLabel";
import {dateTimeDescription, occurrencesOfDay, updateSelectedWeek} from "../../domain/utils/DateTimeUtils";
import {Sdk} from "../../domain/sdk/Sdk";
import {WeekInMonth} from "../../domain/sdk/domain/WeekInMonth";
import {SelectMonthlyOptions} from "../../domain/sdk/domain/SelectMonthlyOptions";
import {DAYS_OF_WEEK} from "../../domain/sdk/domain/DayOfWeek";
import {ScheduledMeeting} from "../../domain/sdk/domain/ScheduledMeeting";
import ButtonDanger from "./ButtonDanger";
import Toggle from "../svg/Toggle";
import {ScheduledMeetingRequest} from "../../domain/sdk/domain/request/ScheduledMeetingRequest";
import {PodRequest} from "../../domain/sdk/domain/request/PodRequest";

export interface SetupMeetingProps extends InputHTMLAttributes<any> {
    leaderPod?: boolean;
    admin?: boolean
    supportAdHoc?: boolean;
    edit?: boolean;
    cancel?: boolean;
    pause?: boolean
    validator?: Function
}

export default function SetupMeeting({leaderPod = false, admin = false, pause = false, supportAdHoc, edit, cancel, validator}: SetupMeetingProps) {
    const path = leaderPod ? '/leader-pods' : '/pods';
    const action = edit ? 'Update meeting' : 'Schedule meeting';
    const navigate = useNavigate();
    const {state} = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [examples, setExamples] = useState([] as string[]);
    const [meetingDateTime, setMeetingDateTime] = useState(new Date());
    const [pausedFromEpoch, setPausedFromEpoch] = useState(new Date());
    const [pausedToEpoch, setPausedToEpoch] = useState(new Date());
    const [locale, setLocale] = useState('en-GB');
    const [selectedWeek, setSelectedWeek] = useState(WeekInMonth.First);
    const [monthlyOptions, setMonthlyOptions] = useState(SelectMonthlyOptions.Date);
    const [recurringMeeting, setRecurringMeeting] = useState(true);
    const {values, setValues, errors, setErrors, handleChange, handleSubmit} = formUtils(onFormSubmit, validator);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        (async () => {
            let date;
            if (!edit) {
                let now = DateTime.local({zone: 'local'});
                now = now.set({minute: 0, second: 0, millisecond: 0});
                setLocale(now.locale);
                date = now.toJSDate();
                setValues({
                    duration: 60,
                    frequency: 1,
                    recurring: true,
                    frequencyPeriod: FrequencyPeriod.Week,
                });
                let week = updateSelectedWeek(date);
                if (week) {
                    setSelectedWeek(week);
                }
            } else {
                let scheduledMeeting = await Sdk.scheduledMeetingsClient.scheduledMeeting(state.scheduledMeeting.id);
                let dateTime = DateTime.fromSeconds(scheduledMeeting.unixTime, {zone: 'local'});
                setLocale(dateTime.locale);
                date = dateTime.toJSDate();
                setValues({
                    name: state.name,
                    duration: scheduledMeeting.duration,
                    frequency: scheduledMeeting.frequency,
                    frequencyPeriod: scheduledMeeting.frequencyPeriod,
                    meetingLink: scheduledMeeting.thirdPartyMeetingLink,
                });
                let weekInMonth = scheduledMeeting.monthlyOptions?.weekInMonth;
                if (weekInMonth) {
                    setMonthlyOptions(SelectMonthlyOptions.Week);
                }
                setRecurringMeeting(scheduledMeeting.recurring);
                setSelectedWeek(weekInMonth ?? updateSelectedWeek(date) ?? WeekInMonth.First);

                if (pause) {
                    const now = new Date();
                    setPausedFromEpoch(scheduledMeeting.pausedFromEpoch ? new Date(scheduledMeeting.pausedFromEpoch * 1000) : now);
                    setPausedToEpoch(scheduledMeeting.pausedToEpoch ? new Date(scheduledMeeting.pausedToEpoch * 1000) : now);
                    setIsPaused(scheduledMeeting.pausedFromEpoch !== null && scheduledMeeting.pausedToEpoch !== null && (scheduledMeeting?.pausedToEpoch ?? 0) > (now.getTime()/1000));
                }
            }
            setMeetingDateTime(date);
            setLoaded(true);
        })();
    }, [setValues, setSelectedWeek, state, edit, pause]);

    useEffect(() => {
        setExamples([dateTimeDescription(new ScheduledMeeting({
            thirdPartyMeetingLink: values.meetingLink,
            unixTime: DateTime.fromJSDate(meetingDateTime).toSeconds(),
            frequency: values.frequency,
            frequencyPeriod: values.frequencyPeriod,
            duration: values.duration,
            weeklyOptions: {
                days: [DAYS_OF_WEEK[meetingDateTime.getDay()]]
            },
            monthlyOptions: monthlyOptions === SelectMonthlyOptions.Date ? undefined : {
                weekInMonth: selectedWeek,
            }
        }))]);
    }, [meetingDateTime, values, monthlyOptions, selectedWeek]);

    async function onFormSubmit() {
        try {
            if (!values.meetingLink || values.meetingLink === '') {
                errors.meetingLink = 'Meeting link is required';
                return;
            }

            let luxonDateTime = DateTime.fromJSDate(meetingDateTime);
            if (!luxonDateTime.isValid) {
                setErrors({
                    dateTime: "Invalid date time"
                })
                return;
            }
            const now = DateTime.now().toSeconds();
            if (luxonDateTime.toSeconds() < now) {
                setErrors({
                    dateTime: "Date cannot be in the past"
                });
                return;
            }
            if (pause && isPaused) {
                if (pausedToEpoch.getTime() < pausedFromEpoch.getTime()) {
                    setErrors({
                        paused: "End date must be after start date"
                    });
                    return;
                } else if (pausedToEpoch.getTime() / 1000 < now) {
                    setErrors({
                        paused: "End date must be in the future"
                    });
                    return;
                }
            }

            let unixTime = luxonDateTime.toSeconds();
            let request: ScheduledMeetingRequest | PodRequest = {
                name: values.name,
                unixTime: unixTime,
                recurring: recurringMeeting,
                frequency: values.frequency,
                frequencyPeriod: values.frequencyPeriod,
                duration: values.duration,
                thirdPartyMeetingLink: values.meetingLink,
                weeklyOptions: {days: [DAYS_OF_WEEK[meetingDateTime.getDay()]]},
                monthlyOptions: values.frequencyPeriod === FrequencyPeriod.Month && monthlyOptions === SelectMonthlyOptions.Week ? {
                    weekInMonth: selectedWeek,
                } : undefined,
                pausedFromEpoch : pause && isPaused ? (pausedFromEpoch.getTime() / 1000) : undefined,
                pausedToEpoch : pause && isPaused ? (pausedToEpoch.getTime() / 1000) : undefined
            };
            let response;
            if (edit) {
                if (leaderPod) {
                    response = await Sdk.scheduledMeetingsClient.update(state.meetingScheduleId, request);
                } else {
                    response = await Sdk.podsClient.updateDetails(state.id, request);
                }
            } else {
                if (leaderPod) {
                    response = await Sdk.leaderPodsClient.create(state.membershipId, request);
                } else {
                    response = await Sdk.podsClient.create(state.membershipId, request);
                }
            }

            if (response.status === 200) {
                if (admin) {
                    navigate('/members', {state});
                } else {
                    navigate(path, {state});
                }
            } else {
                setErrors({
                    other: `Meeting not ${edit ? 'updated' : 'scheduled'}. Please try again later`
                })
            }
        } catch (e) {
            setErrors({
                dateTime: "Invalid date time"
            })
        }
    }

    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-centered`}>
                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div style={styles.TitleWrapper}>
                                {`${edit ? 'Edit' : 'Schedule'} ${leaderPod ? 'leader ' : ''}meeting`}
                            </div>
                        </div>
                    </div>

                    <form style={styles.InviteForm} onSubmit={handleSubmit}>
                        {supportAdHoc && !edit && <div className={`row`}>
                            <div className={`columns small-12`} style={{marginBottom: 10}}>
                                <ButtonPrimary
                                    type={"button"}
                                    style={{backgroundColor: (recurringMeeting ? "#EA7E3D" : "#969696"), marginRight: 10}}
                                    text={"Recurring"}
                                    onClick={() => setRecurringMeeting(true)}
                                />
                                <ButtonPrimary
                                    type={"button"}
                                    style={{backgroundColor: (recurringMeeting ? "#969696" : "#EA7E3D")}}
                                    text={"Single"}
                                    onClick={() => setRecurringMeeting(false)}
                                />
                            </div>
                        </div>}
                        {recurringMeeting && <div className={`row`}>
                            <div className={`columns small-12`}>
                                <div className={`row`}>
                                    <div className={`columns small-12`} style={styles.ExampleTimes}>
                                        {examples.length > 0 && examples.map((example, index) => {
                                            return <div key={index}>{example}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {!leaderPod && <div className={`row`}>
                            <div className={`columns small-12`}>
                                <div style={styles.InputWrapper}>
                                    <FormInputTextWithLabel
                                        label={"Name"}
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name || ''}
                                    />
                                </div>
                            </div>
                            <div className={`columns small-12`}>
                                {errors.name && (<p style={styles.ErrorMessage}>{errors.name}</p>)}
                            </div>
                        </div>}
                        <div className={`row`}>
                            <div className={`columns small-12 medium-5`}>
                                <div style={styles.InputWrapper}>
                                    <DateTimeInputWithLabel
                                        label={"Next meeting"}
                                        locale={locale}
                                        update={date => {
                                            if (date) {
                                                setMeetingDateTime(date);
                                                let week = updateSelectedWeek(date);
                                                if (week) {
                                                    setSelectedWeek(week);
                                                }
                                            }
                                        }}
                                        date={meetingDateTime}
                                    />
                                </div>
                            </div>
                            <div className={`columns small-4 medium-2 end`}>
                                <div style={styles.InputWrapper}>
                                    <FormInputTextWithLabel
                                        label={"Duration (mins)"}
                                        name="duration"
                                        onChange={handleChange}
                                        value={values.duration}
                                    />
                                </div>
                            </div>
                            {recurringMeeting && <div className={`columns small-3 medium-2`}>
                                <div style={styles.InputWrapper}>
                                    <FormInputTextWithLabel
                                        label={"Every"}
                                        name="frequency"
                                        onChange={handleChange}
                                        value={values.frequency}
                                    />
                                </div>
                            </div>}
                            {recurringMeeting && <div className={`columns small-5 medium-3`}>
                                <div style={styles.InputWrapperNoLabel}>
                                    <FormSelect
                                        name="frequencyPeriod"
                                        onChange={handleChange}
                                        value={values.frequencyPeriod || FrequencyPeriod.Week}
                                        options={[
                                            {text: 'week(s)', value: FrequencyPeriod.Week},
                                            {text: 'month(s)', value: FrequencyPeriod.Month}
                                        ]}
                                    />
                                </div>
                            </div>}

                            {/* monthly options */}
                            {recurringMeeting && values.frequencyPeriod === FrequencyPeriod.Month && <div className={`columns small-12`}>
                                <div className={`row`}>
                                    <div className={`columns small-5 medium-3`}>
                                        <FormSelect
                                            label={"Occurs on"}
                                            name="occursOn"
                                            onChange={event => {
                                                setMonthlyOptions(event.target.value);
                                            }}
                                            value={monthlyOptions}
                                            options={[
                                                {text: 'Date', value: SelectMonthlyOptions.Date},
                                                {text: 'Week', value: SelectMonthlyOptions.Week}
                                            ]}
                                        />
                                    </div>
                                    {monthlyOptions === SelectMonthlyOptions.Week && <div className={`columns small-5 medium-3 end`}>
                                        <FormSelect
                                            label={"Fixed week"}
                                            name="fixedWeek"
                                            onChange={event => {
                                                let selectedWeek = event.target.value;
                                                let occurrences = occurrencesOfDay(meetingDateTime);
                                                let updated = meetingDateTime;
                                                if (selectedWeek === WeekInMonth.First) {
                                                    updated = occurrences[0];
                                                } else if (selectedWeek === WeekInMonth.Second) {
                                                    updated = occurrences[1];
                                                } else if (selectedWeek === WeekInMonth.Third) {
                                                    updated = occurrences[2];
                                                } else if (selectedWeek === WeekInMonth.Fourth) {
                                                    updated = occurrences[3];
                                                } else if (selectedWeek === WeekInMonth.Last) {
                                                    updated = occurrences[occurrences.length - 1];
                                                }
                                                setMeetingDateTime(updated);
                                                setSelectedWeek(selectedWeek);
                                            }}
                                            value={selectedWeek}
                                            options={[
                                                {text: 'First', value: WeekInMonth.First},
                                                {text: 'Second', value: WeekInMonth.Second},
                                                {text: 'Third', value: WeekInMonth.Third},
                                                {text: 'Fourth', value: WeekInMonth.Fourth},
                                                {text: 'Last', value: WeekInMonth.Last}
                                            ]}
                                        />
                                    </div>}
                                </div>
                            </div>}
                        </div>
                        <div className={`row`}>
                            <div className={`columns small-12`}>
                                {errors.dateTime && (<p style={styles.ErrorMessage}>{errors.dateTime}</p>)}
                            </div>
                        </div>
                        <div className={`row`}>
                            <div className={`columns small-12`}>
                                <div style={styles.NextMeetingsHeadingText}>
                                    Meeting link
                                </div>
                            </div>
                            <div className={`columns small-12`}>
                                <div style={styles.InputWrapper}>
                                    <FormInputTextWithLabel
                                        name="meetingLink"
                                        placeholder={"e.g. Zoom"}
                                        onChange={handleChange}
                                        value={values.meetingLink || ''}
                                    />
                                    {errors.meetingLink && (<p style={styles.ErrorMessage}>{errors.meetingLink}</p>)}
                                </div>
                            </div>
                        </div>

                        {edit && pause && <>
                            <div className={`row`}>
                                <div className={`columns small-9`}>
                                    <div style={styles.PauseMeetingsHeadingText}>
                                        Pause meeting
                                    </div>
                                </div>
                                <div className={`columns small-3 text-right`}>
                                    <Toggle
                                        style={{cursor: "pointer", float: "right", marginTop: 5}}
                                        width={50}
                                        height={26}
                                        enabled={isPaused}
                                        onClick={() => setIsPaused(!isPaused)}
                                    />
                                </div>
                            </div>
                            {isPaused && <div className={`row`} style={{marginBottom: 10}}>
                                <div className={`columns small-6`}>
                                    <DateTimeInputWithLabel
                                        label={"Start date"}
                                        locale={locale}
                                        update={date => {
                                            if (date) {
                                                setPausedFromEpoch(date)
                                            }
                                        }}
                                        date={pausedFromEpoch}
                                    />
                                </div>
                                <div className={`columns small-6`}>
                                    <DateTimeInputWithLabel
                                        label={"End date"}
                                        locale={locale}
                                        update={date => {
                                            if (date) {
                                                setPausedToEpoch(date)
                                            }
                                        }}
                                        date={pausedToEpoch}
                                    />
                                </div>
                                <div className={`columns small-12`}>
                                    {errors.paused && (<p style={styles.ErrorMessage}>{errors.paused}</p>)}
                                </div>
                                <div className={`columns small-12`}>
                                    When you click update meeting an email will be sent to the pod participants
                                </div>
                            </div>}
                        </>}

                        <div className={`row`}>
                            <div className={`columns small-12 show-for-small-only`}>
                            <ButtonPrimary style={styles.FullWidth} text={action} type="submit"/>
                                <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                                    if (admin) {
                                        navigate('/members', {state});
                                    } else {
                                        navigate(path, {state})
                                    }
                                }}/>
                                {cancel && <ButtonDanger style={styles.FullWidthDelete} text="Delete meeting" type="button" onClick={async () => {
                                    navigate(path + '/confirm-cancel', {state});
                                }}/>}
                            </div>
                            <div className={`columns medium-12 float-right hide-for-small-only`}>
                                {cancel && <ButtonDanger style={styles.DeleteButton} text="Delete meeting" type="button" onClick={async () => {
                                    navigate(path + '/confirm-cancel', {state});
                                }}/>}
                                <ButtonPrimary style={styles.AssignButton} text={action} type="submit"/>
                                <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                                    if (admin) {
                                        navigate('/members', {state});
                                    } else {
                                        navigate(path, {state})
                                    }
                                }}/>
                            </div>
                            <div className={`columns small-12`}>
                                {errors.other && (<p style={styles.ErrorMessage}>{errors.other}</p>)}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>}
    </>;
}
