import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost} from "../utils/HttpClient";
import {LeaderPod, LeaderPodResponse} from "./domain/LeaderPod";
import {ScheduledMeetingsClient} from "./ScheduledMeetingsClient";
import {PodRequest} from "./domain/request/PodRequest";

export class LeaderPodsClient {
    private readonly membershipUrl: string;
    private readonly leaderPodsUrl: string;
    private scheduledMeetingsClient: ScheduledMeetingsClient;

    constructor(config: SdkConfig, scheduledMeetingsClient: ScheduledMeetingsClient) {
        this.membershipUrl = `${config.apiUrl}/v1/memberships`;
        this.leaderPodsUrl = `${config.apiUrl}/v1/leader-pods`;
        this.scheduledMeetingsClient = scheduledMeetingsClient;
    }

    /* thirdPartyMeetingLink is optionally provided if Zoom third party settings not available */
    async create(membershipId: string, leaderPod:PodRequest) {
        return await httpPost(`${this.membershipUrl}/${membershipId}/leader-pods`, leaderPod);
    }

    async pods(membershipId: string): Promise<LeaderPod[]> {
        let response = await httpGet(`${this.membershipUrl}/${membershipId}/leader-pods`);
        if (response.status === 200) {
            const meetings: LeaderPod[] = [];
            for (const leaderMeeting of response.data as LeaderPodResponse[]) {
                const meeting = await this.scheduledMeetingsClient.scheduledMeeting(leaderMeeting.meetingScheduleId);
                meetings.push({
                    ...leaderMeeting,
                    scheduledMeeting: meeting
                })
            }
            return meetings;
        }
        return [];
    }

    async myLeaderPods(): Promise<LeaderPod[]> {
        let response = await httpGet(`${this.leaderPodsUrl}/my-pods`);
        if (response.status === 200) {
            const meetings: LeaderPod[] = [];
            for (const leaderMeeting of response.data as LeaderPodResponse[]) {
                const scheduledMeeting = await this.scheduledMeetingsClient.scheduledMeeting(leaderMeeting.meetingScheduleId);
                const name = `${leaderMeeting.membershipName} Leader meeting`;
                meetings.push({
                    ...leaderMeeting,
                    name,
                    scheduledMeeting
                })
            }
            return meetings;
        }
        return [];
    }

    async cancelLeaderMeeting(podId: string) {
        return await httpDelete(`${this.leaderPodsUrl}/${podId}`);
    }
}