import * as React from "react"
import { SVGProps } from "react"

export interface MessagesProps extends SVGProps<SVGSVGElement> {
    quantity:number;
}

const Messages = (props: MessagesProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={43}
        height={41}
        viewBox="0 0 24 24"
        {...props}
    >
        <g data-name="Chat">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h8v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" fill="#606060" />
        </g>
        {props.quantity > 0 && <g data-name="quantity">
            <g data-name="Quantity" transform="translate(15)">
                <circle
                    data-name="Ellipse"
                    cx={4.8}
                    cy={4.8}
                    r={4.8}
                    fill="#ea7e3d"
                />
            </g>
            <text
                data-name={1}
                transform="translate(18.2 6.5)"
                fill="#fff"
                fontSize={5.3}
                fontFamily="SFProText-Regular, SF Pro Text"
            >
                {props.quantity < 10 ? props.quantity : ".."}
            </text>
        </g>}
    </svg>
)

export default Messages

