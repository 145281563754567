export function validate(values: any) {
    let errors: any = {};
    if (!values.name || values.name === '') {
        errors.name = 'Membership name is required';
    }
    if (!values.supportEmail || values.supportEmail === '') {
        errors.supportEmail = 'Membership support email is required';
    }
    let settings = values.settings;
    if (settings) {
        if (settings.LeaderAgreementLink && !settings.LeaderAgreementLink.startsWith("http")) {
            errors.leaderAgreementLink = 'Link must start with http';
        }
        if (settings.ParticipantAgreementLink && !settings.ParticipantAgreementLink.startsWith("http")) {
            errors.participantAgreementLink = 'Link must start with http';
        }
        if (settings.LeaderTrainingLink && !settings.LeaderTrainingLink.startsWith("http")) {
            errors.leaderTrainingLink = 'Link must start with http';
        }
        if (settings.ParticipantOnboardingLink && !settings.ParticipantOnboardingLink.startsWith("http")) {
            errors.participantOnboardingLink = 'Link must start with http';
        }
    }
    if (values.customButton1Label && values.customButton1Label !== '') {
        if (!values.customButton1Url || values.customButton1Url === '') {
            errors.customButtons = 'Url is required if label is provided'
        }
    }
    if (values.customButton1Url && values.customButton1Url !== '') {
        if (!values.customButton1Label || values.customButton1Label === '') {
            errors.customButtons = 'Label is required if link is provided'
        }
    }
    if (!values.maxParticipants || values.maxParticipants === '') {
        errors.maxParticipants = 'Max participants is required';
    } else {
        try {
            let number = parseInt(values.maxParticipants);
            if (number < 1) {
                errors.maxParticipants = 'Must be a positive number';
            }
        } catch (e) {
            errors.maxParticipants = 'Must be a positive number';
        }
    }
    return errors;
}