import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost} from "../utils/HttpClient";
import {UserActionRequired} from "./domain/UserActionRequired";
import {Sdk} from "./Sdk";

export class OnboardingActionsClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/onboarding-actions`;
    }

    async myActions(businessId:string):Promise<UserActionRequired[]> {
        let response = await httpGet(`${this.url}/my-actions/${businessId}`);
        return response.data ?? [];
    }

    async markComplete(businessId:string, action: string) {
        let response = await httpPost(`${this.url}/mark-complete`, {businessId, action});
        if (response?.status === 200) {
            let actions = Sdk.context.actionsRequired;
            actions.splice(actions.indexOf(action), 1);
        }
        return response;
    }
}