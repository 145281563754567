import React, {useEffect, useState} from 'react';
import styles from './ThirdPartySettings.module.css';
import ButtonPrimary from '../components/ButtonPrimary';
import {validateZoom} from "./ThirdPartySettings.validation";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import {Sdk} from "../../domain/sdk/Sdk";
import {UserActionRequired} from "../../domain/sdk/domain/UserActionRequired";

export default function ThirdPartySettings() {
    const [zoomApiKey, setZoomApiKey] = useState('');
    const [zoomApiSecret, setZoomApiSecret] = useState('');
    const [zoomUserId, setZoomUserId] = useState('');
    const [zoomSuccess, setZoomSuccess] = useState(false);
    const [errors, setErrors] = useState({} as any);

    useEffect(() => {
        (async () => {
            let businessId = Sdk.context.selectedBusiness.id;
            let response = await Sdk.zoomClient.settings(businessId);
            if (response.status === 200) {
                let data = response.data;
                setZoomApiKey(data.apiKey);
                setZoomApiSecret(data.secret);
                setZoomUserId(data.userId);
            }
        })();
    }, [])

    async function updateZoomCredentials() {
        let errors = validateZoom(zoomApiKey, zoomApiSecret, zoomUserId);
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        let businessId = Sdk.context.selectedBusiness.id;
        let response = await Sdk.zoomClient.setup(businessId, zoomApiKey, zoomApiSecret, zoomUserId);
        if (response.status === 200) {
            await Sdk.onboardingActionsClient.markComplete(businessId, UserActionRequired.ZOOM_PROMPT);
            setZoomSuccess(true);
            setTimeout(() => setZoomSuccess(false), 2000);
        } else {
            setErrors({
                zoomErrors : "Unable to update Zoom credentials. Please try again later."
            });
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-6 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Third Party Integration
                    </div>
                </div>
                <div className={`columns medium-9 large-6 small-centered`}>
                    <div className={`${styles.SubHeadingText}`}>
                        Zoom
                    </div>
                </div>
                <div className={`columns medium-9 large-6 small-centered`}>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputTextWithLabel label="API Key" name="zoomApiKey" placeholder={"Web link"} value={zoomApiKey ?? ''} onChange={event => setZoomApiKey(event.target.value)}/>
                        {errors.zoomApiKey && (<p className={`${styles.ErrorMessage}`}>{errors.zoomApiKey}</p>)}
                    </div>
                </div>
                <div className={`columns medium-9 large-6 small-centered`}>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputTextWithLabel label="API Secret" name="zoomApiSecret" placeholder={"Authentication token"} value={zoomApiSecret ?? ''} onChange={event => setZoomApiSecret(event.target.value)}/>
                        {errors.zoomApiSecret && (<p className={`${styles.ErrorMessage}`}>{errors.zoomApiSecret}</p>)}
                    </div>
                </div>
                <div className={`columns medium-9 large-6 small-centered`}>
                    <div className={`${styles.LastInputWrapper}`}>
                        <FormInputTextWithLabel label="Zoom User Id" name="zoomUserId" placeholder={"Email"} value={zoomUserId ?? ''} onChange={event => setZoomUserId(event.target.value)}/>
                        {errors.zoomUserId && (<p className={`${styles.ErrorMessage}`}>{errors.zoomUserId}</p>)}
                    </div>
                </div>

                <div className={`columns small-12 small-centered show-for-small-only`}>
                    <ButtonPrimary className={`${styles.FullWidth}`} text="Update Zoom Credentials" type="button" onClick={updateZoomCredentials}/>
                </div>
                <div className={`columns medium-9 large-6 medium-centered hide-for-small-only`}>
                    <ButtonPrimary className={`${styles.FullWidth}`} text="Update Zoom Credentials" type="button" onClick={updateZoomCredentials}/>
                </div>
                <div className={`columns medium-9 large-6 small-centered`}>
                    {errors.zoomErrors && (<p className={`${styles.ErrorMessage}`}>{errors.zoomErrors}</p>)}
                    {zoomSuccess && (<p className={`${styles.SuccessMessage}`}>{"All saved"}</p>)}
                </div>
            </div>
        </div>
    </>;
}
