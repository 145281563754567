import {UserClient} from "./UserClient";
import {SdkConfig} from "./SdkConfig";
import {AccountClient} from "./AccountClient";
import {BusinessClient} from "./BusinessClient";
import Auth from "../Auth";
import {BusinessAdminClient} from "./BusinessAdminClient";
import {MembersClient} from "./MembersClient";
import {MembershipsClient} from "./MembershipsClient";
import {OnboardingActionsClient} from "./OnboardingActionsClient";
import {LeaderPodsClient} from "./LeaderPodsClient";
import {InAppNotificationsClient} from "./InAppNotificationsClient";
import {PodsClient} from "./PodsClient";
import {ScheduledMeetingsClient} from "./ScheduledMeetingsClient";
import {ZoomClient} from "./ZoomClient";
import {LeadersClient} from "./LeadersClient";
import {NextTinyStepClient} from "./NextTinyStepClient";
import {MessageClient} from "./MessageClient";
import {PushNotificationClient} from "./PushNotificationClient";
import {DateTime} from "luxon";
import {LogoClient} from "./LogoClient";
import {NotificationSettingsClient} from "./SubscriberFrquencyClient";

export class ProgressPodSdk {
    private readonly auth: Auth;
    userClient: UserClient;
    config: SdkConfig;
    logoClient: LogoClient;
    businessClient: BusinessClient;
    accountClient: AccountClient;
    businessAdminClient: BusinessAdminClient;
    membershipsClient: MembershipsClient;
    leadersClient: LeadersClient;
    leaderPodsClient: LeaderPodsClient;
    podsClient: PodsClient;
    scheduledMeetingsClient: ScheduledMeetingsClient;
    membersClient: MembersClient;
    onboardingActionsClient: OnboardingActionsClient;
    nextTinyStepClient: NextTinyStepClient;
    zoomClient: ZoomClient;
    inAppNotificationsClient: InAppNotificationsClient;
    messageClient: MessageClient;
    pushNotificationClient: PushNotificationClient;
    notificationSettingsClient: NotificationSettingsClient;
    context: any;

    constructor(config: SdkConfig) {
        this.config = config;
        this.auth = new Auth();
        this.logoClient = new LogoClient(config);
        this.userClient = new UserClient(config, this.auth);
        this.accountClient = new AccountClient(config);
        this.businessClient = new BusinessClient(config);
        this.businessAdminClient = new BusinessAdminClient(config);
        this.membershipsClient = new MembershipsClient(config);
        this.scheduledMeetingsClient = new ScheduledMeetingsClient(config);
        this.leadersClient = new LeadersClient(config);
        this.leaderPodsClient = new LeaderPodsClient(config, this.scheduledMeetingsClient);
        this.podsClient = new PodsClient(config, this.scheduledMeetingsClient);
        this.membersClient = new MembersClient(config);
        this.onboardingActionsClient = new OnboardingActionsClient(config);
        this.nextTinyStepClient = new NextTinyStepClient(config);
        this.zoomClient = new ZoomClient(config);
        this.inAppNotificationsClient = new InAppNotificationsClient(config);
        this.messageClient = new MessageClient(config);
        this.pushNotificationClient = new PushNotificationClient(config);
        this.notificationSettingsClient = new NotificationSettingsClient(config);
    }

    async authenticateAndInitialise() {
        let authenticated = this.auth.isAuthenticated();
        if (!this.context) {
            await this.initialise();
        }
        return authenticated;
    }

    isAuthenticated() {
        return this.auth.isAuthenticated();
    }

    async login(username: string, password: string) {
        let response = await this.userClient.login(username, password);
        if (this.auth.isAuthenticated()) {
            await this.initialise();
        }
        return response;
    }

    private async initialise() {
        console.info('Initialise...');
        try {
            this.context = {status: 200}
            let user = await this.userClient.myUser();
            if (user) {
                let businesses = await this.businessClient.myBusinesses();
                let account = await this.accountClient.myAccount();
                this.context.user = user;
                this.context.account = account;
                if (!account.timezone || account.timezone === '') {
                    await Sdk.accountClient.updateTimezone(DateTime.now().zoneName);
                }
                await this.inAppNotificationsClient.myNotifications();
                await this.messageClient.initialise(user.id);
                this.context.actionsRequired = [];
                this.context.businesses = [];
                if (businesses && businesses.length > 0) {
                    businesses = businesses.sort((a, b) => a.name.localeCompare(b.name));
                    let ownedBusinesses = businesses.filter(business => business.owner);
                    let adminBusinesses = businesses.filter(business => business.admin);
                    let selectedBusiness;
                    if (ownedBusinesses.length > 0) {
                        selectedBusiness = ownedBusinesses[0];
                    } else if (ownedBusinesses.length > 0) {
                        selectedBusiness = adminBusinesses[0];
                    } else {
                        selectedBusiness = businesses[0];
                    }
                    this.context.businesses = businesses;
                    this.context.selectedBusiness = selectedBusiness;
                    this.context.actionsRequired = await this.onboardingActionsClient.myActions(selectedBusiness.id);
                }
                this.pushNotificationClient.subscribeToPushNotifications().then(()=> console.info("Subscribed to push notifications"));
            } else {
                this.context.status = 403
            }
        } catch (e) {
            console.error('Initialisation failed', e);
        }
    }
}

const config = process.env.REACT_APP_ENVIRONMENT === 'DEV' ? SdkConfig.local() : SdkConfig.prod();
export const Sdk = new ProgressPodSdk(config);