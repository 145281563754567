export interface InAppNotification {
    id:string;
    membershipName:string;
    message:string;
    async:boolean;
    actionType:NotificationActionType;
    primaryDisplayName:string;
    primaryActionUrl:string;
    primaryIcon:Icon;
    secondaryDisplayName:string;
    secondaryActionUrl:string;
    externalLink?:string;
    epoch:number;
}

 export enum NotificationActionType {
     ACKNOWLEDGE = 'ACKNOWLEDGE',
     DISMISSIBLE_ACTION = 'DISMISSIBLE_ACTION',
     ACTION = 'ACTION',
     CHOICE = 'CHOICE'
 }

 export enum Icon {
     Like = 'Like',
 }







