import styles from './ButtonCancel.module.css'
import React, {ButtonHTMLAttributes} from 'react';

export interface ButtonCancelProps extends ButtonHTMLAttributes<any> {
    className?: string;
    text?: string;
}

export default function ButtonCancel({className, text, ...other}: ButtonCancelProps) {
    return <button className={`${styles.ButtonCancel} ${className ? className : ''}`} {...other}>
        {text}
    </button>
}
