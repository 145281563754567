export default function validate(values:any) {
    let errors:any = {};
    if ((values.oldPassword && values.oldPassword.length < 8) || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(values.oldPassword)) {
        errors.oldPassword = '8 characters, 1 number & 1 uppercase';
    }
    if ((values.newPassword && values.newPassword.length < 8) || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(values.newPassword)) {
        errors.newPassword = '8 characters, 1 number & 1 uppercase';
    }
    if (values.confirm !== values.newPassword) {
        errors.confirm = 'Passwords do not match';
    }
    return errors;
};