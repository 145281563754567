import styles from './ButtonPrimary.module.css'
import React, {ButtonHTMLAttributes} from 'react';

export interface ButtonPrimaryProps extends ButtonHTMLAttributes<any> {
    className?: string;
    text?: string;
}

export default function ButtonPrimary({className, text, ...other}: ButtonPrimaryProps) {
    return <button className={`${styles.ButtonPrimary} ${className ? className : ''}`} {...other}>
        {text}
    </button>
}
