export default function validate(values) {
    let errors = {};
    if (!values.email || values.email === '') {
        errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    if (!values.password || values.password === '') {
        errors.password = 'Password is required';
    }
    return errors;
};