import React, {useEffect, useState} from 'react';
import styles from './SwitchPod.module.css';
import {Link, useLocation} from "react-router-dom";
import {Sdk} from "../../domain/sdk/Sdk";
import {Pod} from "../../domain/sdk/domain/Pod";
import {MY_PODS} from "./MySettings";
import Breadcrumb from "../components/Breadcrumb";

export default function SwitchPod() {
    const {state} = useLocation();
    const [pods, setPods] = useState([] as Pod[]);

    useEffect(() => {
        (async () => {
            let allPods = await Sdk.podsClient.pods(state.membershipId);
            let userId = Sdk.context.user.id;
            let pods = allPods.filter(pod => pod.leaderId !== userId && !pod.members.includes(userId));
            setPods(pods.filter(pod => pod.members.length < pod.maxParticipants));
        })();
    }, [state.membershipId]);

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "My Settings", link: "/my-settings", state: {panel: MY_PODS}},
                        {text: "Switch Pod"},
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.LastInputWrapper}`}>
                        This will <strong>remove you</strong> from your existing Pod and transfer your Next Tiny Steps to the selected Pod.
                    </div>
                </div>
            </div>

            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Select Progress Pod
                    </div>
                    {pods.length === 0 && <div className={`${styles.InstructionsText}`}>
                        No Progress Pods available
                    </div>}
                    <div className={`row`}>
                        <div>{pods && pods.map((pod: Pod, index) => {
                            return <div key={index} className={`columns large-6 end`}>
                                <div className={`${styles.PodName}`}>{pod.name}</div>
                                <div className={`${styles.MeetingDateTime}`}>
                                    {pod.scheduledMeeting.frequencyDescription}
                                </div>
                                <div className={`${styles.MeetingDateTime}`}>
                                    {`${pod.maxParticipants - pod.members.length} places available`}
                                </div>
                                <div className={`${styles.CardLinkWrapper}`}>
                                    <Link className={`${styles.CardLink} ${styles.JoinWidth}`} to={`/pod/confirm-switch`} state={
                                        {
                                            membershipId: state.membershipId,
                                            fromPod: state,
                                            toPod: pod
                                        }}>
                                        Switch
                                    </Link>
                                </div>
                            </div>
                        })}</div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
