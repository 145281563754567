import React from 'react';
import {validateMeetingSchedule} from "../../domain/utils/DateTimeUtils";
import SetupMeeting from "../components/SetupMeeting";

export default function EditLeaderPod() {

    return <>
        <SetupMeeting
            validator={validateMeetingSchedule}
            leaderPod={true}
            supportAdHoc={true}
            edit={true}
            cancel={true}
        />
    </>
}
