import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost, httpPut} from "../utils/HttpClient";
import {NextTinyStep} from "./domain/NextTinyStep";

export class NextTinyStepClient {
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1`;
    }

    async myNextTinySteps():Promise<NextTinyStep[]> {
        let response = await httpGet(`${this.url}/my-next-tiny-steps`);
        return response.data ?? [];
    }

    async nextTinySteps(podId:string):Promise<NextTinyStep[]> {
        let response = await httpGet(`${this.url}/pods/${podId}/next-tiny-steps`);
        return response.data ?? [];
    }

    async commit(stepId:string, goal:string, description:string, commitmentDate:number) {
        return await httpPost(`${this.url}/next-tiny-steps/${stepId}/commit`, {goal, description, commitmentDate});
    }

    async update(stepId:string, nextTinyStep: NextTinyStep) {
        return await httpPut(`${this.url}/next-tiny-steps/${stepId}`, nextTinyStep);
    }
}