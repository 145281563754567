import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost} from "../utils/HttpClient";

export class ZoomClient {
    private readonly businessesUrl: string;

    constructor(config: SdkConfig) {
        this.businessesUrl = `${config.apiUrl}/v1/businesses`;
    }

    async setup(businessId:string, zoomApiKey:string, zoomApiSecret:string, zoomUserId:string) {
        return await httpPost(`${this.businessesUrl}/${businessId}/zoom/settings`, {
            zoomApiKey,
            zoomApiSecret,
            zoomUserId
        });
    }

    async settings(businessId:string) {
        return await httpGet(`${this.businessesUrl}/${businessId}/zoom/settings`);
    }

    async supported(businessId:string) {
        let response = await httpGet(`${this.businessesUrl}/${businessId}/zoom/supported`);
        return response.status === 200;
    }
}