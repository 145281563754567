import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost, httpPut} from "../utils/HttpClient";
import {Business} from "./domain/Business";

export class BusinessClient {
    private readonly businessUrl: string;

    constructor(config: SdkConfig) {
        this.businessUrl = `${config.apiUrl}/v1/businesses`;
    }

    async myBusinesses(): Promise<Business[]> {
        let response = await httpGet(`${this.businessUrl}/my-businesses`);
        if (`${response.status}`.startsWith("2")) {
            return response.data;
        }
        return [];
    }

    async register(businessName: string, firstName: string, lastName: string, email: string, password: string) {
        return await httpPost(`${this.businessUrl}`, {
            businessName,
            firstName,
            lastName,
            email,
            password
        })
    }

    async updateName(businessId: string, name: string) {
        return await httpPut(`${this.businessUrl}/${businessId}`, {name});
    }

    async inviteAdmin(businessId: string, name: string, email: string) {
        return await httpPost(`${this.businessUrl}/${businessId}/admins`, {name, email});
    }
}