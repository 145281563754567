import styles from './FormSelect.module.css'
import React, {SelectHTMLAttributes} from 'react';
import Chevron from "../svg/Chevron";

export interface FormSelectHProps extends SelectHTMLAttributes<any> {
	label?:string
	options:Option[];
	labelClassName?:string;
}
export interface Option {
	text:string;
	value:string;
}

export default function FormSelectSlim({className, labelClassName, label, name, options, ...other}:FormSelectHProps) {
	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.Select_Label}`} htmlFor={name}>
			{label && <div className={`${labelClassName ? labelClassName : ''}`}>{label}</div>}
			<div className={`${styles.Select_Wrapper}`}>
				<select className={`${styles.Select_Slim}`} name={name} {...other}>
					{options ? options.map((option, index) => <option value={option.value} key={option.text}>{option.text}</option>) : ''}
				</select>
				<Chevron className={`${styles.Select_Icon}`} />
			</div>
		</label>
	</div>
}
