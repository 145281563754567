import styles from './FormInputTextWithLabel.module.css'
import React, {InputHTMLAttributes} from 'react';

export interface FormInputTextWithLabelProps extends InputHTMLAttributes<any> {
	className?: string;
	label?: string;
}

export default function FormInputTextWithLabel({className, label, placeholder, name, ...other}:FormInputTextWithLabelProps) {
	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.InputText_Label}`} htmlFor={name}>
			{label}
			<div className={`${styles.InputText_Wrapper}`}>
				<input className={`${styles.InputText_Input}`} placeholder={placeholder} type="text" name={name} {...other}/>
			</div>
		</label>
	</div>
}
