import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost} from "../utils/HttpClient";
import {Admin} from "./domain/Admin";

export class BusinessAdminClient {
    private readonly businessUrl: string;
    private readonly businessAdminsUrl: string;

    constructor(config: SdkConfig) {
        this.businessUrl = `${config.apiUrl}/v1/businesses`;
        this.businessAdminsUrl = `${config.apiUrl}/v1/business-admins`;
    }

    async register(inviteCode: string, firstName: string, lastName: string, password: string) {
        return await httpPost(`${this.businessAdminsUrl}/register`, {
            inviteCode,
            firstName,
            lastName,
            password
        });
    }

    async fetchAdmins(businessId: string): Promise<Admin[] | undefined> {
        let response = await httpGet(`${this.businessUrl}/${businessId}/business-admins`);
        if (response.status === 200) {
            return response.data as Admin[];
        }
    }

    async removeAdmin(businessId: string, adminId: string) {
        return await httpDelete(`${this.businessUrl}/${businessId}/business-admins/${adminId}`);
    }
}