import React from 'react';
import styles from './MyPodsPanel.module.css';
import {Link} from "react-router-dom";
import {MyMembership} from "../../../domain/sdk/domain/Membership";
import {Pod} from "../../../domain/sdk/domain/Pod";
import {Sdk} from "../../../domain/sdk/Sdk";
import {Waitlist} from "../../../domain/sdk/domain/Waitlist";

export interface MyPodsPanelProps {
    memberships: MyMembership[];
    pods: Pod[];
    waitlists: Waitlist[];
    settings: Map<string, Map<string, string>>;
    isLeader:boolean;
}

export default function MyPodsPanel({memberships, pods, waitlists, settings, isLeader}: MyPodsPanelProps) {
    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>
                        My Pods
                    </div>
                </div>
            </div>
            {memberships.map((membership: MyMembership, index: number) => {
                let podsForMembership = pods.filter(value => value.membershipId === membership.membershipId);
                let waitlistsForMembership = waitlists.filter(value => value.membershipId === membership.membershipId);
                return <div key={index} style={{paddingBottom:50}}>
                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div className={`${styles.SubTitleWrapper}`}>
                                {membership.name} Membership
                            </div>
                        </div>
                    </div>

                    {waitlistsForMembership.length > 0 && <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div className={`${styles.Heading}`}>
                                Waitlists
                            </div>
                        </div>
                        <div className={`columns small-12`}>
                            <div className={`${styles.TextBlock}`} style={{marginBottom: 5, fontStyle: "italic"}}>
                                You are on the following waitlists
                            </div>
                            <div className={`${styles.InputWrapper}`}>
                                {waitlistsForMembership.map((waitlist: Waitlist, index) => {
                                    return <div key={`waitlist-${index}`} className={`${styles.TextBlock} ${styles.HighlightTextBlock}`}>
                                        {waitlist.name}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>}

                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div className={`${styles.Heading}`}>
                                Pods
                            </div>
                        </div>
                        <div className={`columns small-12`}>
                            <div className={`${styles.InputWrapper}`}>
                                {podsForMembership.map((pod: Pod, index) => {
                                    return <div key={index} className={`${styles.TextBlock}`}>
                                        {pod.name}&nbsp;&nbsp;
                                        <Link className={`${styles.Link}`} to={`/pod`} state={pod}>View Pod</Link>&nbsp;&nbsp;
                                        {!membership.leader && <>
                                            <Link className={`${styles.Link}`} to={`/pod/switch`} state={pod}>Switch Pod</Link>&nbsp;&nbsp;
                                        </>}
                                        {!(pod.leaderId === Sdk.context.user.id && pod.members.length > 0) && <>
                                            <Link className={`${styles.Link}`} to={`/pod/leave`} state={pod}>Leave Pod</Link>
                                        </>}
                                    </div>
                                })}
                                {podsForMembership.length === 0 && <div className={`${styles.InputWrapper} ${styles.HighlightTextBlock}`}>
                                    You are not currently in a Progress Pod for this membership.&nbsp;
                                    <Link to={`/pods/join`} className={`${styles.Link}`} state={membership}>
                                        Click here to join a Pod
                                    </Link>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {!membership.leader && <>
                        <br/>
                        <div className={`row`}>
                            <div className={`columns small-12`}>
                                <div className={`${styles.InputWrapper}`}>
                                    <Link className={`${styles.Link}`} to={`/request-leadership`} state={membership}>Apply to lead your own Pod</Link>
                                </div>
                            </div>
                        </div>
                    </>}

                    {settings.has(membership.membershipId) && settings.get(membership.membershipId)?.has('ParticipantAgreementLink') && <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div className={`${styles.Heading}`}>
                                Agreements
                            </div>
                        </div>
                        <div className={`columns small-12`}>
                            <div className={`${styles.InputWrapper}`}>
                                {isLeader && <Link className={`${styles.Link}`} to={"/my-settings"} onClick={async () => {
                                    window.open(settings.get(membership.membershipId)?.get('LeaderAgreementLink') as string, '_blank');
                                }}>
                                    View leader agreement
                                </Link>}
                                {!isLeader && <Link className={`${styles.Link}`} to={"/my-settings"} onClick={async () => {
                                    window.open(settings.get(membership.membershipId)?.get('ParticipantAgreementLink') as string, '_blank');
                                }}>
                                    View participant agreement
                                </Link>}
                            </div>
                        </div>
                    </div>}
                </div>
            })}
        </div>
    </>;
}