import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styles from './ViewPod.module.css';
import ButtonSecondary from "../components/ButtonSecondary";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import {CustomButton} from "../../domain/sdk/domain/CustomButton";
import {extract, useQuery} from "../../domain/utils/QueryUtils";
import {ScheduledMeeting} from "../../domain/sdk/domain/ScheduledMeeting";
import Breadcrumb from "../components/Breadcrumb";
import {Pod} from "../../domain/sdk/domain/Pod";
import {PodMember} from "../../domain/sdk/domain/PodMember";

export default function ViewPod() {
    const navigate = useNavigate();
    let queryParams = useQuery();
    const {state} = useLocation();
    const {model} = (() => {
        const leaderIdParam = queryParams.get("l");
        const membershipIdParam = queryParams.get("m");
        const membershipNameParam = queryParams.get("mn");
        const podIdParam = queryParams.get("p");
        const podNameParam = queryParams.get("n");
        const meetingScheduleIdParam = queryParams.get("s");
        return {
            model: {
                params: podIdParam ?? false,
                leaderId: leaderIdParam ? extract(leaderIdParam) : state.leaderId,
                membershipId : membershipIdParam ? extract(membershipIdParam) : state.membershipId,
                membershipName: membershipNameParam ? extract(membershipNameParam) : state.membershipName,
                id: podIdParam ? extract(podIdParam) : state.id,
                name: podNameParam ? extract(podNameParam) : state.name,
                meetingScheduleIdParam,
                scheduledMeeting : state?.scheduledMeeting,
                ...state
            }
        }
    })();
    const [loaded, setLoaded]: any = useState(false);
    const [customButton, setCustomButton]: any = useState(undefined as undefined | CustomButton);
    const [ntsDisabled, setNtsDisabled]: any = useState(true);
    const [scheduledMeeting, setScheduledMeeting]: any = useState(state?.scheduledMeeting as undefined | ScheduledMeeting);
    const [assistantName, setAssistantName]: any = useState(undefined as undefined | string);
    const [pod, setPod] = useState<Pod>();
    const [members, setMembers] = useState<PodMember[]>();

    useEffect(() => {
        (async () => {
            const pod = await Sdk.podsClient.pod(model.id);
            setPod(pod);
            const members = await Sdk.podsClient.members(model.id);
            setMembers(members);
            let customButtons = await Sdk.membershipsClient.customButtons(model.membershipId);
            const podButtons = customButtons?.filter(button => button.placement === 'VIEW_POD');
            if (podButtons && podButtons.length > 0) {
                setCustomButton(podButtons[0]);
            }
            let featureToggles = await Sdk.membershipsClient.membershipFeatures(model.membershipId);
            setNtsDisabled(featureToggles.has('NextTinyStep') && featureToggles.get('NextTinyStep'));
            if (model.meetingScheduleIdParam) {
                setScheduledMeeting(await Sdk.scheduledMeetingsClient.scheduledMeeting(extract(model.meetingScheduleIdParam)));
            }

            if (model.leaderId === Sdk.context.user.id) {
                let assistant = await Sdk.podsClient.assistantLeaderByPod(model.id);
                if (assistant) {
                    let matchingMembers = members.filter(member => member.userId === assistant?.assistantId);
                    if (matchingMembers.length === 1) {
                        let podMember = matchingMembers[0];
                        setAssistantName(`${podMember.firstName} ${podMember.lastName}`);
                    }
                }
            }
            setLoaded(true);
        })();
    }, [model.leaderId, model.meetingScheduleIdParam, model.membershipId, model.id]);

    const numberOfMembers = members?.filter(member => !member.leader)?.length ?? 0;

    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Progress Pods", link: "/pods", state: {...model, name: model.membershipName}},
                        {text: model.name, link: "/pod", state: model}
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`} style={{marginBottom:20}}>
                    <div className={`${styles.SubTitleText}`}>
                        {model.membershipName}
                    </div>
                    <div className={`${styles.DateText}`}>
                        {scheduledMeeting?.nextMeeting}
                    </div>
                    {assistantName && <div className={`${styles.DateText}`}>
                        {assistantName} is assigned as the assistant leader to run this meeting
                    </div>}
                    {pod && members && <div className={styles.StatsText}>
                        {numberOfMembers} {numberOfMembers === 1 ? "member" : "members"}, {Math.max(pod.maxParticipants - numberOfMembers, 0)} {(pod.maxParticipants - numberOfMembers) === 1 ? "space" : "spaces"}. {pod.waitlist.length} Waitlist
                    </div>}
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-5 small-left`}>
                    <ButtonSecondary className={`${styles.ButtonWrapper}`} text="View participants" type="button" onClick={async () => {
                        navigate('/pod/participants', {state:{...model}});
                    }}/>
                    {!ntsDisabled && <ButtonSecondary className={`${styles.ButtonWrapper}`} text="Download meeting checklist" type="button" onClick={async () => {
                        await Sdk.podsClient.downloadAgenda(model.id);
                    }}/>}
                    {customButton && <>
                        {customButton.formLabel && customButton.formLabel !== '' && <div>{customButton.formLabel}</div>}
                        <ButtonSecondary className={`${styles.ShareWinButtonWrapper}`} text={customButton.label} type="button" onClick={async () => {
                            window.open(customButton.url, '_blank');
                        }}/>
                    </>}
                </div>

                <div className={`columns small-12 medium-5 small-left`}>
                    {scheduledMeeting?.thirdPartyMeetingLink &&
                        <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Join online meeting" type="button" onClick={() => {
                            window.open(scheduledMeeting.thirdPartyMeetingLink, '_blank');
                        }}/>}
                    {!ntsDisabled && <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Next Tiny Step" type="button" onClick={() => {
                        navigate('/pods/next-tiny-step', {state:{...model, scheduledMeeting}});
                    }}/>}
                </div>
            </div>
        </div>}
    </>;
}
