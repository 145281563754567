import * as React from "react"
import { SVGProps } from "react"

const Anchor = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20.799}
        height={10.059}
        {...props}
    >
        <g data-name="Layer 13" transform="translate(1 1)">
            <path
                data-name="Path 135"
                d="M7.843 8.059h-3.82A4.036 4.036 0 0 1 0 4.029h0A4.036 4.036 0 0 1 4.023 0h3.828"
                fill="none"
                stroke="#606060"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
            <path
                data-name="Path 136"
                d="M11.002 0h3.774a4.036 4.036 0 0 1 4.023 4.029h0a4.036 4.036 0 0 1-4.023 4.029h-3.765"
                fill="none"
                stroke="#606060"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
            <rect
                data-name="Rectangle 27"
                width={1.812}
                height={9.196}
                rx={0.906}
                transform="rotate(-90 4.869 .067)"
                fill="#606060"
            />
        </g>
    </svg>
)

export default Anchor
