import React from 'react';
import styles from './LeaderWelcome.module.css';
import {useNavigate} from "react-router-dom";
import ButtonSecondary from "../components/ButtonSecondary";
import {Sdk} from "../../domain/sdk/Sdk";
import {UserActionRequired} from "../../domain/sdk/domain/UserActionRequired";

export default function LeaderWelcome() {
    let navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-11 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Welcome!
                    </div>
                    <div className={`${styles.BodyText}`}>
                        Once approved by the administrator you will receive an email that contains the link to the training.
                    </div>
                </div>
                <div className={`columns small-12 medium-6 large-4 small-centered`}>
                    <div className={`${styles.OkayButtonWrapper}`}>
                        <ButtonSecondary className={`${styles.FullWidthButton}`} text="Ok" type="button" onClick={async () => {
                            await Sdk.onboardingActionsClient.markComplete(Sdk.context.selectedBusiness.id, UserActionRequired.LEADER_APPROVAL_ACKNOWLEDGEMENT);
                            navigate('../leader/confirm-training');
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
