import React, {useContext, useEffect, useState} from 'react';
import styles from './Pod.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Edit from "../svg/Edit";
import {Pod} from "../../domain/sdk/domain/Pod";
import {LeaderPod} from "../../domain/sdk/domain/LeaderPod";
import {LeaderStatus} from "../../domain/sdk/domain/LeaderStatus";
import {Waitlist} from "../../domain/sdk/domain/Waitlist";
import {dateSuffix} from "../../domain/utils/DateTimeUtils";
import {compareAllPodsByWeekdayThenTime} from "../../domain/utils/PodUtils";
import Breadcrumb from "../components/Breadcrumb";
import {SecureContext} from "../state/SecureContext";
import People from "../svg/People";
import {isMeetingPaused} from "../../domain/utils/MeetingUtils";

export default function Pods() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const {state} = useLocation();
    const navigate = useNavigate();
    const [leaderStatus, setLeaderStatus] = useState(LeaderStatus.NONE);
    const [leaderPods, setLeaderPods] = useState([] as LeaderPod[]);
    const [pods, setPods] = useState([] as Pod[]);
    const [waitlists, setWaitlists] = useState([] as Waitlist[]);
    const [isPodLeader, setIsPodLeader] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [canJoinPod, setCanJoinPod] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSelectedMembershipId(state.membershipId);
        (async () => {
            setLeaderStatus(await Sdk.leadersClient.leaderStatus(state.membershipId));
            let allLeaderPods = await Sdk.leaderPodsClient.myLeaderPods();
            setLeaderPods(allLeaderPods
                .filter(pod => pod.membershipId === state.membershipId)
                .sort(compareAllPodsByWeekdayThenTime())
            );
            let myPods = await Sdk.podsClient.myPods();
            myPods = myPods
                .filter(pod => pod.membershipId === state.membershipId)
                .sort(compareAllPodsByWeekdayThenTime());
            setPods(myPods);
            setWaitlists(await Sdk.podsClient.myWaitlists());
            setLoading(false);
        })();
    }, [state.membershipId, setSelectedMembershipId]);

    useEffect(() => {
        (async () => {
            let podLeader = pods.filter(pod => pod.leaderId === Sdk.context.user.id).length > 0;
            setIsPodLeader(podLeader);
            setIsAdmin(Sdk.context.selectedBusiness?.owner || Sdk.context.selectedBusiness?.admin);

            let isPodMember = (podLeader && pods.length > 1) || (!podLeader && pods.length > 0);
            setCanJoinPod(!isPodMember);
        })();
    }, [pods]);

    return <>
        {!loading && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Memberships", link: "/memberships", state: state},
                        {text: state.membershipName ?? state.name},
                        {text: "Progress Pods"},
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-6 small-left`}>
                    {(isAdmin || (leaderStatus === LeaderStatus.SIGNED_OFF && !isPodLeader)) &&
                        <ButtonPrimary className={`${styles.AddPodWrapper}`} text="Add Pod" type="button" onClick={() => {
                            navigate('/pods/add', {state});
                        }}/>}
                    {canJoinPod &&
                        <ButtonPrimary className={`${styles.JoinPodWrapper}`} text="Join Pod" type="button" onClick={() => {
                            navigate('/pods/join', {state});
                        }}/>}
                </div>
            </div>
            {leaderPods.length > 0 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitle}`}>Leader Pods</div>
                </div>
            </div>}
            {leaderPods && <div className={`row`}>
                {leaderPods.map((pod, index) => {
                    return <div key={pod.id} className={`columns large-4 end`}>
                        <div className={`${styles.CardWrapper}`}>
                            <div className={`${styles.CardTitle}`}>
                                {pod.membershipName} leader meeting <People className={`${styles.Icon}`}/>
                            </div>
                            <div className={`${styles.CardDateAndTime}`}>
                                {pod.scheduledMeeting.nextMeeting}
                            </div>
                            <div className={`${styles.CardItem}`}>
                                <Link className={`${styles.CardLink}`} to={`/leader-pod`} state={{...pod, name: state.name}}>
                                    View details
                                </Link>
                                {isAdmin && <Link className={`${styles.CardLink} ${styles.EditLink}`} to={`/leader-pods/edit`} state={{...pod, name: state.name}}>
                                    <div className={`${styles.Edit}`}>Edit</div>
                                    <Edit className={`${styles.Icon}`}/>
                                </Link>}
                            </div>
                        </div>
                    </div>
                })}
            </div>}

            {pods.length > 0 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitle}`}>My Progress Pods</div>
                </div>
            </div>}
            {pods && <div className={`row`}>
                {pods.map((pod: Pod, index) => {
                    return <div key={index} className={`columns large-4 end`}>
                        <div className={`${styles.CardWrapper}`}>
                            <div style={{display: 'flex'}}>
                                <div className={`${styles.CardTitle}`}>
                                    {pod.name}
                                </div>
                                {isMeetingPaused(pod.scheduledMeeting) && <div style={{marginLeft: "auto", fontSize: 12}}>PAUSED</div>}
                            </div>
                            <div className={`${styles.CardDateAndTime}`}>
                                {pod.scheduledMeeting.nextMeeting}
                            </div>
                            <div className={`${styles.CardItem}`}>
                                <Link className={`${styles.CardLink}`} to={`/pod`} state={pod}>
                                    View pod details
                                </Link>
                                {pod.leaderId === Sdk.context.user.id && <Link className={`${styles.CardLink} ${styles.EditLink}`} to={`/pod/edit`} state={pod}>
                                    <div className={`${styles.Edit}`}>Edit</div>
                                    <Edit className={`${styles.Icon}`}/>
                                </Link>}
                            </div>
                        </div>
                    </div>
                })}
            </div>}

            {waitlists.length > 0 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitle}`}>My Waitlists</div>
                </div>
            </div>}
            {waitlists && <div className={`row`}>
                {waitlists.map((pod: Waitlist, index) => {
                    return <div key={index} className={`columns large-4 end`}>
                        <div className={`${styles.CardWrapper}`}>
                            <div className={`${styles.CardTitle}`}>
                                {pod.name}
                            </div>
                            <div className={`${styles.CardDateAndTime}`}>
                                {pod.scheduledMeeting.frequencyDescription}
                            </div>
                            <div className={`${styles.QueueText}`}>
                                You are {pod.position}{dateSuffix(pod.position)} in the queue
                            </div>
                        </div>
                    </div>
                })}
            </div>}
        </div>}
    </>;
}
