import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPost} from "../utils/HttpClient";

export class LogoClient {
    private cache:Map<string, string|undefined> = new Map<string, string>();
    private readonly url: string;

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1`;
    }

    async fetch(membershipId:string) {
        if (this.cache.has(membershipId)) {
            return this.cache.get(membershipId);
        }
        let response = await httpGet(`${this.url}/memberships/${membershipId}/logo`);
        if (response.status === 404) {
            this.cache.set(membershipId, undefined);
            return;
        }
        let data = response.data as string;
        this.cache.set(membershipId, data);
        return data;
    }

    async upload(membershipId:string, data:string) {
        this.cache.set(membershipId, data);
        return await httpPost(`${this.url}/memberships/${membershipId}/logo`, {data});
    }
}