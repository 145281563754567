import styles from './ButtonSecondary.module.css'
import React, {ButtonHTMLAttributes} from 'react';

export interface ButtonSecondaryProps extends ButtonHTMLAttributes<any> {
	className?: string;
	text?: string;
}

export default function ButtonSecondary({className, text, ...other}:ButtonSecondaryProps) {
	return <button className={`${styles.ButtonSecondary} ${className ? className : ''}`} {...other}>
		{text}
	</button>
}
