import * as React from "react"
import { SVGProps } from "react"

const Edit = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16.916}
        height={16.994}
        {...props}
    >
        <g data-name="Layer 11">
            <path
                data-name="Path 149"
                d="m4.004 15.766-2.719.226A.262.262 0 0 1 1 15.715l.142-2.811a.937.937 0 0 1 .273-.612l8.769-8.788 3.183 3.19-8.781 8.8a.933.933 0 0 1-.582.272Z"
                fill="none"
                stroke="#606060"
                strokeMiterlimit={10}
                strokeWidth={2}
            />
            <path
                data-name="Path 154"
                d="m1.519 12.387 3 3.007"
                fill="none"
                stroke="#606060"
                strokeLinecap="round"
                strokeWidth={1.5}
            />
            <path
                data-name="Path 150"
                d="m13.287.515 3.111 3.111a.732.732 0 0 1-.004 1.037L14.988 6.07a.023.023 0 0 1-.032 0l-4.105-4.113a.023.023 0 0 1-.002-.035L12.255.515a.728.728 0 0 1 1.032 0Z"
                fill="#606060"
            />
        </g>
    </svg>
)

export default Edit
