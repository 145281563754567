import React from 'react';
import styles from './Member.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonPrimary from "../components/ButtonPrimary";
import Breadcrumb from "../components/Breadcrumb";
import ButtonDanger from "../components/ButtonDanger";

export default function PodParticipant() {
    const navigate = useNavigate();
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Progress Pods", link: "/pods", state: state},
                        {text: "Participants", link: "/pod/participants", state: state.pod},
                        {text: `${state.member.firstName} ${state.member.lastName}`},
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.BodyText}`}>
                        Member bio
                    </div>
                </div>
                <div className={`columns small-12`}>
                    <div className={`${styles.InputWrapper}`}>
                        {state.member.bio ?? "No bio"}
                    </div>
                </div>
            </div>
            {state.member.email && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.BodyText}`}>
                        Participant email address
                    </div>
                </div>
                <div className={`columns small-12`}>
                    <div className={`${styles.InputWrapper}`}>
                        {state.member.email}
                    </div>
                </div>
            </div>}
            <div className={`row`}>
                <div className={`columns small-12 medium-6 large-5 small-left`}>
                    <div className={`${styles.BodyText}`}>
                        Participant admin
                    </div>
                    <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Remove from pod" type="button" onClick={async () => {
                        navigate('/pod/participant/remove', {state});
                    }}/>
                    {!state.isAssistant && <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Assign assistant leader" type="button" onClick={async () => {
                        navigate('/pod/participant/assign', {state});
                    }}/>}
                    {state.isAssistant && <ButtonDanger className={`${styles.ButtonWrapper}`} text="Unassign assistant leader" type="button" onClick={async () => {
                        navigate('/pod/participant/unassign', {state});
                    }}/>}
                </div>
            </div>
        </div>
    </>;
}
