import React from 'react';
import styles from './LeaderTraining.module.css';
import {useNavigate} from "react-router-dom";
import ButtonPrimary from "../components/ButtonPrimary";

export default function LeaderTraining() {
    let navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-10 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Leader Meeting
                    </div>
                    <div className={`${styles.BodyText}`}>
                        You’ve automatically been added to the Leaders Meeting group.
                    </div>
                </div>
                <div className={`columns small-12 medium-6 large-4 small-centered`}>
                    <div className={`${styles.OkayButtonWrapper}`}>
                        <ButtonPrimary className={`${styles.FullWidthButton}`} text="Continue" type="button" onClick={async () => {
                            navigate('/memberships');
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
