import React, {useEffect, useState} from 'react';
import styles from './BioPrompt.module.css';
import ButtonPrimary from '../components/ButtonPrimary';
import formUtils from "../../domain/utils/UseForm";
import validate from "./BioPrompt.validation";
import FormTextarea from "../components/FormTextarea";
import {Sdk} from "../../domain/sdk/Sdk";
import {UserActionRequired} from "../../domain/sdk/domain/UserActionRequired";
import {useNavigate} from "react-router-dom";

export default function BioPrompt() {
    let navigate = useNavigate();
    const [firstLogin, setFirstLogin] = useState(true);
    const {values, setValues, errors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    useEffect(() => {
        setValues({
            bio: Sdk.context.account.bio ?? '',
            emailAddress: Sdk.context.user.email,
            firstName: Sdk.context.user.firstName,
            lastName: Sdk.context.user.lastName,
        });
        setFirstLogin(Sdk.context.actionsRequired?.includes(UserActionRequired.COMPLETE_PROFILE))
    }, [setValues]);

    async function onFormSubmit() {
        await Sdk.accountClient.updateProfile(values.bio, values.emailAddress, values.firstName, values.lastName);
        Sdk.context.account.bio = values.bio;
        await Sdk.onboardingActionsClient.markComplete(Sdk.context.selectedBusiness.id, UserActionRequired.COMPLETE_PROFILE);
        navigate('/memberships');
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-10 large-8 small-centered`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Bio
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className={`columns medium-10 large-8 small-centered`}>
                        <div className={`${styles.InputWrapper}`}>
                            <label className={`${styles.Label}`}>
                                <FormTextarea
                                    name="bio"
                                    placeholder={"Tell us a little about yourself, e.g. location, hobbies, life achievements and a weird fact."}
                                    onChange={handleChange}
                                    value={values.bio || ''}
                                    autoFocus={firstLogin}/>
                            </label>
                            {errors.bio && (<p className={`${styles.ErrorMessage}`}>{errors.bio}</p>)}
                        </div>
                    </div>
                    <div className={`columns small-12 medium-6 large-4 small-centered`}>
                        <ButtonPrimary className={`${styles.FullWidth}`} text="Save" type="submit"/>
                    </div>
                </form>
            </div>
        </div>
    </>;
}
