import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet} from "../utils/HttpClient";
import {InAppNotification} from "./domain/InAppNotification";
import {Sdk} from "./Sdk";

export class InAppNotificationsClient {
    private readonly url: string;
    private listeners:NotificationListener[] = [];

    constructor(config: SdkConfig) {
        this.url = `${config.apiUrl}/v1/notifications`;
    }

    addListener(listener: NotificationListener) {
        this.listeners.push(listener);
    }

    async myNotifications(): Promise<InAppNotification[]> {
        let response = await httpGet(`${this.url}/my-notifications`);
        let notifications = response.data ?? [];
        Sdk.context.notifications = notifications;
        for (const listener of this.listeners) {
            listener(notifications.length);
        }
        return notifications;
    }

    async dismissNotification(notificationId: string) {
        return await httpDelete(`${this.url}/${notificationId}`);
    }
}

export interface NotificationListener {
    (quantity:number):void;
}