import React from 'react';
import styles from './ConfirmCancelLeaderPod.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonDanger from "../components/ButtonDanger";

export default function ConfirmCancelLeaderPod() {
    const {state} = useLocation();
    const navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Delete Leader Meeting
                    </div>
                </div>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        Are you sure you want to delete this leader meeting?
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered show-for-small-only`}>
                    <ButtonDanger style={styles.FullWidthDelete} text="Yes. Delete meeting" type="button" onClick={async () => {
                        await Sdk.leaderPodsClient.cancelLeaderMeeting(state.id);
                        navigate('/leader-pods', {state});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/leader-pods/edit', {state});
                    }}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/leader-pods/edit', {state});
                    }}/>
                    <ButtonDanger style={styles.DeleteButton} text="Yes. Delete meeting" type="button" onClick={async () => {
                        await Sdk.leaderPodsClient.cancelLeaderMeeting(state.id);
                        navigate('/leader-pods', {state});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
