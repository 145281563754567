const styles: any = {
    TitleWrapper: {
        paddingTop: 50,
        paddingBottom: 50,
        color: "#EA7E3D",
        fontSize: 21,
    },

    InviteAdminText: {
        textAlign: "center",
        fontSize: 21,
        color: "#606060",
        marginBottom: 50,
        fontWeight: 300
    },

    InviteForm: {},

    InputWrapper: {
        paddingBottom: 10,
    },

    LastInputWrapper: {
        paddingBottom: 30,
    },

    AssignButton: {
        width: "60%",
        fontSize: 22,
        float: "right",
    },

    CancelButton: {
        width: "39%",
        fontSize: 22,
    },

    FullWidth: {
        width: "100%",
        marginTop: 10,
        marginBottom: 10,
    },

    ErrorMessage: {
        color: "#EA7E3D",
        margin: 0,
        paddingTop: 0,
        paddingRight: 5,
        paddingBottom: 0,
        paddingLeft: 5,
    }
}

export default styles;