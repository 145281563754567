import React from 'react';
import styles from './DeleteMembership.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import {MEMBERSHIPS} from "./MySettings";
import ButtonDanger from "../components/ButtonDanger";

export default function DeleteMembership() {
    const navigate = useNavigate();
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Delete membership
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Are you sure you want to delete membership {state.name}?
                    </div>
                    <div style={styles.LastInputWrapper}>
                        This action cannot be undone. It will remove all members and pods.
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state: {...state, ...{panel: MEMBERSHIPS}}});
                    }}/>
                    <ButtonDanger style={styles.AssignButton} text="Yes. Delete Membership" type="button" onClick={async () => {
                        await Sdk.membershipsClient.deleteMembership(state.membershipId);
                        navigate('/memberships');
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonDanger style={styles.FullWidth} text="Yes. Delete Membership" type="button" onClick={async () => {
                        await Sdk.membershipsClient.deleteMembership(state.membershipId);
                        navigate('/memberships');
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state: {...state, ...{panel: MEMBERSHIPS}}});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
