import React from 'react';
import styles from './RemoveMember.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";

export default function RemoveMember() {
    const navigate = useNavigate();
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Remove participant
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Are you sure you want to remove {state.member.firstName} {state.member.lastName}?
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        They will no longer be able to access the Progress Pods in this membership.
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/member', {state});
                    }}/>
                    <ButtonPrimary style={styles.RemoveButton} text="Remove participant" type="button" onClick={async () => {
                        await Sdk.membersClient.removeMember(state.membershipId, state.member.id);
                        navigate('/members', {state});
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidth} text="Remove participant" type="button" onClick={async () => {
                        await Sdk.membersClient.removeMember(state.membershipId, state.member.id);
                        navigate('/members', {state});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/member', {state});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
