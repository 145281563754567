import {Pod} from "../sdk/domain/Pod";
import {LeaderPod} from "../sdk/domain/LeaderPod";
import {ReadOnlyPod} from "../sdk/domain/Membership";

export function compareAllPodsByWeekdayThenTime() {
    return (a: Pod | LeaderPod, b: Pod | LeaderPod) => {
        let diff = a.scheduledMeeting.weekdayNumber() - b.scheduledMeeting.weekdayNumber();
        if (diff !== 0) {
            return diff;
        }
        return a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime;
    };
}

export function comparePodsByWeekdayThenTime() {
    return (a: Pod, b: Pod) => {
        let diff = a.scheduledMeeting.weekdayNumber() - b.scheduledMeeting.weekdayNumber();
        if (diff !== 0) {
            return diff;
        }
        return a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime;
    };
}

export function comparePodsBySizeThenWeekdayThenTime() {
    return (a: Pod, b: Pod) => {
        let diff = b.members.length - a.members.length;
        if (diff !== 0) {
            return diff;
        }
        diff = a.scheduledMeeting.weekdayNumber() - b.scheduledMeeting.weekdayNumber();
        if (diff !== 0) {
            return diff;
        }
        return a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime;
    };
}

export function compareReadOnlyPodsByWeekdayThenTime() {
    return (a: ReadOnlyPod, b: ReadOnlyPod) => {
        if (a.scheduledMeeting && b.scheduledMeeting) {
            let diff = a.scheduledMeeting.weekdayNumber() - b.scheduledMeeting.weekdayNumber();
            if (diff !== 0) {
                return diff;
            }
            return a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime;
        }
        return 0
    };
}

export function comparePodsByNameThenWeekdayThenTime() {
    return (a: Pod, b: Pod) => {
        let diff = a.name.localeCompare(b.name);
        if (diff !== 0) {
            return diff;
        }
        diff = a.scheduledMeeting.weekdayNumber() - b.scheduledMeeting.weekdayNumber();
        if (diff !== 0) {
            return diff;
        }
        return a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime;
    };
}