import React, {useEffect, useState} from 'react';
import styles from './ManageAdminsPanel.module.css';
import {Link, useNavigate} from "react-router-dom";
import {Sdk} from "../../../domain/sdk/Sdk";
import {Admin} from "../../../domain/sdk/domain/Admin";
import ButtonSecondary from "../../components/ButtonSecondary";


export default function ManageAdminsPanel() {
    let navigate = useNavigate();
    const [isOwner, setIsOwner] = useState(false);
    const [admins, setAdmins] = useState([] as Admin[]);

    useEffect(() => {
        let business = Sdk.context.selectedBusiness;
        setIsOwner(business?.owner);
        (async () => {
            let admins = await Sdk.businessAdminClient.fetchAdmins(Sdk.context.selectedBusiness.id);
            setAdmins(admins ?? []);
        })()
    }, [])

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Administrators
                    </div>
                </div>
            </div>

            {isOwner && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.LastInputWrapper}`}>
                        <ButtonSecondary className={`${styles.InviteButton} hide-for-small-only`} text="Invite Admin"
                                         onClick={() => navigate("/invite-admin")}
                        />
                        <ButtonSecondary className={`${styles.ButtonWrapperFullWidth} show-for-small-only`} text="Invite Admin"
                                         onClick={() => navigate("/invite-admin")}
                        />
                    </div>
                </div>
            </div>}

            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitleWrapper}`}>
                        Manage Admins
                    </div>
                </div>
            </div>
            {admins.map((user: Admin, index: number) => {
                return <div key={index}>
                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            <div className={`${styles.HighlightTextBlock}`}>
                                {user.firstName} {user.lastName}&nbsp;&nbsp;
                                {Sdk.context.selectedBusiness?.owner && <Link className={`${styles.Link}`} to={`/remove-admin`} state={user}>Remove Admin</Link>}
                            </div>
                            <div className={`${styles.TextBlock}`}>
                                {user.email}
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>;
}