import styles from './HeaderLoggedOut.module.css';
import LogoWrapper from './/LogoWrapper';
import React from 'react';
import {Outlet} from "react-router-dom";

export interface HeaderLoggedOutProps {
    className?: string;
}

export default function HeaderLoggedOut({className}:HeaderLoggedOutProps) {
    return <>
        <div className={`${styles.Header} ${className ? className : ''}`}>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.LogoWrapper}`}>
                        <LogoWrapper className={styles.LogoInnerWrapper}/>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <Outlet />
        </div>
    </>;
}
