import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost} from "../utils/HttpClient";
import {LeaderStatus} from "./domain/LeaderStatus";
import {Leader} from "./domain/Leader";

export class LeadersClient {
    private readonly membershipsUrl: string;

    constructor(config: SdkConfig) {
        this.membershipsUrl = `${config.apiUrl}/v1/memberships`;
    }

    async leaders(membershipId:string): Promise<Leader[]> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/leaders`);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }

    async leaderStatus(membershipId: string): Promise<LeaderStatus> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/my-leader-status`);
        if (response.status === 200) {
            return response.data;
        }
        return LeaderStatus.NONE
    }

    async requestLeadership(membershipId: string) {
        await httpPost(`${this.membershipsUrl}/${membershipId}/request-leadership`, {});
    }

    async demoteLeader(membershipId: string, leaderId:string) {
        await httpDelete(`${this.membershipsUrl}/${membershipId}/leaders/${leaderId}`, {});
    }
}