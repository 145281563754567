import React from 'react';
import styles from './SignIn.module.css';
import validate from './SignIn.validation';
import formUtils from '../../domain/utils/UseForm';
import FormInputText from '../components/FormInputText';
import ButtonPrimary from '../components/ButtonPrimary';
import FormInputPassword from '../components/FormInputPassword';
import {Sdk} from '../../domain/sdk/Sdk';
import Avatar from "../svg/Avatar";
import {Link, useNavigate} from "react-router-dom";
import {rootNavigation} from "../../domain/utils/NavigationUtils";

export default function SignIn() {
    let navigate = useNavigate();
    const {values, errors, setErrors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    async function onFormSubmit() {
        let response = await Sdk.login(values.email, values.password);
        if (response && response.status === 200) {
            navigate(rootNavigation());
        } else {
            setErrors({
                other: "Email and/or password not recognised. Alternatively email may not be verified."
            })
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.UserForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            <Avatar/>
                        </div>
                        <div className={`${styles.UsernameWrapper}`}>
                            <FormInputText
                                placeholder="Email"
                                name="email"
                                onChange={handleChange}
                                value={values.email || ''}
                                autoComplete="username"
                            />
                            {errors.email && (
                                <p className={`${styles.ErrorMessage}`}>{errors.email}</p>
                            )}
                        </div>
                        <div className={`${styles.EmailWrapper}`}>
                            <FormInputPassword
                                name="password"
                                               onChange={handleChange}
                                               value={values.password || ''}
                                               autoComplete="current-password"/>
                            {errors.password && (
                                <p className={`${styles.ErrorMessage}`}>{errors.password}</p>
                            )}
                        </div>
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Login" type="submit"/>
                        </div>
                        {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}

                        <div className={`${styles.ForgotPasswordContainer}`}>
                            <Link className={`${styles.ForgotPasswordText}`} to="/forgot-password">Forgot Password?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}
