const styles: any = {
    TitleWrapper: {
        paddingTop: 50,
        paddingBottom: 26,
        color: "#EA7E3D",
        fontSize: 21,
    },

    SubHeadingText: {
        fontSize: 21,
        color: "#606060",
        marginBottom: 20,
        fontWeight: 300
    },

    SubTitleWrapper :{
        paddingBottom: 5,
        marginBottom: 20,
        color: "#606060",
        fontSize: 21,
        display: "inline-block",
        borderBottom: "1px #bbbaba solid",
        width: "100%"
    },

    TextBlock : {
        fontSize: 20,
        linHeight: 20,
        color: "#606060",
        display: "block",
        marginBottom: 5,
        fontWeight: 300
    },

    InviteForm: {
        marginBottom: 50
    },

    InputWrapper: {},

    InputWithDefaultsWrapper: {
        display: "flex",
        flexDirection: "row"
    },

    LinkInputWrapper: {
        flex : 1
    },

    LastInputWrapper: {
        paddingBottom: 20,
    },

    InputIcons: {
        paddingTop: 22,
        float: 'right',
    },

    IconLink: {
        display: 'block',
    },

    AssignButton: {
        width: "50%",
        fontSize: 22,
        float: "right",
    },

    CancelButton: {
        width: "49%",
        fontSize: 22,
    },

    FullWidth: {
        width: "100%",
        marginBottom: 10,
    },

    FeatureCheckbox: {
        fontSize: 18
    },

    ErrorMessage: {
        color: "#EA7E3D",
        margin: 0,
        paddingTop: 0,
        paddingRight: 5,
        paddingBottom: 0,
        paddingLeft: 5,
    }
}

export default styles;