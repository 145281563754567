export function validateZoom(key:string, secret:string, userId:string) {
    let errors:any = {};
    if (!key || key === '') {
        errors.zoomApiKey = "Zoom key is required";
    }
    if (!secret || secret === '') {
        errors.zoomApiSecret = "Zoom secret is required";
    }
    if (!userId || userId === '') {
        errors.zoomUserId = "Zoom user ID is required";
    }
    if (!new RegExp(/\S+@\S+\.\S+/).test(userId)) {
        errors.zoomUserId = "Zoom user email is invalid";
    }
    return errors;
}