const styles: any = {
    TitleWrapper: {
        paddingTop: 50,
        paddingBottom: 26,
        color: "#EA7E3D",
        fontSize: 21,
    },

    SubHeadingText: {
        fontSize: 21,
        color: "#606060",
        marginBottom: 20,
        fontWeight: 300
    },

    NextMeetingsHeadingText: {
        fontSize: 21,
        color: "#606060",
        marginTop: 20,
        marginBottom: 5,
        fontWeight: 300
    },

    PauseMeetingsHeadingText: {
        fontSize: 21,
        color: "#606060",
        marginBottom: 15,
        fontWeight: 300
    },

    InviteForm: {
        marginBottom: 50
    },

    InputWrapper: {
        marginBottom: 10
    },

    InputWrapperNoLabel: {
        marginTop: 20,
        marginBottom: 10
    },

    Description: {
        fontSize: 21,
        color: "#606060",
        marginBottom: 10,
        fontWeight: 300
    },

    LastInputWrapper: {
        paddingBottom: 20,
    },

    Checkbox : {
        paddingBottom: 10,
    },

    CheckboxLabel : {
        paddingLeft: 5,
    },

    DisplayInline : {
        display: "inline-block",
        paddingRight: 20
    },

    AssignButton: {
        fontSize: 22,
        paddingLeft: 30,
        paddingRight: 30,
        float: "right",
    },

    CancelButton: {
        fontSize: 22,
        float: "right",
        paddingLeft: 30,
        paddingRight: 30,
        marginRight: 20
    },

    FullWidth: {
        width: "100%",
        marginBottom: 10,
    },

    ExampleTimes: {
        paddingBottom: 20,
        color: "#606060",
        fontWeight: 600
    },

    ErrorMessage: {
        color: "#EA7E3D",
        margin: 0,
        paddingTop: 0,
        paddingRight: 5,
        paddingBottom: 0,
        paddingLeft: 5,
    },

    DeleteButton: {
        fontSize: 22,
        paddingLeft: 30,
        paddingRight: 30,
    },

    FullWidthDelete: {
        width: "100%",
        marginBottom: 10,
    }
}

export default styles;