import React from 'react';
import styles from './ConfirmLeaveMembership.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonDanger from "../components/ButtonDanger";
import {MY_PODS} from "./MySettings";

export default function ConfirmLeaveMembership() {
    const {state} = useLocation();
    const navigate = useNavigate();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Leave Membership
                    </div>
                </div>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        Are you sure you want to leave the <strong>{state.name}</strong> membership?
                    </div>
                </div>

                <div className={`columns medium-9 large-7 medium-centered show-for-small-only`}>
                    <ButtonDanger style={styles.FullWidthDelete} text="Yes. Leave membership" type="button" onClick={async () => {
                        await Sdk.membersClient.leave(state.membershipId);
                        navigate('/memberships', {state});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state : {...state, ...{panel:MY_PODS}}});
                    }}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/my-settings', {state : {...state, ...{panel:MY_PODS}}});
                    }}/>
                    <ButtonDanger style={styles.DeleteButton} text="Yes. Leave membership" type="button" onClick={async () => {
                        await Sdk.membersClient.leave(state.membershipId);
                        navigate('/memberships', {state});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
