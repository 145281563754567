import React, {useContext, useEffect, useState} from 'react';
import styles from './RemoveMember.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import {extract} from "../../domain/utils/QueryUtils";
import {Pod} from "../../domain/sdk/domain/Pod";
import {Membership} from "../../domain/sdk/domain/Membership";
import {SecureContext} from "../state/SecureContext";

export default function ConfirmAssistantLeader() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [searchParams] = useSearchParams({});
    const podId = searchParams.has("p") ? extract(searchParams.get("p")) : state.podId;
    const [meetingLink, setMeetingLink] = useState('');
    const [error, setError] = useState(undefined as undefined | string);
    const [pod, setPod] = useState(undefined as undefined | Pod);
    const [membership, setMembership] = useState(undefined as undefined | Membership);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            let pod = await Sdk.podsClient.pod(podId);
            setPod(pod);
            if (pod) {
                setSelectedMembershipId(pod.membershipId);
                setMembership(await Sdk.membershipsClient.membership(pod.membershipId));
            }
            setLoaded(true);
        })()
    }, [podId, setSelectedMembershipId]);

    return <>
        {loaded && pod && <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Assistant leader
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        You are agreeing to lead this pod until such time that you're unassigned as the assisted leader.
                    </div>
                    <div style={styles.LastInputWrapper}>
                        Enter a meeting link for the participants of this pod to join.
                    </div>
                </div>
                <div className={`columns small-8 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        <FormInputTextWithLabel
                            name="meetingLink"
                            placeholder={"e.g. Zoom"}
                            onChange={(e) => {
                                if (e.target.value !== '') {
                                    setError(undefined);
                                }
                                setMeetingLink(e.target.value)
                            }}
                            value={meetingLink}
                        />
                        {error && (<p style={styles.ErrorMessage}>{error}</p>)}
                    </div>
                </div>

                <div className={`columns medium-10 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/pod', {state: {...pod, membershipName: membership?.name}});
                    }}/>
                    <ButtonPrimary style={styles.RemoveButton} text="Confirm" type="button" onClick={async () => {
                        if (meetingLink === undefined || meetingLink === '') {
                            setError('Meeting link is required');
                            return;
                        }
                        await Sdk.podsClient.confirmAssistantLeader(pod.id, meetingLink);
                        if (pod) {
                            pod.scheduledMeeting.thirdPartyMeetingLink = meetingLink;
                        }
                        navigate('/pod', {state: {...pod, membershipName: membership?.name}});
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidth} text="Confirm" type="button" onClick={async () => {
                        if (meetingLink === undefined || meetingLink === '') {
                            setError('Meeting link is required');
                            return;
                        }
                        await Sdk.podsClient.confirmAssistantLeader(pod.id, meetingLink);
                        if (pod) {
                            pod.scheduledMeeting.thirdPartyMeetingLink = meetingLink;
                        }
                        navigate('/pod', {state: {...pod, membershipName: membership?.name}});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/pod', {state: {...pod, membershipName: membership?.name}});
                    }}/>
                </div>
            </div>
        </div>}
    </>;
}
