import 'react-datepicker/dist/react-datepicker.css'
import '../css/react-datepicker-overrides.css'
import {useLocation} from "react-router-dom";
import styles from "./NextTinySteps.module.css";
import React, {useEffect, useState} from "react";
import FormTextarea from "../components/FormTextarea";
import formUtils from "../../domain/utils/UseForm";
import validate from "./NextTinySteps.validation";
import ReactDatePicker from "react-datepicker";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import {DateTime} from "luxon";
import {NextTinyStep, NextTinyStepStatus} from "../../domain/sdk/domain/NextTinyStep";
import FormSelect from "../components/FormSelect";
import {calculateNextMeeting, ScheduledMeeting} from "../../domain/sdk/domain/ScheduledMeeting";
import {extract, useQuery} from "../../domain/utils/QueryUtils";
import FormInputText from "../components/FormInputText";
import Breadcrumb from "../components/Breadcrumb";


const FORMAT = "EEEE, dd LLLL yyyy hh:mm a";

export default function NextTinySteps() {
    let queryParams = useQuery();
    const {state} = useLocation();
    const {model} = (() => {
        const leaderIdParam = queryParams.get("l");
        const podIdParam = queryParams.get("p");
        const scheduledMeetingIdParam = queryParams.get("s");
        const nameParam = queryParams.get("n");
        const membershipIdParam = queryParams.get("m");
        return {
            model: {
                params: podIdParam !== undefined ?? false,
                id: podIdParam ? extract(podIdParam) : state.id,
                name: nameParam ? extract(nameParam) : state.name,
                leaderId: leaderIdParam ? extract(leaderIdParam) : state?.leaderId,
                scheduledMeetingId : scheduledMeetingIdParam ? extract(scheduledMeetingIdParam) : state?.scheduledMeeting.id,
                scheduledMeeting : state?.scheduledMeeting,
                membershipId : membershipIdParam ? extract(membershipIdParam) : state?.membershipId,
                ...state
            }
        }
    })();

    const {values, errors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);
    const [nextMeeting, setNextMeeting] = useState(null as DateTime | null);
    const [locale, setLocale] = useState('en-GB');
    const [commitmentDate, setCommitmentDate] = useState(null as Date | null);
    const [current, setCurrent] = useState(null as NextTinyStep | null);
    const [currentStatus, setCurrentStatus] = useState(NextTinyStepStatus.InProgress);
    const [previous, setPrevious] = useState([] as (NextTinyStep[]));
    const [loaded, setLoaded] = useState(false);
    const [scheduledMeeting, setScheduledMeeting]: any = useState(state?.scheduledMeeting as undefined | ScheduledMeeting);

    useEffect(() => {
        let podId = model.id;
        (async () => {
            let scheduledMeeting;
            if (model.params) {
                scheduledMeeting = await Sdk.scheduledMeetingsClient.scheduledMeeting(model.scheduledMeetingId);
            } else {
                scheduledMeeting = model.scheduledMeeting;
            }
            setScheduledMeeting(scheduledMeeting);

            let dateTime = calculateNextMeeting(scheduledMeeting);
            setLocale(dateTime.locale);
            setNextMeeting(dateTime);
            let myNextTinySteps = (await Sdk.nextTinyStepClient.myNextTinySteps())
                .filter(step => step.podId === podId)
                .sort((a, b) => b.created - a.created);
            if (myNextTinySteps.length > 0) {
                let currentStep = myNextTinySteps[0];
                setCurrent(currentStep);
                setCurrentStatus(currentStep.status);
                if (myNextTinySteps.length > 1) {
                    myNextTinySteps.splice(0, 1);
                    setPrevious(myNextTinySteps);
                }
            }
            setLoaded(true);
        })();
    }, [model?.id, model?.scheduledMeeting, model?.scheduledMeetingId, model?.params])

    async function onFormSubmit() {
        if (current) {
            if (commitmentDate === null) {
                errors.completionDate = "Commitment date is required";
                return;
            }
            if (commitmentDate.getTime() >= (nextMeeting?.toMillis() ?? 0)) {
                errors.completionDate = "Commitment date must be before the next scheduled meeting time";
                return;
            }
            if (commitmentDate.getTime() < new Date().getTime()) {
                errors.completionDate = "Commitment date must be in the future";
                return;
            }
            current.commitmentDate = commitmentDate.getTime();
            current.goal = values.goal;
            current.description = values.description;
            current.status = NextTinyStepStatus.InProgress;
            await Sdk.nextTinyStepClient.commit(current.id, current.goal, current.description, current.commitmentDate);
            setCurrentStatus(current.status);
        }
    }

    async function changeStatus(event: React.ChangeEvent<HTMLInputElement>) {
        if (current) {
            let updatedStatus = event.target.value as NextTinyStepStatus;
            current.status = updatedStatus;
            setCurrentStatus(updatedStatus);
            if (current.status === NextTinyStepStatus.NotSet) {
                current.commitmentDate = null;
                current.goal = '';
                values.goal = '';
                current.description = '';
                values.description = '';
                setCommitmentDate(null);
            }
            await Sdk.nextTinyStepClient.update(current.id, current);
            setCurrent(current);
        }
    }

    return <>
        {loaded && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Progress Pods", link: "/pods", state: model},
                        {text: model.name, link: "/pod", state: {...model, scheduledMeeting}},
                        {text: "Next Tiny Steps"},
                    ]}/>
                </div>
            </div>
            {current && current?.status === NextTinyStepStatus.NotSet && <>
                <div className={`row`}>
                    <div className={`columns small-12`}>
                        <div className={`${styles.InstructionsText}`}>
                            Once set the members of your Pod will be notified.
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={`row`}>
                        <div className={`columns small-12 medium-10 large-8 small-centered`}>
                            <div className={`${styles.InputWrapper}`}>
                                <FormInputText
                                    style={{resize: "none"}}
                                    name="goal"
                                    placeholder={"What goal/project are you working on?"}
                                    onChange={handleChange}
                                    value={values.goal || ''}/>
                                {errors.goal && (<p className={`${styles.ErrorMessage}`}>{errors.goal}</p>)}
                            </div>
                            <div className={`${styles.InputWrapper}`}>
                                <FormTextarea
                                    style={{resize: "none"}}
                                    name="description"
                                    placeholder={"What action are you going to take to move forward?"}
                                    onChange={handleChange}
                                    value={values.description || ''}/>
                                {errors.description && (<p className={`${styles.ErrorMessage}`}>{errors.description}</p>)}
                            </div>
                            <div className={`${styles.InputWrapper}`}>
                                <ReactDatePicker
                                    placeholderText={"Click to select date"}
                                    showTimeSelect
                                    dateFormat={locale === 'en-US' ? 'MM/dd/yyyy h:mm a' : 'dd/MM/yyyy h:mm a'}
                                    selected={commitmentDate}
                                    onChange={(date) => {
                                        if (date) {
                                            errors.completionDate = undefined;
                                            if (date.getTime() >= (nextMeeting?.toMillis() ?? 0)) {
                                                errors.completionDate = "Commitment date must be before the next scheduled meeting time";
                                            }
                                            if (date.getTime() < new Date().getTime()) {
                                                errors.completionDate = "Commitment date must be in the future";
                                            }
                                            setCommitmentDate(date ?? new Date());
                                        }
                                    }}
                                    popperPlacement="top-start"
                                />
                                {errors.completionDate && (<p className={`${styles.ErrorMessage}`}>{errors.completionDate}</p>)}
                            </div>
                        </div>
                        <div className={`columns small-12 medium-10 large-8 small-centered hide-for-small-only`}>
                            <ButtonPrimary className={styles.AlignRight} text="Confirm Next Tiny Step" type="submit"/>
                        </div>
                        <div className={`columns small-12 medium-10 large-8 small-centered show-for-small-only`}>
                            <ButtonPrimary className={styles.FullWidthButton} text="Confirm Next Tiny Step" type="submit"/>
                        </div>
                    </div>
                </form>
            </>}

            {current && current?.status !== NextTinyStepStatus.NotSet && <div className={`row`}>
                <div className={`columns small-12 medium-7`}>
                    <div className={`${styles.Subtitle}`}>
                        {"Goal"}
                    </div>
                    <div className={`${styles.Paragraph}`}>
                        {current.goal}
                    </div>
                    <div className={`${styles.Subtitle}`}>
                        {"Next Tiny Step"}
                    </div>
                    <div className={`${styles.Paragraph}`}>
                        {current.description}
                    </div>
                    <div className={`${styles.Subtitle}`}>
                        {"Commitment Date"}
                    </div>
                    <div className={`${styles.Paragraph}`}>
                        {current.commitmentDate !== null && DateTime.fromMillis(current.commitmentDate).toFormat(FORMAT)}
                    </div>
                </div>
                <div className={`columns small-12 medium-5`}>
                    <div className={`${styles.StatusDropdown}`}>
                        <FormSelect
                            label={"Progress Status"}
                            name="status"
                            onChange={changeStatus}
                            value={currentStatus}
                            labelClassName={`${styles.StatusDropdownLabel}`}
                            options={[
                                {text: 'Not Set', value: NextTinyStepStatus.NotSet},
                                {text: 'Scheduled', value: NextTinyStepStatus.InProgress},
                                {text: 'Done', value: NextTinyStepStatus.Done},
                                {text: 'Not Done', value: NextTinyStepStatus.NotDone}
                            ]}
                        />
                    </div>
                </div>
            </div>}
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <hr/>
                </div>
            </div>
            {previous && previous.length > 0 && <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper} ${styles.Arrow}`}>{"Previous"}</div>
                </div>
                {previous.map((previous: NextTinyStep, index) => {
                    return <div key={index}>
                        <div className={`columns small-9`}>
                            <div className={`${styles.Paragraph}`}>
                                <div>{previous.goal && previous.goal !== '' && `${previous.goal} > `} {previous.description}</div>
                            </div>
                            <div className={`${styles.Subparagraph}`}>
                                {previous.commitmentDate ? DateTime.fromMillis(previous.commitmentDate).toFormat(FORMAT) : "Commitment date not set"}
                            </div>
                        </div>
                        <div className={`columns small-3 text-right`}>
                            {previous.status === NextTinyStepStatus.NotDone && <div className={`${styles.DoneLabel}`}>Not done</div>}
                            {previous.status === NextTinyStepStatus.Done && <div className={`${styles.DoneLabel}`}>Done</div>}
                        </div>
                    </div>
                })}
            </div>}
        </div>}
    </>
}

