import React, {useContext, useEffect, useState} from 'react';
import styles from './Members.module.css';
import {Link, useLocation} from "react-router-dom";
import {Sdk} from "../../domain/sdk/Sdk";
import {Member} from "../../domain/sdk/domain/Member";
import Leader from "../svg/Leader";
import FormSelect from "../components/FormSelect";
import {MembersSortOrder} from "../../domain/sdk/domain/MembersSortOrder";
import {Pod} from "../../domain/sdk/domain/Pod";
import {DateTime} from "luxon";
import {Waitlist} from "../../domain/sdk/domain/Waitlist";
import {comparePodsByNameThenWeekdayThenTime, comparePodsBySizeThenWeekdayThenTime, comparePodsByWeekdayThenTime} from '../../domain/utils/PodUtils';
import Breadcrumb from "../components/Breadcrumb";
import {SecureContext} from "../state/SecureContext";
import {isMeetingPaused} from "../../domain/utils/MeetingUtils";

export default function Members() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const {state} = useLocation();
    const [members, setMembers] = useState([] as Member[]);
    const [membersWithNoPod, setMembersWithNoPod] = useState([] as Member[]);
    const [memberMap, setMemberMap] = useState(new Map<string, Member>());
    const [waitlistMap, setWaitlistMap] = useState(new Map<string, Waitlist>());
    const [pods, setPods] = useState([] as Pod[]);
    const [sortOrder, setSortOrder] = useState(MembersSortOrder.MeetingTime);
    const [activeUsers, setActiveUsers]: any = useState(new Map<String, Number>());

    useEffect(() => {
        setSelectedMembershipId(state.membershipId);
        (async () => {
            let memberResponse = await Sdk.membersClient.members(state.membershipId);
            let mapping = new Map<string, Member>();
            for (const member of memberResponse) {
                mapping.set(member.id, member);
            }
            let pods = await Sdk.podsClient.pods(state.membershipId);
            setWaitlistMap((await Sdk.podsClient.waitlists(state.membershipId)).reduce((map, waitlist) => {
                map.set(waitlist.id, waitlist);
                return map;
            }, new Map<string, Waitlist>()));
            let membersInPod = pods.flatMap(pod => pod.members).concat(pods.flatMap(pod => pod.leaderId));
            let membersNotInAPod = memberResponse.filter(member => !membersInPod.includes(member.id))
                .sort((a, b) => (a.leader === b.leader) ? 0 : a.leader ? -1 : 1);

            setActiveUsers(await Sdk.membershipsClient.lastActive(state.membershipId));
            setMemberMap(mapping);
            setMembers(memberResponse);
            setMembersWithNoPod(membersNotInAPod);
            setPods(pods.sort(comparePodsByWeekdayThenTime()));
        })();
    }, [state.membershipId, setSelectedMembershipId]);

    async function changeStatus(event: React.ChangeEvent<HTMLInputElement>) {
        let selectedOrder = event.target.value as MembersSortOrder;
        setSortOrder(selectedOrder);
        if (selectedOrder === MembersSortOrder.FirstName) {
            members.sort((a, b) => a.firstName.localeCompare(b.firstName));
        } else if (selectedOrder === MembersSortOrder.LastName) {
            members.sort((a, b) => a.lastName.localeCompare(b.lastName));
        } else if (selectedOrder === MembersSortOrder.JoinedAsc) {
            members.sort((a, b) => a.joined - b.joined);
        } else if (selectedOrder === MembersSortOrder.JoinedDesc) {
            members.sort((a, b) => b.joined - a.joined);
        } else if (selectedOrder === MembersSortOrder.LastActive) {
            members.sort((a, b) => {
                let aActive = activeUsers[a.id];
                let bActive = activeUsers[b.id];
                if (aActive && !bActive) {
                    return -1;
                } else if (bActive && !aActive) {
                    return 1;
                } else if (!bActive && !aActive) {
                    return -1;
                } else if (bActive === aActive) {
                    return 0;
                }
                return bActive > aActive ? 1 : -1;
            });
        } else if (selectedOrder === MembersSortOrder.MeetingTime) {
            pods.sort(comparePodsByWeekdayThenTime());
        } else if (selectedOrder === MembersSortOrder.PodSize) {
            pods.sort(comparePodsBySizeThenWeekdayThenTime());
        } else if (selectedOrder === MembersSortOrder.PodName) {
            pods.sort(comparePodsByNameThenWeekdayThenTime());
        }
    }

    function showLastActive(userId: string) {
        if (activeUsers[userId]) {
            return '(active ' + (() => {
                let diff = DateTime.now().diff(DateTime.fromSeconds(activeUsers[userId]), ["years", "months", "days", "hours", "minutes", "seconds"]);
                if (diff.years > 0) {
                    return `${diff.years} year${diff.years > 1 ? 's' : ''} ago`;
                } else if (diff.months > 0) {
                    return `${diff.months} month${diff.months > 1 ? 's' : ''} ago`;
                } else if (diff.days > 0) {
                    return `${diff.days} day${diff.days > 1 ? 's' : ''} ago`;
                } else if (diff.hours > 0) {
                    return `${diff.hours} hour${diff.hours > 1 ? 's' : ''} ago`;
                } else if (diff.minutes > 30) {
                    return `${diff.minutes} minute${diff.minutes > 1 ? 's' : ''} ago`;
                } else if (diff.minutes >= 0) {
                    return `just now`;
                }
            })() + ')';
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12 medium-8`}>
                    <Breadcrumb routes={[
                        {text: "Memberships", link: "/memberships"},
                        {text: state.membershipName ?? state.name},
                        {text: "Participants & Pods"}
                    ]}/>
                </div>
                <div className={`columns small-12 medium-4`}>
                    <div className={`${styles.TopPadding} hide-for-small-only`}></div>
                    <div className={`${styles.Dropdown}`}>
                        <FormSelect
                            label={"Sort by"}
                            name="sortBy"
                            onChange={changeStatus}
                            value={sortOrder}
                            labelClassName={`${styles.DropdownLabel}`}
                            options={[
                                {text: 'Meeting time', value: MembersSortOrder.MeetingTime},
                                {text: 'Pod size', value: MembersSortOrder.PodSize},
                                {text: 'Pod name', value: MembersSortOrder.PodName},
                                {text: 'Last active', value: MembersSortOrder.LastActive},
                                {text: 'Joined (earliest)', value: MembersSortOrder.JoinedAsc},
                                {text: 'Joined (latest)', value: MembersSortOrder.JoinedDesc},
                                {text: 'First name', value: MembersSortOrder.FirstName},
                                {text: 'Last name', value: MembersSortOrder.LastName}
                            ]}
                        />
                    </div>
                </div>
            </div>

            {(sortOrder === MembersSortOrder.MeetingTime || sortOrder === MembersSortOrder.PodSize || sortOrder === MembersSortOrder.PodName) && <>
                <div className={`row`}>
                    {pods.map((pod: Pod, i) => {
                        let leader = members.filter(member => member.leader && member.id === pod.leaderId);
                        let waitlist = waitlistMap.get(pod.id);
                        const isPaused = isMeetingPaused(pod.scheduledMeeting);
                        return <div className={`columns small-12 medium-6 large-4 end ${styles.PodDetails}`} key={i}>
                            <div style={{display: "flex"}}>
                                <Link to={"/pods/admin"} state={{...state, podId: pod.id}}>
                                    <div style={{textDecoration: "underline", color: "#1779ba", fontSize: 22, marginBottom: 10}}>{pod.name}</div>
                                </Link>
                            </div>
                            <div className={`${styles.PodMeeting}`}>
                                {<Link className={`${styles.EditLink}`} to={`/pod/admin`} state={pod}>
                                    {pod.scheduledMeeting.frequencyDescription}
                                </Link>}
                            </div>
                            {isPaused && <div style={{marginTop: 5, marginLeft: "auto", fontSize: 16, color: "#000"}}>PAUSED</div>}
                            <a href={pod.scheduledMeeting.thirdPartyMeetingLink as string} target={'_blank'} rel="noreferrer" className={`${styles.Link}`}>Join Online Meeting</a>
                            <div className={`${styles.PodSize}`}>
                                {pod.members.length} {pod.members.length === 1 ? "member" : "members"}, {pod.maxParticipants - pod.members.length} {(pod.maxParticipants - pod.members.length) > 1 ? "spaces" : "space"}
                            </div>
                            {leader.length > 0 &&
                                <Link className={`${styles.LeaderText}`} to={"/member"} state={{...state, member: leader[0]}}>
                                    <span style={{textDecoration: "underline", color: "#1779ba"}}>{leader[0].firstName} {leader[0].lastName}</span> <Leader
                                    className={`${styles.LeaderBadge}`}/>
                                </Link>
                            }
                            <div>{pod.members.map((userId, j) => {
                                let member = memberMap.get(userId);
                                return <div key={`${i}_${j}`}>{member && <div className={`${styles.NameText}`}>
                                    <Link className={`${styles.NameText}`} to={"/member"} state={{...state, member}}>
                                        <span style={{textDecoration: "underline", color: "#1779ba"}}>{member.firstName} {member.lastName}</span> {member.leader ?
                                        <Leader className={`${styles.LeaderBadge}`} colour={"#939393"}/> : ""} {showLastActive(userId)}
                                    </Link>
                                </div>}</div>
                            })}</div>
                            {waitlist && waitlist.members.length > 0 && <div>
                                <div className={`${styles.WaitlistTitleWrapper}`}>Waitlist</div>
                                {waitlist.members.map((userId, j) => {
                                    let member = memberMap.get(userId);
                                    return <div key={`waitlist_${i}_${j}`}>{member && <div className={`${styles.NameText}`}>
                                        <Link className={`${styles.NameText}`} to={"/member"} state={{...state, member}}>
                                            <span style={{textDecoration: "underline", color: "#1779ba"}}>{member.firstName} {member.lastName}</span> {member.leader ?
                                            <Leader className={`${styles.LeaderBadge}`} colour={"#939393"}/> : ""} {showLastActive(userId)}
                                        </Link>
                                    </div>}</div>
                                })}
                            </div>}
                        </div>
                    })}
                </div>
                {membersWithNoPod && membersWithNoPod.length > 0 && <>
                    <div className={`row`}>
                        <div className={`columns small-12 `}>
                            <div className={`${styles.SubTitleWrapper}`}>{"Not in a Pod"}</div>
                        </div>
                    </div>
                    <div className={`row`}>
                        <div className={`columns small-12`}>
                            {membersWithNoPod.map((member, index) => {
                                return <div key={index} className={`${styles.NameText}`}>
                                    <Link key={index} className={`${styles.NameText}`} to={"/member"} state={{...state, member}}>
                                        <span style={{textDecoration: "underline", color: "#1779ba"}}>{member.firstName} {member.lastName}</span> {member.leader &&
                                        <Leader className={`${styles.LeaderBadge}`} colour={"#939393"}/>} {showLastActive(member.id)}
                                    </Link>
                                </div>
                            })}
                        </div>
                    </div>
                </>}
            </>}

            {(sortOrder && [MembersSortOrder.FirstName, MembersSortOrder.LastName, MembersSortOrder.JoinedAsc, MembersSortOrder.JoinedDesc, MembersSortOrder.LastActive].includes(sortOrder)) && members &&
                <div className={`row`}>
                    {members.map((member, index) => {
                        return <div key={index} className={`columns small-12 ${styles.Participant}`}>
                            <Link className={`${styles.NameText}`} to={"/member"} state={{...state, member}}>
                                <span style={{textDecoration: "underline", color: "#1779ba"}}>{member.firstName} {member.lastName}</span> {member.leader &&
                                <Leader className={`${styles.LeaderBadge}`}/>} {showLastActive(member.id)}
                            </Link>
                            <div>
                                {[MembersSortOrder.JoinedAsc, MembersSortOrder.JoinedDesc].includes(sortOrder) && DateTime.fromMillis(member.joined).toLocaleString(DateTime.DATETIME_SHORT)}
                            </div>
                        </div>
                    })}
                </div>}
        </div>
    </>;
}
