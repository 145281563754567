import React from 'react';
import styles from './RemoveMember.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";

export default function AssignAssistantLeader() {
    const navigate = useNavigate();
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Assign as assistant leader
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Are you sure you want to assign {state.member.firstName} {state.member.lastName} as the assistant leader?
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        They will be asked to provide a meeting link, which will override the current one.
                    </div>
                </div>

                <div className={`columns medium-10 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/pod/participants', {state: state.pod});
                    }}/>
                    <ButtonPrimary style={styles.RemoveButton} text="Assign assistant leader" type="button" onClick={async () => {
                        await Sdk.podsClient.assignAssistantLeader(state.pod.id, state.member.userId);
                        navigate('/pod/participants', {state: state.pod});
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidth} text="Assign assistant leader" type="button" onClick={async () => {
                        await Sdk.podsClient.assignAssistantLeader(state.pod.id, state.member.userId);
                        navigate('/pod/participants', {state: state.pod});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/pod/participants', {state: state.pod});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
