import styles from './ButtonDanger.module.css'
import React, {ButtonHTMLAttributes} from 'react';

export interface ButtonDangerProps extends ButtonHTMLAttributes<any> {
    className?: string;
    text?: string;
    state?:any;
}

export default function ButtonDanger({className, text, ...other}: ButtonDangerProps) {
    return <button className={`${styles.ButtonDanger} ${className ? className : ''}`} {...other}>
        {text}
    </button>
}
