import React, {useEffect, useState} from 'react';
import styles from './AddMembership.module';
import ButtonPrimary from '../components/ButtonPrimary';
import {Link, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import formUtils from "../../domain/utils/UseForm";
import {validate} from "./AddMembership.validation";
import FormInputTextWithLabel from "../components/FormInputTextWithLabel";
import {Sdk} from "../../domain/sdk/Sdk";
import {ReactComponent as Open} from '../svg/Open.svg'
import {ReactComponent as Reset} from '../svg/Reset.svg'
import {DefaultLinks} from "../../domain/sdk/domain/Setting";
import {CustomButton} from "../../domain/sdk/domain/CustomButton";
import Checkbox from "../components/Checkbox";
import UploadImage from "../components/UploadImage";

export default function AddMembership() {
    let navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [defaults, setDefaults] = useState({} as DefaultLinks);
    const [ntsDisabled, setNtsDisabled] = useState(false);
    const [chatDisabled, setChatDisabled] = useState(false);
    const [selectPodOnSignupDisabled, setSelectPodOnSignupDisabled] = useState(false);
    const {values, setValues, errors, setErrors, handleChange, handleCheckboxChange, handleSubmit} = formUtils(onFormSubmit, validate);
    const [step, setStep] = useState(1);
    const [avatar, setAvatar] = useState(undefined as undefined | string);
    const MAX_STEPS = 4;

    useEffect(() => {
        (async () => {
            let settings = await Sdk.membershipsClient.defaultLinks();
            setDefaults(settings);
            setValues({
                leaderAgreementLink: settings.LeaderAgreementLink,
                participantAgreementLink: settings.ParticipantAgreementLink,
                leaderTrainingLink: settings.LeaderTrainingLink,
                participantOnboardingLink: settings.ParticipantOnboardingLink,
                shareWinLink: settings.ShareWinLink,
                maxParticipants: settings.MaxParticipants
            });
            setLoaded(true);
        })();
    }, [setValues]);

    async function onFormSubmit() {
        let buttons:CustomButton[] = [];
        if (values.customButton1Label && values.customButton1Label !== '') {
            buttons.push({
                formLabel: values.customButton1FormLabel,
                label: values.customButton1Label,
                url: values.customButton1Url,
                placement : 'VIEW_POD'
            });
        }
        if (values.customButton2Label && values.customButton2Label !== '') {
            buttons.push({
                formLabel: values.customButton2FormLabel,
                label: values.customButton2Label,
                url: values.customButton2Url,
                placement: 'PARTICIPANT_SIGNUP'
            });
        }
        const response = await Sdk.membershipsClient.register(Sdk.context.selectedBusiness.id, values.name, values.url, values.communityUrl, values.supportEmail, {
            LeaderAgreementLink: values.leaderAgreementLink,
            ParticipantAgreementLink: values.participantAgreementLink,
            LeaderTrainingLink: values.leaderTrainingLink,
            ParticipantOnboardingLink: values.participantOnboardingLink,
            ShareWinLink: values.shareWinLink,
            MaxParticipants: values.maxParticipants,
        }, {
            NextTinyStep: ntsDisabled,
            Chat: chatDisabled,
            SelectPodOnSignup: selectPodOnSignupDisabled
        }, buttons);
        if (response.status === 200) {
            if (avatar) {
                let avatarResponse = await Sdk.logoClient.upload(response.data, avatar);
                if (avatarResponse.status === 200) {
                    navigate('/memberships');
                }
            }
            navigate('/memberships');
        }
    }

    return <>
        {loaded && <div>
            <div className={`row`}>
                <form style={styles.InviteForm} onSubmit={handleSubmit}>
                    <div className={`columns small-12`}>
                        <div style={styles.TitleWrapper}>
                            Add membership (Step {step} of {MAX_STEPS})
                        </div>
                    </div>

                    {step === 1 && <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Membership Details
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership name" name="name" placeholder={"e.g. Smart Moves"} onChange={handleChange} value={values.name || ''}/>
                            {errors.name && (<p style={styles.ErrorMessage}>{errors.name}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership support email" name="supportEmail" placeholder={"Email"} onChange={handleChange}
                                                    value={values.supportEmail || ''}/>
                            {errors.supportEmail && (<p style={styles.ErrorMessage}>{errors.supportEmail}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership website URL (Optional)" name="url" placeholder={"Web link"} onChange={handleChange}
                                                    value={values.url || ''}/>
                            {errors.url && (<p style={styles.ErrorMessage}>{errors.url}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <FormInputTextWithLabel label="Membership community URL (Optional)" name="communityUrl" placeholder={"Web link, e.g. Facebook"} onChange={handleChange}
                                                    value={values.communityUrl || ''}/>
                            {errors.communityUrl && (<p style={styles.ErrorMessage}>{errors.communityUrl}</p>)}
                        </div>
                        <div style={styles.InputWrapper}>
                            <Checkbox  name="ntsEnabled" label="Enable Next Tiny Step module"
                                       labelTextStyle={styles.FeatureCheckbox}
                                       onChange={handleCheckboxChange}
                                       onClick={() => setNtsDisabled(!ntsDisabled)}
                                       checked={!ntsDisabled} >
                            </Checkbox>
                        </div>
                        <div style={styles.InputWrapper}>
                            <Checkbox  name="chatDisabled" label="Enable chat"
                                       labelTextStyle={styles.FeatureCheckbox}
                                       onChange={handleCheckboxChange}
                                       onClick={() => setChatDisabled(!chatDisabled)}
                                       checked={!chatDisabled} >
                            </Checkbox>
                        </div>
                        <div style={styles.InputWrapper}>
                            <Checkbox  name="selectPodOnSignupDisabled" label="Allow members to select a pod when signing up"
                                       labelTextStyle={styles.FeatureCheckbox}
                                       onChange={handleCheckboxChange}
                                       onClick={() => setSelectPodOnSignupDisabled(!selectPodOnSignupDisabled)}
                                       checked={!selectPodOnSignupDisabled} >
                            </Checkbox>
                        </div>
                    </div>}

                    {step === 2 && <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Onboarding & training links
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to leader agreement" name="leaderAgreementLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.leaderAgreementLink || ''}/>
                                {errors.leaderAgreementLink && (<p style={styles.ErrorMessage}>{errors.leaderAgreementLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.leaderAgreementLink} rel="noreferrer"><Open/></a>
                                <Link to={""} onClick={() => values.leaderAgreementLink = defaults.LeaderAgreementLink}><Reset/></Link>
                            </div>
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to participant agreement" name="participantAgreementLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.participantAgreementLink || ''}/>
                                {errors.participantAgreementLink && (<p style={styles.ErrorMessage}>{errors.participantAgreementLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.participantAgreementLink} rel="noreferrer"><Open/></a>
                                <Link to={""} onClick={() => values.participantAgreementLink = defaults.ParticipantAgreementLink}><Reset/></Link>
                            </div>
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to leader training" name="leaderTrainingLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.leaderTrainingLink || ''}/>
                                {errors.leaderTrainingLink && (<p style={styles.ErrorMessage}>{errors.leaderTrainingLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.leaderTrainingLink} rel="noreferrer"><Open/></a>
                                <Link to={""} onClick={() => values.leaderTrainingLink = defaults.LeaderTrainingLink}><Reset/></Link>
                            </div>
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Link to participant onboarding" name="participantOnboardingLink" placeholder={"Web link"} onChange={handleChange}
                                                        value={values.participantOnboardingLink || ''}/>
                                {errors.participantOnboardingLink && (<p style={styles.ErrorMessage}>{errors.participantOnboardingLink}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <a style={styles.IconLink} target="_blank" href={values.participantOnboardingLink} rel="noreferrer"><Open/></a>
                                <Link to={""} onClick={() => values.participantOnboardingLink = defaults.ParticipantOnboardingLink}><Reset/></Link>
                            </div>
                        </div>

                        <div style={styles.TextBlock}>
                            Add a custom link to the sign up page (optional)
                        </div>
                        <div className={`show-for-small-only`}>
                            <div>
                                <FormInputTextWithLabel
                                    label="Link text"
                                    name="customButton2Label"
                                    placeholder={"Link text"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton2Label || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Link"
                                    name="customButton2Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton2Url || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton2FormLabel"
                                    placeholder={"Appears above the link"}
                                    onChange={handleChange}
                                    value={values.customButton2FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div className={`hide-for-small-only`} style={styles.InputWithDefaultsWrapper}>
                            <div style={{flex: 2, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Link text"
                                    name="customButton2Label"
                                    placeholder={"Link text"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton2Label || ''}
                                />
                            </div>
                            <div style={{flex: 4, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Link"
                                    name="customButton2Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton2Url || ''}
                                />
                            </div>
                            <div style={{flex: 3}}>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton2FormLabel"
                                    placeholder={"Appears above the link"}
                                    onChange={handleChange}
                                    value={values.customButton2FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div>
                            {errors.customButton2 && (<p style={styles.ErrorMessage}>{errors.customButton2}</p>)}
                        </div>
                    </div>}

                    {step === 3 && <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Pod configuration
                        </div>
                        <div style={styles.InputWithDefaultsWrapper}>
                            <div style={styles.LinkInputWrapper}>
                                <FormInputTextWithLabel label="Maximum participants per pod" name="maxParticipants" placeholder={"8"} onChange={handleChange}
                                                        value={values.maxParticipants || ''}/>
                                {errors.maxParticipants && (<p style={styles.ErrorMessage}>{errors.maxParticipants}</p>)}
                            </div>
                            <div style={styles.InputIcons}>
                                <Link to={""} onClick={() => values.maxParticipants = defaults.MaxParticipants}><Reset/></Link>
                            </div>
                        </div>


                        <div style={styles.TextBlock}>
                            Add a custom button to each Progress Pod. (optional)
                        </div>
                        <div className={`show-for-small-only`}>
                            <div>
                                <FormInputTextWithLabel
                                    label="Button text"
                                    name="customButton1Label"
                                    placeholder={"Click me"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton1Label || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Button link"
                                    name="customButton1Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton1Url || ''}
                                />
                            </div>
                            <div>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton1FormLabel"
                                    placeholder={"Appears above the button"}
                                    onChange={handleChange}
                                    value={values.customButton1FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div className={`hide-for-small-only`} style={styles.InputWithDefaultsWrapper}>
                            <div style={{flex: 2, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Button text"
                                    name="customButton1Label"
                                    placeholder={"Click me"}
                                    onChange={(e) => {
                                        if (e.target.value.length > 25) {
                                            e.target.value = e.target.value.substring(0, 24);
                                            return;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.customButton1Label || ''}
                                />
                            </div>
                            <div style={{flex: 4, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Button link"
                                    name="customButton1Url"
                                    placeholder={"Web link"}
                                    onChange={handleChange}
                                    value={values.customButton1Url || ''}
                                />
                            </div>
                            <div style={{flex: 3, paddingRight: 10}}>
                                <FormInputTextWithLabel
                                    label="Optional label"
                                    name="customButton1FormLabel"
                                    placeholder={"Appears above the button"}
                                    onChange={handleChange}
                                    value={values.customButton1FormLabel || ''}
                                />
                            </div>
                        </div>
                        <div>
                            {errors.customButton1 && (<p style={styles.ErrorMessage}>{errors.customButton1}</p>)}
                        </div>
                    </div>}

                    {step === MAX_STEPS && <div className={`columns small-12`}>
                        <div style={styles.SubTitleWrapper}>
                            Upload a logo
                        </div>
                        <div style={styles.TextBlock}>
                            Optionally upload a membership logo. This will appear on the sign up page.
                        </div>
                        <div className={`show-for-small-only`} style={styles.TextBlock}>
                            Crop available on desktop.
                        </div>
                        <div style={{textAlign:"center", margin: 30}}>
                            <UploadImage
                                onImageChange={async (data) => {
                                    setAvatar(data);
                                }}
                            />
                        </div>
                    </div>}

                    {/* mobile */}
                    <div className={`columns small-12 show-for-small-only`}>
                        {step < MAX_STEPS && <>
                            <ButtonPrimary style={styles.FullWidth} text="Next" type="button" onClick={async () => {
                                let errors = validate(values);
                                if (Object.keys(errors).length > 0) {
                                    setErrors(errors);
                                } else {
                                    setErrors({});
                                    setStep(step + 1);
                                }
                            }}/>
                        </>}
                        {step === MAX_STEPS && <>
                            <ButtonPrimary style={styles.FullWidth} text="Create membership" type="submit"/>
                        </>}
                        {step === 1 && <>
                            <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                                navigate('/memberships')
                            }}/>
                        </>}
                        {step > 1 && step <= MAX_STEPS && <>
                            <ButtonCancel style={styles.FullWidth} text="Back" type="button" onClick={async () => {
                                let errors = validate(values);
                                if (Object.keys(errors).length > 0) {
                                    setErrors(errors);
                                } else {
                                    setStep(step - 1);
                                }
                            }}/>
                        </>}
                    </div>
                    {/* desktop */}
                    <div className={`columns medium-9 large-8 float-right hide-for-small-only text-right`} style={{paddingTop: 10}}>
                        {step === 1 && <>
                            <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                                navigate('/memberships')
                            }}/>
                        </>}
                        {step > 1 && step <= MAX_STEPS && <>
                            <ButtonCancel style={styles.CancelButton} text="Back" type="button" onClick={async () => {
                                let errors = validate(values);
                                if (Object.keys(errors).length > 0) {
                                    setErrors(errors);
                                } else {
                                    setStep(step - 1);
                                }
                            }}/>
                        </>}
                        {step < MAX_STEPS && <>
                            <ButtonPrimary style={styles.AssignButton} text="Next" type="button" onClick={async () => {
                                let errors = validate(values);
                                if (Object.keys(errors).length > 0) {
                                    setErrors(errors);
                                } else {
                                    setErrors({});
                                    setStep(step + 1);
                                }
                            }}/>
                        </>}
                        {step === MAX_STEPS && <>
                            <ButtonPrimary style={styles.AssignButton} text="Create membership" type="submit"/>
                        </>}
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        {errors.other && (<p style={styles.ErrorMessage}>{errors.other}</p>)}
                    </div>
                </form>
            </div>
        </div>}
    </>;
}
