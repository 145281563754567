import React from 'react';
import styles from './InviteAdminPrompt.module.css';
import ButtonPrimary from '../components/ButtonPrimary';
import {useNavigate} from "react-router-dom";
import FormInputText from "../components/FormInputText";
import ButtonCancel from "../components/ButtonCancel";
import formUtils from "../../domain/utils/UseForm";
import validate from "./InviteAdminPrompt.validation";
import {Sdk} from "../../domain/sdk/Sdk";
import {rootNavigation} from "../../domain/utils/NavigationUtils";
import {ADMINS} from "./MySettings";

export default function InviteAdminPrompt() {
    let navigate = useNavigate();
    const {values, errors, setErrors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    async function onFormSubmit() {
        let selectedBusiness = Sdk.context.selectedBusiness.id;
        let response = await Sdk.businessClient.inviteAdmin(selectedBusiness, values.name, values.email);
        if (response && response.status === 200) {
            navigate(rootNavigation())
        } else {
            setErrors({other: 'Failed to invite admin'})
        }
    }

    async function skip() {
        navigate('/my-settings', {state : {panel:ADMINS}});
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Invite Administrator
                    </div>
                    <div className={`${styles.InviteAdminText}`}>
                        Would you like to to invite an admin to manage the membership on your behalf?
                    </div>
                </div>

                <form className={`${styles.InviteForm}`} onSubmit={handleSubmit}>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Name" name="name" onChange={handleChange} value={values.name || ''}/>
                            {errors.name && (<p className={`${styles.ErrorMessage}`}>{errors.name}</p>)}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputText placeholder="Email" name="email" onChange={handleChange} value={values.email || ''}/>
                            {errors.email && (<p className={`${styles.ErrorMessage}`}>{errors.email}</p>)}
                        </div>
                    </div>
                    <div className={`columns small-12 small-centered show-for-small-only`}>
                        <ButtonPrimary className={`${styles.FullWidth}`} text="Invite Administrator" type="submit"/>
                        <ButtonCancel className={`${styles.FullWidth}`} text="Cancel" type="button" onClick={skip}/>
                    </div>
                    <div className={`columns medium-9 large-6 medium-centered hide-for-small-only`}>
                        <ButtonCancel className={`${styles.CancelButton}`} text="Cancel" type="button" onClick={skip}/>
                        <ButtonPrimary className={`${styles.AssignButton}`} text="Invite Administrator" type="submit"/>
                    </div>
                    <div className={`columns medium-9 large-6 small-centered`}>
                        {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}
                    </div>
                </form>
            </div>
        </div>
    </>;
}
