import React, {HTMLAttributes, useRef, useState} from "react";
import AvatarEditor from "react-avatar-editor";
import ButtonCancel from "./ButtonCancel";
import ButtonSecondary from "./ButtonSecondary";

export interface UploadImageProps extends HTMLAttributes<any> {
    label?: string;
    showRemoveButton?:boolean;
    onImageChange: (data?: string) => void;
}

export default function UploadImage({label, showRemoveButton = true, onImageChange, ...other}: UploadImageProps) {
    const editorRef = useRef(null as null | AvatarEditor);
    const inputRef = useRef(null as null | HTMLInputElement);
    const [imageSource, setImageSource] = useState(undefined as undefined | string | File);
    const [error, setError] = useState(undefined as undefined | string);

    return <div {...other}>
        <figure>
            {imageSource && imageSource !== '' && <AvatarEditor
                ref={editorRef}
                image={imageSource}
                width={200}
                height={200}
                border={5}
                borderRadius={0}
                color={[255, 255, 255, 0.7]}
                scale={1}
                rotate={0}
                onImageReady={() => {
                    try {
                        if (editorRef?.current) {
                            onImageChange(editorRef.current.getImage().toDataURL() as string);
                        }
                    } catch (e) {
                    }
                }}
                onImageChange={() => {
                    try {
                        if (editorRef?.current) {
                            onImageChange(editorRef.current.getImage().toDataURL() as string);
                        }
                    } catch (e) {
                    }
                }}
                onLoadFailure={() => {
                    setError(`Oops! We can't upload that image`);
                    setTimeout(()=>{setError(undefined)}, 2000);
                }}
            />}
        </figure>
        <label>
            <input
                ref={inputRef}
                type="file"
                onChange={(event) => {
                    if (event?.target?.files) {
                        setImageSource(event.target.files[0]);
                    }
                }}/>
        </label>
        <div>
            {showRemoveButton && imageSource && <ButtonCancel style={{width: 100, marginRight: 10}} type={"button"} text={"Remove"} onClick={()=> {
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
                setImageSource(undefined);
            }} />}
            <ButtonSecondary type={"button"} text={"Choose image"} onClick={()=> {inputRef.current?.click()}} />
        </div>
        {error && <div style={{}}>{error}</div>}
    </div>
}