import React, {useEffect, useState} from 'react';
import formUtils from '../../domain/utils/UseForm';
import styles from './SignUpLeader.module.css';
import validate from './SignUpLeader.validation';
import FormInputText from '../components/FormInputText';
import ButtonPrimary from '../components/ButtonPrimary';
import ButtonCancel from "../components/ButtonCancel";
import FormInputPassword from '../components/FormInputPassword';
import {useNavigate, useSearchParams} from "react-router-dom";
import Checkbox from "../components/Checkbox";
import {Sdk} from "../../domain/sdk/Sdk";
import {MembershipInfo} from "../../domain/sdk/domain/Membership";
import MembershipLogo from "../components/CustomLogo";

export default function SignUpLeader() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const [agreeToLeaderTraining, setAgreeToLeaderTraining] = useState(false);
    const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);
    const [agreeToLeaderAgreement, setAgreeToLeaderAgreement] = useState(false);
    const [agreeToLeaderAgreementLink, setAgreeToLeaderAgreementLink] = useState(null as string | null);
    const [membershipInfo, setMembershipInfo] = useState(null as MembershipInfo | null);
    const {values, errors, setErrors, handleChange, handleCheckboxChange, handleSubmit} = formUtils(onFormSubmit, validate);
    const inviteCode = searchParams.get("invite");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (inviteCode) {
            (async () => {
                setMembershipInfo(await Sdk.membershipsClient.membershipInfo(inviteCode));
                setAgreeToLeaderAgreementLink(await Sdk.membershipsClient.membershipSetting(inviteCode, 'LeaderAgreementLink'));
                setIsLoading(false);
            })();

        }
    }, [inviteCode]);


    async function onClickAgreeToLeaderTraining() {
        setAgreeToLeaderTraining(!agreeToLeaderTraining)
    }

    async function onClickAgreeToTermsAndConditions() {
        setAgreeToTermsAndConditions(!agreeToTermsAndConditions)
    }

    async function onClickAgreeToLeaderAgreement() {
        setAgreeToLeaderAgreement(!agreeToLeaderAgreement)
    }

    function hasAgreeToLeaderAgreementLink() {
        return agreeToLeaderAgreementLink !== null && agreeToLeaderAgreementLink !== '';
    }

    async function onFormSubmit() {
        if (hasAgreeToLeaderAgreementLink() && !agreeToLeaderAgreement) {
            setErrors({agreeToTerms : "Please agree to terms"});
            return;
        }
        if (!agreeToLeaderTraining || !agreeToTermsAndConditions) {
            setErrors({agreeToTerms : "Please agree to terms"});
        } else if (inviteCode === null) {
            setErrors({agreeToTerms : "Missing code"});
        } else {
            let response = await Sdk.membersClient.register(inviteCode, values.email, values.firstName, values.lastName, values.password);
            if (response && response.status === 200) {
                navigate('/sign-up/check-inbox');
            }
        }
    }

    return <>
        {!isLoading && <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.SignUpForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.BusinessName}`}>
                            {membershipInfo?.businessName}
                        </div>
                        <div className={`${styles.MembershipName}`}>
                            {membershipInfo?.membershipName}
                        </div>
                        <div className={`${styles.TitleWrapper}`}>
                            Leader sign up
                        </div>
                        <div className={`${styles.AvatarWrapper}`}>
                            <MembershipLogo membershipId={membershipInfo?.id} />
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Email" name="email"
                                           onChange={handleChange}
                                           value={values.email || ''}/>
                            {errors.email && (
                                <p className={`${styles.ErrorMessage}`}>{errors.email}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="First name" name="firstName"
                                           onChange={handleChange}
                                           value={values.firstName || ''}/>
                            {errors.firstName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.firstName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Last name" name="lastName"
                                           onChange={handleChange}
                                           value={values.lastName || ''}/>
                            {errors.lastName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.lastName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="password"
                                               onChange={handleChange}
                                               value={values.password || ''}/>
                            {errors.password && (
                                <p className={`${styles.ErrorMessage}`}>{errors.password}</p>
                            )}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputPassword name="confirm"
                                               onChange={handleChange}
                                               placeholder={"Retype password"}
                                               value={values.confirm || ''}/>
                            {errors.confirm && (
                                <p className={`${styles.ErrorMessage}`}>{errors.confirm}</p>
                            )}
                        </div>
                        <div className={`${styles.CheckboxWrapper}`}>
                            <Checkbox className={styles.Checkbox} name="agreeToLeaderTraining" label="I AGREE TO COMPLETE THE LEADER TRAINING" onChange={handleCheckboxChange} checked={agreeToLeaderTraining} onClick={onClickAgreeToLeaderTraining} />
                        </div>
                        <div className={`${styles.CheckboxWrapper}`}>
                            <Checkbox className={styles.Checkbox} name="agreeToTermsAndConditions" label="I AGREE TO THE" onChange={handleCheckboxChange} checked={agreeToTermsAndConditions} onClick={onClickAgreeToTermsAndConditions} >
                                <a href="leader/terms" target="_blank" rel="noreferrer" className={styles.Underline}>TERMS AND CONDITIONS</a>
                            </Checkbox>
                        </div>
                        {hasAgreeToLeaderAgreementLink() && <div className={`${styles.CheckboxWrapperLast}`}>
                            <Checkbox className={styles.Checkbox} name="agreeToLeaderAgreement" label="I AGREE TO THE" onChange={handleCheckboxChange} checked={agreeToLeaderAgreement} onClick={onClickAgreeToLeaderAgreement} >
                                <a href={agreeToLeaderAgreementLink ?? ''} rel="noreferrer" className={styles.Underline} target="_blank" >LEADER AGREEMENT</a>
                            </Checkbox>
                            {errors.agreeToTerms && (
                                <p className={`${styles.ErrorMessage}`}>{errors.agreeToTerms}</p>
                            )}
                        </div>}
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Create account & join" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={() => {
                                navigate('/')
                            }}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>}
    </>;
}
