import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import styles from "./PodAdministration.module.css";
import {Pod} from "../../domain/sdk/domain/Pod";
import Leader from "../svg/Leader";
import {Member} from "../../domain/sdk/domain/Member";
import {Sdk} from "../../domain/sdk/Sdk";
import Breadcrumb from "../components/Breadcrumb";
import {SecureContext} from "../state/SecureContext";

export interface MemberAdmin extends Member {
    isLeadingAPod?: string;
}

export default function PodAdministration() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const {state} = useLocation();
    const [pod, setPod] = useState(undefined as Pod | undefined);
    const [leader, setLeader] = useState(undefined as undefined | Member);
    const [memberMap, setMemberMap] = useState(new Map<string, MemberAdmin>());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSelectedMembershipId(state.membershipId);
        (async () => {
            let podsForMembership = await Sdk.podsClient.pods(state.membershipId);
            let pod = podsForMembership.filter(pod => pod.id === state.podId)[0];
            setPod(pod);
            let memberResponse = await Sdk.membersClient.members(state.membershipId);
            let leaderFromMembers = memberResponse.filter(member => member.leader && member.id === pod?.leaderId);
            if (leaderFromMembers.length === 1) {
                setLeader(leaderFromMembers[0]);
            }

            let memberMapping = new Map<string, MemberAdmin>();
            setMemberMap(memberMapping);
            for (const member of memberResponse) {
                memberMapping.set(member.id, member);
            }
            memberMapping.forEach((member, key) => {
                let otherPods = podsForMembership.filter(pod => pod.leaderId === member.id);
                if (otherPods.length > 0) {
                    member.isLeadingAPod = otherPods[0].name;
                }
            })

            setLoading(false);
        })();
    }, [state.podId, state.membershipId, setSelectedMembershipId])

    return <>
        {!loading && pod && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Memberships", link: "/memberships"},
                        {text: "Participants & Pods", link: "/members", state: state},
                        {text: pod.name},
                    ]}/>
                </div>
            </div>

            <div className={`${styles.Panel}`}>
                <div className={`row`}>
                    <div className={`columns small-12`}>
                        {leader && <div className={`${styles.NameText}`}>
                            {leader.firstName} {leader.lastName} <Leader className={`${styles.LeaderBadge}`}/>&nbsp;&nbsp;
                            {pod.members.length === 0 && <Link className={`${styles.Link}`} to={"/pods/admin/remove-leader"} state={{...state, member: leader, pod}}>
                                Remove & delete Pod
                            </Link>}
                        </div>}
                        <div>
                            {pod.members.map((userId, j) => {
                                let member = memberMap.get(userId);
                                return <div key={`user_${j}`}>{member && <div className={`${styles.NameText}`}>
                                    {member.firstName} {member.lastName}&nbsp;&nbsp;
                                    <Link className={`${styles.Link}`} to={"/pods/admin/remove"} state={{...state, member, pod}}>
                                        Remove
                                    </Link>&nbsp;&nbsp;
                                    {!member.isLeadingAPod && <Link className={`${styles.Link}`} to={"/leader/switch"} state={{...state, member}}>
                                        Make leader
                                    </Link>}
                                    {member.isLeadingAPod && <>(Leader of '{member.isLeadingAPod}' Pod)</>}
                                </div>}</div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>;
}