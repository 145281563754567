import React from 'react';
import styles from './ConfirmSwitchLeader.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";

export default function ConfirmSwitchLeader() {
    const {state} = useLocation();
    const navigate = useNavigate();
    let proposedLeader = `${state.member.firstName} ${state.member.lastName}`;

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Confirm switch Pod leader
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        The existing leader will become a participant and {proposedLeader} will become the new leader of this Pod.
                    </div>
                    <div style={styles.InputWrapper}>
                        If the participant is not already a leader they will be assigned.
                    </div>
                    <div style={styles.LastInputWrapper}>
                        An email will be sent to {proposedLeader} to confirm they are happy to lead the Pod and setup a meeting link.
                    </div>
                </div>

                <div className={`columns medium-9 large-12 medium-centered show-for-small-only`}>
                    <ButtonPrimary style={styles.FullWidthDelete} text="Yes. Send request" type="button" onClick={async () => {
                        await Sdk.podsClient.transferLeaderTo(state.podId, state.member.id);
                        navigate('/pods/admin', {state});
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/pods/admin', {state});
                    }}/>
                </div>

                <div className={`columns medium-9 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/pods/admin', {state});
                    }}/>
                    <ButtonPrimary style={styles.DeleteButton} text="Yes. Send request" type="button" onClick={async () => {
                        await Sdk.podsClient.transferLeaderTo(state.podId, state.member.id);
                        navigate('/pods/admin', {state});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
