import React, {FormEventHandler, useEffect, useState} from 'react';

const useForm = (callback:Function, validate?:Function) => {
    const [values, setValues] = useState({} as any);
    const [errors, setErrors] = useState({} as any);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit:FormEventHandler<HTMLFormElement> = (event: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        setIsSubmitting(true);
        if (validate) {
            setErrors(validate(values));
        } else {
            callback();
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
            setIsSubmitting(false);
        }
    }, [callback, isSubmitting, errors]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setValues((values: any) => ({...values, [event.target.name]: event.target.value}));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setValues((values: any) => ({...values, [event.target.name]: event.target.checked}));
    }

    const setInitialValues = (values:Event) => {
        setValues(values);
    };

    return {
        handleChange,
        handleCheckboxChange,
        handleSubmit,
        setInitialValues,
        values,
        setValues,
        errors,
        setErrors
    }
};

export default useForm;