export default function validate(values:any) {
    let errors:any = {};
    if (!values.zoomApiKey || values.zoomApiKey === '') {
        errors.zoomApiKey = "Zoom key is required";
    }
    if (!values.zoomApiSecret || values.zoomApiSecret === '') {
        errors.zoomApiSecret = "Zoom secret is required";
    }
    if (!values.zoomUserId || values.zoomUserId === '') {
        errors.zoomUserId = "Zoom user ID is required";
    }
    if (!new RegExp(/\S+@\S+\.\S+/).test(values.zoomUserId)) {
        errors.zoomUserId = "Zoom user email is invalid";
    }
    return errors;
};