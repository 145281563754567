import React from 'react';
import styles from './ForgotPassword.module.css';
import validate from './ForgotPassword.validation';
import formUtils from '../../domain/utils/UseForm';
import FormInputText from '../components/FormInputText';
import ButtonPrimary from '../components/ButtonPrimary';
import {Sdk} from '../../domain/sdk/Sdk';
import {useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";

export default function ForgotPassword() {
    let navigate = useNavigate();
    const {values, errors, setErrors, handleChange, handleSubmit} = formUtils(onFormSubmit, validate);

    async function onFormSubmit() {
        let response = await Sdk.userClient.forgottenPassword(values.email);
        if (response.status === 200) {
            navigate('check-inbox');
        } else {
            setErrors({
                other: 'Something went wrong'
            })
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.UserForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            Forgotten Password
                        </div>
                        <div className={`${styles.EmailWrapper}`}>
                            <FormInputText placeholder="Email" name="email" onChange={handleChange} value={values.email || ''}/>
                            {errors.email && (<p className={`${styles.ErrorMessage}`}>{errors.email}</p>)}
                        </div>
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Reset Password" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={() => {
                                navigate('/login', {state: values})
                            }}/>
                        </div>
                        {errors.other && (<p className={`${styles.ErrorMessage}`}>{errors.other}</p>)}
                    </form>
                </div>
            </div>
        </div>
    </>;
}
