import {SdkConfig} from "./SdkConfig";
import {httpDelete, httpGet, httpPost, httpPut} from "../utils/HttpClient";
import {Membership, MembershipInfo, MyMembership} from "./domain/Membership";
import {MemberInvite} from "./domain/MemberInvite";
import {DefaultLinks, Setting} from "./domain/Setting";
import {CustomButton} from "./domain/CustomButton";
import {FeatureToggle} from "./domain/FeatureToggle";

export class MembershipsClient {
    private readonly businessesUrl: string;
    private readonly membershipsUrl: string;
    private readonly platformSettings: string;

    constructor(config: SdkConfig) {
        this.businessesUrl = `${config.apiUrl}/v1/businesses`;
        this.membershipsUrl = `${config.apiUrl}/v1/memberships`;
        this.platformSettings = `${config.apiUrl}/v1/platform-settings`;
    }

    async register(businessId: string, name: string, url: string, communityUrl:string, supportEmail:string, settings:any, features: any, customButtons:CustomButton[]) {
        return await httpPost(`${this.businessesUrl}/${businessId}/memberships`, {name, url, communityUrl, supportEmail, settings, features, customButtons});
    }

    async update(membershipId: string, name: string, url: string, communityUrl:string, supportEmail:string, settings:any, features: any, customButtons:CustomButton[]) {
        return await httpPut(`${this.membershipsUrl}/${membershipId}`, {name, url, communityUrl, supportEmail, settings, features, customButtons});
    }

    async membership(membershipId: string): Promise<Membership | undefined> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}`);
        if (response.status === 200) {
            return response.data as Membership;
        }
    }

    async memberships(businessId: string): Promise<Membership[]> {
        let response = await httpGet(`${this.businessesUrl}/${businessId}/memberships`);
        if (response.status === 200) {
            return response.data.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    async myMemberships(businessId:string): Promise<MyMembership[]> {
        let response = await httpGet(`${this.businessesUrl}/${businessId}/my-memberships`);
        if (response.status === 200) {
            return response.data.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        return [];
    }

    downloadActiveCampaignCsv(membershipId:string): void {
        window.open(`${this.membershipsUrl}/${membershipId}/active-campaign/csv?authToken=${localStorage.getItem('access_token')}`)
    }

    async defaultLinks(): Promise<DefaultLinks> {
        let response = await httpGet(this.platformSettings);
        let existing = (response.data as Setting[]).reduce(function(map:any, obj) {
            map[obj.name] = obj.value;
            return map;
        }, {});
        return {
            LeaderAgreementLink : existing.LeaderAgreementLink,
            ParticipantAgreementLink : existing.ParticipantAgreementLink,
            LeaderTrainingLink : existing.LeaderTrainingLink,
            ParticipantOnboardingLink : existing.ParticipantOnboardingLink,
            AdminOnboardingLink : existing.AdminOnboardingLink,
            SupportLink : existing.SupportLink,
            MinParticipants : existing.MinParticipants,
            MaxParticipants : existing.MaxParticipants,
        }
    }

    async membershipSettings(membershipId:string): Promise<Map<string, string>> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/settings`);
        let settings = new Map<string, string>();
        for (const setting of response.data as Setting[]) {
            settings.set(setting.name, setting.value);
        }
        return settings;
    }

    async membershipFeatures(membershipId:string): Promise<Map<string, boolean>> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/features`);
        let features = new Map<string, boolean>();
        for (const setting of response.data as FeatureToggle[]) {
            features.set(setting.feature, setting.disabled);
        }
        return features;
    }

    async customButtons(membershipId:string): Promise<CustomButton[]> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/custom-buttons`);
        return response.data;
    }

    async membershipInfo(inviteCode:string): Promise<MembershipInfo> {
        let response = await httpGet(`${this.membershipsUrl}/${inviteCode}/info`);
        return response.data;
    }

    async membershipSetting(inviteCode:string, setting:string): Promise<string> {
        let response = await httpGet(`${this.membershipsUrl}/${inviteCode}/settings/${setting}`);
        return response.data;
    }

    async invites(membershipId: string): Promise<MemberInvite[]> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/invites`);
        return response.data;
    }

    async resetInviteLinks(membershipId: string) {
        await httpPost(`${this.membershipsUrl}/${membershipId}/reset-invite-links`);
    }

    async deleteMembership(membershipId: string) {
        await httpDelete(`${this.membershipsUrl}/${membershipId}`);
    }

    async deleteStatus(membershipId: string) {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/delete-status`);
        return response.data as boolean;
    }

    async lastActive(membershipId:string): Promise<Map<String, Number>> {
        let response = await httpGet(`${this.membershipsUrl}/${membershipId}/last-active`);
        return response.data;
    }
}