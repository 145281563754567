import * as React from "react"
import {SVGProps} from "react"

export interface Props extends SVGProps<SVGSVGElement> {
    colour?: string;
}


const Leader = ({colour = "#37694a", ...props}: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20.5}
        height={20.365}
        {...props}
    >
        <g transform="translate(.5 .5)">
            <path
                d="M12.747 18.119a10.5 10.5 0 0 1-3.064 1.246S.099 17.865 0 3.575L9.681 0l9.688 3.564a24.869 24.869 0 0 1-.671 5.809"
                fill="none"
                stroke={colour}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.979 7.689a4.913 4.913 0 1 1-4.913 4.913 4.913 4.913 0 0 1 4.913-4.913m0-1.107a6.02 6.02 0 1 0 6.02 6.02 6.02 6.02 0 0 0-6.02-6.02Z"
                fill={colour}
            />
            <circle
                cx={1.509}
                cy={1.509}
                transform="translate(12.469 9.645)"
                fill={colour}
                r={1.509}
            />
            <path
                d="M11.191 14.933a3.362 3.362 0 0 1 5.56-.02 3.778 3.778 0 0 1-5.56.02Z"
                fill={colour}
            />
        </g>
    </svg>
)

export default Leader
