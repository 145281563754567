import styles from "./UploadImage.module.css";
import React, {InputHTMLAttributes, useEffect, useState} from "react";
import {Sdk} from "../../domain/sdk/Sdk";
import Logo from "../svg/Logo";

export interface IdealClientAvatarProps extends InputHTMLAttributes<any> {
    membershipId?: string;
    center?: boolean;
}

export default function CustomLogo({membershipId, width = 80, height = 80, center = true, onClick, ...other}: IdealClientAvatarProps) {
    const [data, setData] = useState(undefined as undefined | string);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        (async () => {
            if (membershipId) {
                const response = await Sdk.logoClient.fetch(membershipId);
                if (response && response !== '') {
                    setData(response);
                }
            } else {
                setData(undefined);
            }
            setLoaded(true);
        })();
    }, [membershipId]);

    return <>
        {loaded && <figure onClick={onClick} style={{textAlign: center ? "center" : "left"}} {...other}>
            {data && <img
                className={`${styles.Avatar}`}
                alt="avatar"
                src={data}
                style={{width: width, height: height}}
            />}
            {!data && <>
                <Logo/>
                <div className={`${styles.Text}`}>ProgressPod.io</div>
            </>}
        </figure>}
    </>
}
