import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPut} from "../utils/HttpClient";
import {ScheduledMeeting} from "./domain/ScheduledMeeting";
import {ScheduledMeetingRequest} from "./domain/request/ScheduledMeetingRequest";

export class ScheduledMeetingsClient {
    private readonly scheduledMeetingUrl: string;

    constructor(config: SdkConfig) {
        this.scheduledMeetingUrl = `${config.apiUrl}/v1/scheduled-meetings`;
    }

    async scheduledMeeting(meetingId:string): Promise<ScheduledMeeting> {
        const meetingResponse = await httpGet(`${this.scheduledMeetingUrl}/${meetingId}`);
        let data = meetingResponse.data;
        return new ScheduledMeeting(data)
    }

    async update(meetingScheduleId:string, request: ScheduledMeetingRequest) {
        return await httpPut(`${this.scheduledMeetingUrl}/${meetingScheduleId}`, request);
    }
}