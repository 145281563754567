import React, {useEffect, useState} from 'react';
import styles from './NotificationsPanel.module.css';
import {NotificationFrequency} from "../../../domain/sdk/domain/NotificationFrequency";
import FormSelectSlim from "../../components/FormSelectSlim";
import {Sdk} from "../../../domain/sdk/Sdk";
import {SubscriberNotification} from "../../../domain/sdk/domain/SubscriberNotification";


export default function NotificationsPanel() {
    const [selectedFrequency, setSelectedFrequency] = useState(NotificationFrequency.HOURLY);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async()=> {
            const notifications = await Sdk.notificationSettingsClient.notifications();
            const existing = notifications.filter(setting => setting.notification === SubscriberNotification.MISSED_POD_MESSAGE);
            if (existing.length === 1) {
                setSelectedFrequency(existing[0].frequency);
            }
            setLoaded(true);
        })();
    }, []);

    return <div>
        {loaded && <>
            <div className={`row`}>
                <div className={`columns small-3`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Notifications
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.TextBlock}`}>
                        Adjust the frequency of your notifications
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-8 medium-9`}>
                    <div className={`${styles.NotificationWrapper}`}>
                        Missed pod messages
                    </div>
                </div>
                <div className={`columns small-4 medium-3`}>
                    <FormSelectSlim
                        onChange={async (event) => {
                            setSelectedFrequency(event.target.value);
                            await Sdk.notificationSettingsClient.updateFrequency(SubscriberNotification.MISSED_POD_MESSAGE, event.target.value);
                        }}
                        value={selectedFrequency}
                        options={[
                            {text: '5-mins', value: NotificationFrequency.FIVE_MINUTES},
                            {text: 'Hourly', value: NotificationFrequency.HOURLY},
                            {text: '3-hours', value: NotificationFrequency.THREE_HOURS},
                            {text: '6-hours', value: NotificationFrequency.SIX_HOURS},
                            {text: '12-hours', value: NotificationFrequency.TWELVE_HOURS},
                            {text: 'Daily', value: NotificationFrequency.DAY},
                            {text: 'Weekly', value: NotificationFrequency.WEEKLY},
                            {text: 'Never', value: NotificationFrequency.NEVER}
                        ]}
                    />
                </div>
            </div>
        </>}
    </div>;
}
