import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styles from './MembershipLinks.module.css';
import ButtonSecondary from "../components/ButtonSecondary";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import Breadcrumb from "../components/Breadcrumb";
import {SecureContext} from "../state/SecureContext";

export default function MembershipInvites() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [message, setMessage] = useState('');
    const [leaderInviteLink, setLeaderInviteLink] = useState('');
    const [participantInviteLink, setParticipantInviteLink] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSelectedMembershipId(state.membershipId);
        setIsAdmin(Sdk.context.selectedBusiness?.owner || Sdk.context.selectedBusiness?.admin);
        (async () => {
            if (state.membershipId) {
                let links = await Sdk.membershipsClient.invites(state.membershipId);
                let leaderLink = links.filter(link => link.role === 'Leader')[0];
                setLeaderInviteLink(window.location.origin + `/sign-up/leader?invite=${leaderLink.code}`);
                let participantLink = links.filter(link => link.role === 'Participant')[0];
                setParticipantInviteLink(window.location.origin + `/sign-up/participant?invite=${participantLink.code}`);
            }
            setLoading(false);
        })();
    }, [state.membershipId, setSelectedMembershipId]);

    async function copyTextToBuffer(text:string) {
        await navigator.clipboard.writeText(text);
        setMessage('Copied to clipboard');
        setTimeout(() => setMessage(''), 1000);
    }

    return <>
        {!loading && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Memberships", link: "/memberships"},
                        {text: state.membershipName ?? state.name},
                        {text: "Invites"},
                    ]}/>
                </div>
            </div>
            {isAdmin && <div className={`row`}>
                <div className={`columns small-12 medium-5 medium-4 small-left`}>
                    <div className={`${styles.BodyText}`}>
                        Click button to copy invite link
                    </div>
                    <ButtonSecondary className={`${styles.ButtonWrapper}`} text="Leader invite link" type="button" onClick={async () => {
                        await copyTextToBuffer(leaderInviteLink);
                    }}/>
                    <ButtonSecondary className={`${styles.ButtonWrapper}`} text="Participant invite link" type="button" onClick={async () => {
                        await copyTextToBuffer(participantInviteLink);
                    }}/>
                    {message.length > 0 && (<p className={`${styles.MessageText}`}>{message}</p>)}
                </div>

                <div className={`columns small-12 medium-5 medium-4 small-left`}>
                    <div className={`${styles.BodyText}`}>
                        Control link access
                    </div>
                    <ButtonPrimary className={`${styles.ButtonWrapper}`} text="Reset links" type="button" onClick={async () => {
                        navigate('/membership/reset-links', {state});
                    }}/>
                </div>
            </div>}
        </div>}
    </>;
}
