export class SdkConfig {
    apiUrl: string;
    prod:boolean;

    static prod() {
        console.info('PROD')
        return Builder.of().build();
    }

    static local() {
        console.info('LOCAL')
        return Builder.of().localApi().build();
    }

    constructor(apiUrl: string, prod:boolean) {
        this.apiUrl = apiUrl;
        this.prod = prod;
    }
}

export class Builder {
    private _apiUrl: string;
    private _prod: boolean;

    public static of() {
        return new Builder();
    }

    constructor() {
        this._apiUrl = "https://api.progresspod.io";
        this._prod = true;
    }

    localApi() {
        this._apiUrl = "http://localhost:8090";
        this._prod = false;
        return this;
    }

    apiUrl(apiUrl: string) {
        this._apiUrl = apiUrl;
        return this;
    }

    build() {
        return new SdkConfig(this._apiUrl, this._prod);
    }
}