import React, {useContext, useEffect, useState} from 'react';
import styles from './LeaderPods.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonPrimary from "../components/ButtonPrimary";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {LeaderPod} from "../../domain/sdk/domain/LeaderPod";
import People from "../svg/People";
import Edit from "../svg/Edit";
import Breadcrumb from "../components/Breadcrumb";
import {SecureContext} from "../state/SecureContext";

export default function LeaderPods() {
    const {setSelectedMembershipId} = useContext(SecureContext);
    const {state} = useLocation();
    const navigate = useNavigate();
    const [meetings, setMeetings] = useState([] as LeaderPod[]);

    useEffect(() => {
        setSelectedMembershipId(state.membershipId);
        (async () => {
            setMeetings(await Sdk.leaderPodsClient.pods(state.membershipId));
        })();
    }, [state.membershipId, setSelectedMembershipId]);

    async function onAddLeaderMeeting() {
        navigate('/leader-pods/add', {state});
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Memberships", link: "/memberships"},
                        {text: state.membershipName ?? state.name},
                        {text: "Leader meetings"},
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-5 medium-4 small-left`}>
                    <ButtonPrimary className={`${styles.AddMeetingWrapper}`} text="Add leader meeting" type="button" onClick={onAddLeaderMeeting}/>
                </div>
            </div>

            {meetings && <div className={`row`}>
                {meetings.map((pod:LeaderPod, index) => {
                    return <div key={pod.id} className={`columns large-4 end`}>
                        <div className={`${styles.CardWrapper}`}>
                            <div className={`${styles.CardSubTitle}`}>
                                {pod.membershipName} leader meeting <People className={`${styles.Icon}`}/>
                            </div>
                            <div className={`${styles.CardDateAndTime}`}>
                                {pod.scheduledMeeting.nextMeeting}
                            </div>
                            <div className={`${styles.CardItem}`}>
                                <Link className={`${styles.CardLink}`} to={`/leader-pod`} state={{...pod, name: state.name}}>
                                    View details
                                </Link>
                                <Link className={`${styles.CardLink} ${styles.EditLink}`} to={`/leader-pods/edit`} state={{...pod, name: state.name}}>
                                    <div className={`${styles.Edit}`}>Edit</div> <Edit className={`${styles.Icon}`}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                })}
            </div>}
        </div>
    </>;
}
