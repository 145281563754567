import styles from './HeaderLoggedIn.module.css';
import LogoWrapper from './LogoWrapper';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import MenuIcon from "../svg/Menu";
import {Sdk} from "../../domain/sdk/Sdk";
import Notifications from "../svg/Notifications";
import {rootNavigation} from "../../domain/utils/NavigationUtils";
import {User} from "../../domain/sdk/domain/User";
import Messages from "../svg/Messages";
import {DefaultLinks} from "../../domain/sdk/domain/Setting";
import {Message} from "../../domain/sdk/domain/Message";
import {MessageListener} from "../../domain/sdk/MessageClient";

export interface HeaderLoggedInProps {
    className?: string;
}

export default function HeaderLoggedIn({className}: HeaderLoggedInProps) {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState(Sdk.context?.notifications?.length || 0);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [defaults, setDefaults] = useState({} as DefaultLinks);
    const [user, setUser] = useState({} as User);
    let menuRef: any = useRef(null);

    useEffect(() => {
        Sdk.inAppNotificationsClient.addListener((quantity: number) => {
            setNotifications(quantity);
        });
        let unread = Sdk.messageClient.channels()
            .filter(channel => channel.lastMessageId !== channel.lastMessageReadId);
        setUnreadMessages(unread.length);
        Sdk.messageClient.addListener(new class implements MessageListener {
            received(received: Message): void {
                if (received.senderId === Sdk.context.user.id) {
                    return;
                }
                let unreadChannels = Sdk.messageClient.channels()
                    .filter(channel => channel.lastMessageId !== channel.lastMessageReadId && channel.id !== received.channelId).length;
                let channelById = Sdk.messageClient.getChannel(received.channelId);
                if (channelById?.lastMessageReadId !== received.id) {
                    unreadChannels++;
                }
                setUnreadMessages(unreadChannels);
            }

            read(message: Message): void {
                let unreadChannels = Sdk.messageClient.channels();
                unreadChannels = unreadChannels.filter(channel => channel.lastMessageId !== channel.lastMessageReadId);
                setUnreadMessages(unreadChannels.length);
            }
        }());
        setUser(Sdk.context.user);
        (async () => {
            let settings = await Sdk.membershipsClient.defaultLinks();
            setDefaults(settings);
        })();
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return <>
        <div className={`${styles.Header} ${className ? className : ''}`}>
            <div className={`row`}>
                <div className={`columns small-5`}>
                    <div className={`${styles.LogoWrapper}`}>
                        <LogoWrapper onClick={() => navigate(rootNavigation())}/>
                    </div>
                </div>
                <div className={`columns small-7`}>
                    <div className={`${styles.MenuWrapper}`}>
                        <Messages
                            className={`${styles.MessagesIcon}`}
                            onClick={() => navigate('/chats')}
                            quantity={unreadMessages}
                        />
                        <Notifications
                            className={`${styles.NotificationIcon}`}
                            onClick={() => navigate('/notifications')}
                            quantity={notifications}
                        />
                        <MenuIcon className={`${styles.MenuIcon}`} onClick={() => setShowMenu(!showMenu)}/>
                        {showMenu && <div ref={menuRef} className={`${styles.Menu}`} onMouseLeave={e => setShowMenu(false)}>
                            <div className={`${styles.MenuItem}`} onClick={()=> {
                                navigate('/memberships');
                                setShowMenu(false);
                            }}>
                                Memberships
                            </div>
                            <div className={`${styles.MenuItem}`} onClick={() => {
                                navigate('/my-settings');
                                setShowMenu(false);
                            }}>
                                My Settings
                            </div>
                            <a target="_blank" href={defaults.SupportLink} rel="noreferrer">
                                <div className={`${styles.MenuItem}`}>Help</div>
                            </a>
                            <div className={`${styles.MenuItem}`} onClick={() => {
                                navigate('/login');
                                Sdk.userClient.logout()
                            }}>
                                Logout
                            </div>
                        </div>}
                    </div>

                </div>
            </div>
        </div>
        {user && <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.LoggedInAs}`}>{`Welcome ${user?.firstName} ${user?.lastName}`} ({Sdk.context?.selectedBusiness?.name})</div>
                </div>
            </div>
        </div>}
    </>;
}
