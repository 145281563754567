import React, {useCallback, useState} from 'react';
import styles from './RemoveMember.module';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import ButtonDanger from "../components/ButtonDanger";

export default function UnassignAssistantLeader() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [error, setError] = useState<string|undefined>(undefined);

    const unassignLeader = useCallback(async ()=> {
        let response = await Sdk.podsClient.unassignAssistantLeader(state.pod.id);
        if (response.status === 200) {
            state.pod.scheduledMeeting.thirdPartyMeetingLink = response.data;
            navigate('/pod/participants', {state: state.pod});
        } else {
            setError(`Oops! That didn't work. Please try again`);
            setTimeout(()=> setError(undefined), 3000);
        }
    }, [navigate, state.pod]);

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-9 large-8 small-centered`}>
                    <div style={styles.TitleWrapper}>
                        Unassign assistant leader
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.InputWrapper}>
                        Are you sure you want to unassign {state.member.firstName} {state.member.lastName} as the assistant leader?
                    </div>
                </div>
                <div className={`columns small-12 small-centered`}>
                    <div style={styles.LastInputWrapper}>
                        The meeting link will revert back to the one you originally provided.
                    </div>
                </div>
                {error && <div className={styles.ErrorMessage}>{error}</div>}

                <div className={`columns medium-10 large-7 medium-centered hide-for-small-only`}>
                    <ButtonCancel style={styles.CancelButton} text="Cancel" type="button" onClick={async () => {
                        navigate('/pod/participants', {state: state.pod});
                    }}/>
                    <ButtonDanger style={styles.RemoveButton} text="Unassign assistant leader" type="button" onClick={async () => {
                        await unassignLeader();
                    }}/>
                </div>

                <div className={`columns small-12 show-for-small-only`}>
                    <ButtonDanger style={styles.FullWidth} text="Unassign assistant leader" type="button" onClick={async () => {
                        await unassignLeader();
                    }}/>
                    <ButtonCancel style={styles.FullWidth} text="Cancel" type="button" onClick={async () => {
                        navigate('/pod/participants', {state: state.pod});
                    }}/>
                </div>
            </div>
        </div>
    </>;
}
