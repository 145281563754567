import React from 'react';
import styles from './Bio.module.css';
import {useLocation} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";

export default function Bio() {
    const {state} = useLocation();

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Progress Pods", link: "/pods", state: state},
                        {text: state.pod.name, link: "/pod", state: state.pod},
                        {text: "Participants", link: '/pod/participants', state: state.pod},
                        {text: `${state.member.firstName} ${state.member.lastName}`}
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.SubTitle}`}>
                        Member Bio
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.InputWrapper}`}>
                        {state.member.bio}
                    </div>
                </div>
            </div>
        </div>
    </>;
}
