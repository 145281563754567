import React, {useEffect, useState} from 'react';
import styles from './SignUp.module.css';
import validate from './SignUp.validation';
import formUtils from '../../domain/utils/UseForm';
import FormInputText from '../components/FormInputText';
import ButtonPrimary from '../components/ButtonPrimary';
import FormInputPassword from '../components/FormInputPassword';
import {useLocation, useNavigate} from "react-router-dom";
import ButtonCancel from "../components/ButtonCancel";
import {Sdk} from "../../domain/sdk/Sdk";
import Checkbox from "../components/Checkbox";

export default function SignUp() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);
    const {values, setValues, errors, setErrors, handleChange, handleCheckboxChange, handleSubmit} = formUtils(onFormSubmit, validate);

    async function onClickAgreeToTermsAndConditions() {
        setAgreeToTermsAndConditions(!agreeToTermsAndConditions)
    }

    useEffect(() => {
        if (state) {
            setValues(state);
        }
    }, [setValues, state]);

    async function onFormSubmit() {
        if (!agreeToTermsAndConditions) {
            setErrors({agreeToTerms : "Please agree to terms"});
        } else {
            let response = await Sdk.businessClient.register(values.businessName, values.firstName, values.lastName, values.email, values.password)
            if (response && response.status === 200) {
                navigate('/sign-up/check-inbox')
            }
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns medium-5 large-4 small-centered`}>
                    <form className={`${styles.SignUpForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            Register your business
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Business name" name="businessName"
                                           onChange={handleChange}
                                           value={values.businessName || ''}/>
                            {errors.businessName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.businessName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Email" name="email"
                                           onChange={handleChange}
                                           value={values.email || ''}/>
                            {errors.email && (
                                <p className={`${styles.ErrorMessage}`}>{errors.email}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="First name" name="firstName"
                                           onChange={handleChange}
                                           value={values.firstName || ''}/>
                            {errors.firstName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.firstName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Last name" name="lastName"
                                           onChange={handleChange}
                                           value={values.lastName || ''}/>
                            {errors.lastName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.lastName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="password"
                                               onChange={handleChange}
                                               value={values.password || ''}/>
                            {errors.password && (
                                <p className={`${styles.ErrorMessage}`}>{errors.password}</p>
                            )}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputPassword name="confirm"
                                               onChange={handleChange}
                                               placeholder={"Retype password"}
                                               value={values.confirm || ''}/>
                            {errors.confirm && (
                                <p className={`${styles.ErrorMessage}`}>{errors.confirm}</p>
                            )}
                        </div>
                        <div className={`${styles.CheckboxWrapper}`}>
                            <Checkbox  name="agreeToTermsAndConditions" label="I AGREE TO THE" onChange={handleCheckboxChange} checked={agreeToTermsAndConditions} onClick={onClickAgreeToTermsAndConditions} >
                                <a href="sign-up/owner/terms" target="_blank" className={styles.Underline}>TERMS AND CONDITIONS</a>
                            </Checkbox>
                            {errors.agreeToTerms && (
                                <p className={`${styles.ErrorMessage}`}>{errors.agreeToTerms}</p>
                            )}
                        </div>
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Sign up" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={async () => {
                                navigate('/')
                            }}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}
