import {SdkConfig} from "./SdkConfig";
import {httpGet, httpPut} from "../utils/HttpClient";
import {Account} from "./domain/Account";

export class AccountClient {
    private readonly myAccountUrl: string;

    constructor(config: SdkConfig) {
        this.myAccountUrl = `${config.apiUrl}/v1/users/my-account`;
    }

    async myAccount():Promise<Account>{
        let response = await httpGet(this.myAccountUrl);
        return response.data;
    }

    async updateProfile(bio:string, emailAddress:string, firstName:string, lastName:string) {
        return await httpPut(`${this.myAccountUrl}/profile`, {bio, emailAddress, firstName, lastName});
    }

    async updateTimezone(timezone:string) {
        return await httpPut(`${this.myAccountUrl}/profile`, {timezone});
    }
}