import React, {useCallback, useEffect, useState} from 'react';
import styles from './PodParticipants.module.css';
import {Sdk} from "../../domain/sdk/Sdk";
import {Link, useLocation} from "react-router-dom";
import {PodMember} from "../../domain/sdk/domain/PodMember";
import Leader from "../svg/Leader";
import {NextTinyStep, NextTinyStepStatus} from "../../domain/sdk/domain/NextTinyStep";
import Breadcrumb from "../components/Breadcrumb";
import {AssistantLeader} from "../../domain/sdk/domain/AssistantLeader";
import {DateTime} from "luxon";

export default function PodParticipants() {
    const {state} = useLocation();
    const [members, setMembers] = useState([] as PodMember[]);
    const [leader, setLeader] = useState(false);
    const [ntsMapping, setNtsMapping] = useState(new Map<string, NextTinyStep>());
    const [assistantLeader, setAssistantLeader]: any = useState(undefined as undefined | AssistantLeader);
    const [activeUsers, setActiveUsers]: any = useState(new Map<String, Number>());

    useEffect(() => {
        (async () => {
            setLeader(state.leaderId === Sdk.context.user.id);
            setMembers(await Sdk.podsClient.members(state.id));
            setActiveUsers(await Sdk.membershipsClient.lastActive(state.membershipId));
            let featureToggles = await Sdk.membershipsClient.membershipFeatures(state.membershipId);
            let isNtsDisabled = featureToggles.has('NextTinyStep') && featureToggles.get('NextTinyStep');
            if (!isNtsDisabled) {
                let steps = await Sdk.nextTinyStepClient.nextTinySteps(state.id);
                steps.sort((a, b) => a.created - b.created);
                let ntsMapping = new Map<string, NextTinyStep>();
                for (const step of steps) {
                    ntsMapping.set(step.userId, step);
                }
                setNtsMapping(ntsMapping);
            }
            setAssistantLeader(await Sdk.podsClient.assistantLeaderByPod(state.id));
        })();
    }, [state.id, state.leaderId, state.membershipId]);

    const showLastActive = useCallback((userId: string) => {
        if (activeUsers[userId]) {
            return '(active ' + (() => {
                let diff = DateTime.now().diff(DateTime.fromSeconds(activeUsers[userId]), ["years", "months", "days", "hours", "minutes", "seconds"]);
                if (diff.years > 0) {
                    return `${diff.years} year${diff.years > 1 ? 's' : ''} ago`;
                } else if (diff.months > 0) {
                    return `${diff.months} month${diff.months > 1 ? 's' : ''} ago`;
                } else if (diff.days > 0) {
                    return `${diff.days} day${diff.days > 1 ? 's' : ''} ago`;
                } else if (diff.hours > 0) {
                    return `${diff.hours} hour${diff.hours > 1 ? 's' : ''} ago`;
                } else if (diff.minutes > 30) {
                    return `${diff.minutes} minute${diff.minutes > 1 ? 's' : ''} ago`;
                } else if (diff.minutes >= 0) {
                    return `just now`;
                }
            })() + ')';
        }
    }, [activeUsers]);

    function determineMember(member: PodMember) {
        // leader can manage the participants, excluding themselves
        const isAssistant = member.userId !== undefined && assistantLeader?.assistantId === member.userId;
        if (leader && !member.leader) {
            return <>
                <Link className={`${styles.NameText}`} to={"/pod/participant"} state={{member, pod: state, membershipId: state.membershipId, isAssistant}}>
                    <span style={{textDecoration:"underline", color:"#1779ba"}}>{member.firstName} {member.lastName}</span>
                </Link>
            </>
        }
        return <>
            {(!leader || member.leader) && <div className={`${styles.NameText}`}>
                <Link className={`${styles.NameText}`} to={"/bio"} state={{member, pod: state, membershipId: state.membershipId}}>
                    <span style={{textDecoration:"underline", color:"#1779ba"}}>{member.firstName} {member.lastName}</span> {member.leader && <Leader/>}
                </Link>
            </div>}
        </>;
    }

    function determineNts(member: PodMember) {
        if (ntsMapping.has(member.userId)) {
            let nts = ntsMapping.get(member.userId);
            if (nts?.status === NextTinyStepStatus.NotSet) {
                return "Not set"
            }
            return <>
                {nts?.goal && nts?.goal.length > 0 && <div>{`Goal/project: ${nts?.goal}`}</div>}
                <div>{`NTS: ${nts?.description}`}</div>
            </>
        }
    }

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Progress Pods", link: "/pods", state: state},
                        {text: state.name, link: "/pod", state: state},
                        {text: "Participants"},
                    ]}/>
                </div>
            </div>
            {members && <div className={`row`}>
                {members.map((member, index) => {
                    return <div key={index} className={`columns small-12 ${styles.Participant}`}>
                        {determineMember(member)}
                        <div className={styles.LastActive}>{showLastActive(member.userId)}</div>
                        {<div className={`${styles.NameText}`}>
                            {determineNts(member)}
                        </div>}
                    </div>
                })}
            </div>}
        </div>
    </>;
}
