import React, {useState} from 'react';
import {SecureContext} from "./SecureContext";


const SecureProvider = ({children}: { children: React.ReactNode }) => {
    const [selectedMembershipId, setSelectedMembershipId] = useState(undefined as undefined | string);

    return <>
        {<SecureContext.Provider value={{
            selectedMembershipId, setSelectedMembershipId
        }}>
            {children}
        </SecureContext.Provider>}
    </>
}

export default SecureProvider;