import styles from './FormTextarea.module.css'
import React, {TextareaHTMLAttributes} from 'react';

export interface FormTextareaProps extends TextareaHTMLAttributes<any> {
	className?: string;
	name?: string;
}

export default function FormTextarea({className, name, ...other}:FormTextareaProps) {
	return <div className={`${className ? className : ''}`}>
		<textarea className={`${styles.Textarea_Textarea} ${styles.Textarea_Textarea}`} name={name} rows={5} {...other}/>
	</div>
}
