import React, {useEffect, useState} from 'react';
import styles from './JoinPod.module.css';
import {Link, useLocation} from "react-router-dom";
import {Sdk} from "../../domain/sdk/Sdk";
import {Pod} from "../../domain/sdk/domain/Pod";
import {extract, useQuery} from "../../domain/utils/QueryUtils";
import Breadcrumb from "../components/Breadcrumb";
import {isMeetingPaused} from "../../domain/utils/MeetingUtils";


export default function JoinPod() {
    const reference = useQuery().get("r");
    const {state} = useLocation();
    const [pods, setPods] = useState([] as Pod[]);
    const [waitlists, setWaitlists] = useState([] as Pod[]);

    useEffect(() => {
        (async () => {
            let membership = reference ? extract(reference) : state.membershipId;
            let allPods = await Sdk.podsClient.pods(membership);
            let userId = Sdk.context.user.id;
            let pods = allPods.filter(pod => pod.leaderId !== userId && !pod.members.includes(userId));
            setPods(pods.filter(pod => pod.members.length < pod.maxParticipants && !isMeetingPaused(pod.scheduledMeeting)).sort((a, b) => a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime));
            let waitlists = await Sdk.podsClient.myWaitlists();
            let filteredWaitlists = pods.filter(pod => pod.members.length >= pod.maxParticipants && waitlists.filter(waitlist => waitlist.id === pod.id).length === 0);
            setWaitlists(filteredWaitlists.sort((a, b) => a.scheduledMeeting.unixTime - b.scheduledMeeting.unixTime));
        })();
    }, [reference, state?.membershipId]);

    return <>
        <div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <Breadcrumb routes={[
                        {text: "Progress Pods", link: "/pods", state: {
                            membershipId : state?.membershipId ?? atob(String(reference)),
                            name: state?.name
                        }},
                        {text: "Join a Pod"},
                    ]}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12`}>
                    <div className={`${styles.InstructionsText}`}>
                        Join the Progress Pod that best suits your schedule and that you can commit to.
                    </div>
                    <div className={`${styles.InstructionsText}`}>
                        Alternatively join one or more waitlists.
                    </div>
                </div>
            </div>

            <div className={`row`}>
                <div className={`columns small-12 medium-5 `}>
                    <div className={`${styles.TitleWrapper}`}>
                        Available Progress Pods
                    </div>
                    {pods.length === 0 && <div className={`${styles.InstructionsText}`}>
                        No Progress Pods available
                    </div>}
                    <div>{pods && pods.map((pod: Pod, index) => {
                        return <div key={index}>
                            <div className={`${styles.PodName}`}>{pod.name}</div>
                            <div className={`${styles.MeetingDateTime}`}>
                                {pod.scheduledMeeting.frequencyDescription}
                            </div>
                            <div className={`${styles.MeetingDateTime}`}>
                                {`${pod.maxParticipants - pod.members.length} places available`}
                            </div>
                            <div className={`${styles.CardLinkWrapper}`}>
                                <Link className={`${styles.CardLink} ${styles.JoinWidth}`} to={`/pods/confirm-join-pod`} state={pod}>
                                    Join
                                </Link>
                            </div>
                        </div>
                    })}</div>
                </div>

                <div className={`columns small-12 medium-5 `}>
                    <div className={`${styles.TitleWrapper}`}>
                        Waitlists
                    </div>
                    {waitlists.length === 0 && <div className={`${styles.InstructionsText}`}>
                        No waitlists available
                    </div>}
                    <div>{waitlists && waitlists.map((pod: Pod, index) => {
                        return <div key={index}>
                            <div className={`${styles.PodName}`}>{pod.name}</div>
                            <div className={`${styles.MeetingDateTime}`}>
                                {pod.scheduledMeeting.frequencyDescription}
                            </div>
                            <div className={`${styles.CardLinkWrapper}`}>
                                <Link className={`${styles.CardLink} ${styles.WaitlistWidth}`} to={`/pods/confirm-join-waitlist`} state={pod}>
                                    Join waitlist
                                </Link>
                            </div>
                        </div>
                    })}</div>
                </div>
            </div>
        </div>
    </>;
}
