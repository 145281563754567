import React, {useEffect, useState} from 'react';
import formUtils from '../../domain/utils/UseForm';
import styles from './SignUpParticipant.module.css';
import validate from './SignUpParticipant.validation';
import FormInputText from '../components/FormInputText';
import ButtonPrimary from '../components/ButtonPrimary';
import ButtonCancel from "../components/ButtonCancel";
import FormInputPassword from '../components/FormInputPassword';
import {useNavigate, useSearchParams} from "react-router-dom";
import Checkbox from "../components/Checkbox";
import {Sdk} from "../../domain/sdk/Sdk";
import {MembershipInfo, ReadOnlyPod} from "../../domain/sdk/domain/Membership";
import {ScheduledMeeting} from "../../domain/sdk/domain/ScheduledMeeting";
import {compareReadOnlyPodsByWeekdayThenTime} from "../../domain/utils/PodUtils";
import {CustomButton} from "../../domain/sdk/domain/CustomButton";
import MembershipLogo from "../components/CustomLogo";

export default function SignUpParticipant() {
    const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);
    const [agreeToParticipantAgreement, setAgreeToParticipantAgreement] = useState(false);
    const [agreeToParticipantAgreementLink, setAgreeToParticipantAgreementLink] = useState(null as string | null);
    const {values, errors, setErrors, handleChange, handleCheckboxChange, handleSubmit} = formUtils(onFormSubmit, validate);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const inviteCode = searchParams.get("invite");
    const [membershipInfo, setMembershipInfo] = useState(null as MembershipInfo | null);
    const [showSelectPod, setShowSelectPod] = useState(false);
    const [availablePods, setAvailablePods] = useState([] as ReadOnlyPod[] | undefined);
    const [waitlists, setWaitlists] = useState([] as ReadOnlyPod[] | undefined);
    const [preferredPodId, setPreferredPodId] = useState(undefined as undefined | string);
    const [customButton, setCustomButton]: any = useState(undefined as undefined | CustomButton);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (inviteCode) {
            (async () => {
                let info = await Sdk.membershipsClient.membershipInfo(inviteCode);
                setMembershipInfo(info);
                let selectPodOnSignup = info.selectPodOnSignupEnabled;
                setShowSelectPod(selectPodOnSignup);
                if (selectPodOnSignup && info.availablePods) {
                    for (const pod of info.availablePods) {
                        pod.scheduledMeeting = new ScheduledMeeting(pod);
                    }
                    setAvailablePods(info.availablePods?.filter(pod => pod.seatsAvailable > 0).sort(compareReadOnlyPodsByWeekdayThenTime()));
                    setWaitlists(info.availablePods?.filter(pod => pod.seatsAvailable === 0).sort(compareReadOnlyPodsByWeekdayThenTime()));
                }
                if (info.customButtons && info.customButtons.length > 0) {
                    setCustomButton(info.customButtons[0]);
                }
                setAgreeToParticipantAgreementLink(await Sdk.membershipsClient.membershipSetting(inviteCode, 'ParticipantAgreementLink'));
                setIsLoading(false);
            })();
        }
    }, [inviteCode,]);

    async function onClickAgreeToTermsAndConditions() {
        setAgreeToTermsAndConditions(!agreeToTermsAndConditions)
    }

    async function onClickAgreeToParticipantAgreement() {
        setAgreeToParticipantAgreement(!agreeToParticipantAgreement)
    }

    function hasAgreeToParticipantAgreementLink() {
        return agreeToParticipantAgreementLink !== null && agreeToParticipantAgreementLink !== '';
    }

    async function onFormSubmit() {
        if (hasAgreeToParticipantAgreementLink() && !agreeToParticipantAgreement) {
            setErrors({agreeToTerms: "Please agree to terms"});
            return;
        }
        if (!agreeToTermsAndConditions) {
            setErrors({agreeToTerms: "Please agree to terms"});
        } else if (inviteCode === null) {
            setErrors({agreeToTerms: "Missing code"});
        } else {
            let response = await Sdk.membersClient.register(inviteCode, values.email, values.firstName, values.lastName, values.password, preferredPodId);
            if (response && response.status === 200) {
                navigate('/sign-up/check-inbox');
            }
        }
    }

    function listPods() {
        return <div className={`${styles.Pods}`}>
            {availablePods?.map((pod: ReadOnlyPod, index) => {
                return <div key={index} className={`${pod.id === preferredPodId ? styles.Selected : styles.Unselected}`} onClick={() => {
                    if (preferredPodId === pod.id) {
                        setPreferredPodId(undefined);
                    } else {
                        setPreferredPodId(pod.id);
                    }
                }}>
                    <div className={`${styles.PodName}`}>{pod.name}</div>
                    <div className={`${styles.MeetingDateTime}`}>
                        {pod.scheduledMeeting?.frequencyDescription}
                    </div>
                    <div className={`${styles.MeetingDateTime}`}>
                        {`${pod.seatsAvailable} places available`}
                    </div>
                </div>
            })}
        </div>;
    }

    function listWaitlists() {
        return <div className={`${styles.Pods}`}>
            {waitlists?.map((pod: ReadOnlyPod, index) => {
                return <div key={index} className={`${pod.id === preferredPodId ? styles.Selected : styles.Unselected}`} onClick={() => {
                    if (preferredPodId === pod.id) {
                        setPreferredPodId(undefined);
                    } else {
                        setPreferredPodId(pod.id);
                    }
                }}>
                    <div className={`${styles.PodName}`}>{pod.name}</div>
                    <div className={`${styles.MeetingDateTime}`}>
                        {pod.scheduledMeeting?.frequencyDescription}
                    </div>
                </div>
            })}
        </div>;
    }

    const numberOfPodsAvailable = membershipInfo?.availablePods?.length ?? 0;

    return <>
        {!isLoading && <div>
            <div className={`row`}>
                <div className={`columns small-12 small-centered}`}>
                    <div className={`${styles.BusinessName}`}>
                        {membershipInfo?.businessName}
                    </div>
                    <div className={`${styles.MembershipName}`}>
                        {membershipInfo?.membershipName}
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`columns small-12 medium-5 ${showSelectPod ? '' : 'small-centered'}`}>
                    <form className={`${styles.SignUpForm}`} onSubmit={handleSubmit}>
                        <div className={`${styles.TitleWrapper}`}>
                            Participant sign up
                        </div>
                        <div className={`${styles.AvatarWrapper}`}>
                            <MembershipLogo membershipId={membershipInfo?.id}/>
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Email" name="email"
                                           onChange={handleChange}
                                           value={values.email || ''}/>
                            {errors.email && (
                                <p className={`${styles.ErrorMessage}`}>{errors.email}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="First name" name="firstName"
                                           onChange={handleChange}
                                           value={values.firstName || ''}/>
                            {errors.firstName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.firstName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputText placeholder="Last name" name="lastName"
                                           onChange={handleChange}
                                           value={values.lastName || ''}/>
                            {errors.lastName && (
                                <p className={`${styles.ErrorMessage}`}>{errors.lastName}</p>
                            )}
                        </div>
                        <div className={`${styles.InputWrapper}`}>
                            <FormInputPassword name="password"
                                               onChange={handleChange}
                                               value={values.password || ''}/>
                            {errors.password && (
                                <p className={`${styles.ErrorMessage}`}>{errors.password}</p>
                            )}
                        </div>
                        <div className={`${styles.LastInputWrapper}`}>
                            <FormInputPassword name="confirm"
                                               onChange={handleChange}
                                               placeholder={"Retype password"}
                                               value={values.confirm || ''}/>
                            {errors.confirm && (
                                <p className={`${styles.ErrorMessage}`}>{errors.confirm}</p>
                            )}
                        </div>

                        {showSelectPod && <div className={`show-for-small-only`}>
                            {numberOfPodsAvailable > 0 && <>
                                <div className={`${styles.InstructionsText}`}>
                                    Select whichever Pod best suits your schedule and that you can commit to.
                                </div>
                                {listPods()}
                                <br/>
                                {membershipInfo?.availablePods && membershipInfo?.availablePods?.filter(pod => pod.seatsAvailable === 0).length > 0 &&
                                    <div className={`${styles.InstructionsText}`}>
                                        Or select a waitlist.
                                    </div>}
                                {listWaitlists()}
                                <br/>
                                {membershipInfo?.availablePods && membershipInfo?.availablePods?.filter(pod => pod.seatsAvailable === 0).length > 0 &&
                                    <div className={`${styles.InstructionsText}`}>
                                        Alternatively we can contact you when a pod is available.
                                    </div>
                                }
                                {numberOfPodsAvailable > 0 && <div className={`${preferredPodId ? styles.Unselected : styles.Selected}`} style={{marginTop: 10}} onClick={()=> setPreferredPodId(undefined)}>
                                    Contact me when a new pod is set up.
                                </div>}
                            </>}
                            {numberOfPodsAvailable === 0 && <div className={`${styles.InstructionsText}`} style={{marginTop: 10, marginBottom: 10}}>
                                There are currently no Progress Pods available{numberOfPodsAvailable === 0 && ", we'll contact you when a new pod is set up."}
                            </div>}
                        </div>}

                        {customButton && <>
                            {customButton.formLabel && <div className={`${styles.InstructionsText}`}>{customButton.formLabel}</div>}
                            <a href={customButton.url} rel="noreferrer" target="_blank" className={styles.CustomLink}>{customButton.label}</a>
                        </>}

                        <div className={`${styles.CheckboxWrapper}`}>
                            <Checkbox className={styles.Checkbox} name="agreeToTermsAndConditions" label={"I AGREE TO THE"} onChange={handleCheckboxChange}
                                      checked={agreeToTermsAndConditions} onClick={onClickAgreeToTermsAndConditions}>
                                <a href="participant/terms" rel="noreferrer" target="_blank" className={styles.Underline}>TERMS AND CONDITIONS</a>
                            </Checkbox>
                        </div>
                        {hasAgreeToParticipantAgreementLink() && <div className={`${styles.CheckboxWrapperLast}`}>
                            <Checkbox className={styles.Checkbox} name="agreeToParticipantAgreement" label="I AGREE TO THE" onChange={handleCheckboxChange}
                                      checked={agreeToParticipantAgreement} onClick={onClickAgreeToParticipantAgreement}>
                                <a href={agreeToParticipantAgreementLink ?? ''} rel="noreferrer" target="_blank" className={styles.Underline}>PARTICIPANT AGREEMENT</a>
                            </Checkbox>
                            {errors.agreeToTerms && (<p className={`${styles.ErrorMessage}`}>{errors.agreeToTerms}</p>)}
                        </div>}
                        <div className={`${styles.ButtonWrapper}`}>
                            <ButtonPrimary className={`${styles.FullWidthButton}`} text="Create account & join" type="submit"/>
                        </div>
                        <div className={`${styles.CancelButtonWrapper}`}>
                            <ButtonCancel className={`${styles.FullWidthButton}`} text="Cancel" type="button" onClick={async () => navigate('/')}/>
                        </div>
                    </form>
                </div>
                {showSelectPod && <div className={`columns small-12 medium-offset-1 medium-6 hide-for-small-only`}>
                    <div className={`${styles.TitleWrapper}`}>
                        Select a Progress Pod
                    </div>
                    {numberOfPodsAvailable > 0 && <>
                        <div className={`${styles.InstructionsText}`}>
                            Select whichever Pod best suits your schedule and that you can commit to.
                        </div>
                        {listPods()}
                        <br/>
                        {membershipInfo?.availablePods && membershipInfo?.availablePods?.filter(pod => pod.seatsAvailable === 0).length > 0 &&
                            <div className={`${styles.InstructionsText}`}>
                                Or select a waitlist.
                            </div>
                        }
                        {listWaitlists()}
                        <br/>
                        {membershipInfo?.availablePods && membershipInfo?.availablePods?.filter(pod => pod.seatsAvailable === 0).length > 0 &&
                            <div className={`${styles.InstructionsText}`}>
                                Alternatively we can contact you when a pod is available.
                            </div>
                        }
                        {<div className={`${preferredPodId ? styles.Unselected : styles.Selected}`} style={{marginTop: 10}} onClick={() => setPreferredPodId(undefined)}>
                            Contact me when a new pod is set up.
                        </div>}
                    </>}
                    {numberOfPodsAvailable === 0 && <div className={`${styles.InstructionsText}`} style={{marginTop: 10, marginBottom: 10}}>
                        There are currently no Progress Pods available{numberOfPodsAvailable === 0 && ", we'll contact you when a new pod is set up."}
                    </div>}
                </div>}
            </div>
        </div>}
    </>;
}
